import React, {useEffect, useState} from "react";
import WideCard from "common/components/WideCard";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import CommonButton from "common/components/Button";
import {useDispatch, useSelector} from "react-redux";
import {getCheckinList} from "services/booking/booking";

function CheckingList({changePage}){

    const dispatch = useDispatch();
    const [checkinList,setCheckinList] = useState([]);
    const checkinListData = useSelector(state => state.CheckinListSlice.booking);

    const tableColumns = [
        {field: "pName", headerName: "Patient Name", accessor: "pName", textAlign: 'left'},
        {field: "doctor", headerName: "Doctor", accessor: "doctor", textAlign: 'left'},
        {field: "date", headerName: "Date", accessor: "date", textAlign: 'left'},
        {field: "time", headerName: "Time", accessor: "time", textAlign: 'left'},
        {field: "action", headerName: "Action", accessor: "action", textAlign: 'left'},
    ];

    const searchKey = ['pName', 'doctor'];

    useEffect(() => {
        dispatch(getCheckinList());
    }, []);

    useEffect(() => {
        if(checkinListData.isSuccess){
            const data = checkinListData?.data;
            if(Array.isArray(data)){
                const array = data.map((val,index)=>({
                    id: index,
                    pName: `${val.patient.title} ${val.patient.firstName} ${val.patient.lastName}`,
                    doctor:`${val.slot.doctor.title} ${val.slot.doctor.name}`,
                    date:val.slot.date,
                    time:`${val.slot.startTime} - ${val.slot.endTime}`,
                    action:(<div style={{display: 'flex', flexDirection: 'row'}}>
                        <CommonButton
                            name={'Procedures'}
                            variant={'outline'}
                            circular={true}
                            onClick={()=>{
                                changePage(val);
                            }}
                        />
                    </div>)
                }));
                setCheckinList(array);
            }

        }
    }, [checkinListData.data]);

    return(
        <div>
            <WideCard>
                <Typography
                    sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '19px',}}>Checking List</Typography>
                <Box>
                    <Divider sx={{borderBottom: '1.3px solid black', marginTop: '6px',}}/>
                </Box>

                <MDBox sx={{marginY: 2}}>
                    <MDTable tableData={checkinList} tableColumns={tableColumns} searchKey={searchKey} isSearch={true}/>
                </MDBox>

            </WideCard>
        </div>
    );
}

export default CheckingList
