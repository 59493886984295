import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "services/AxiosOrders";

export const getGrn = createAsyncThunk('get-grn', async (grnData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/grn/search`, grnData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getAllGrn = createAsyncThunk('get-all-grn', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/grn`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const postGrn = createAsyncThunk('post-grn', async (grnData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/grn`, grnData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});