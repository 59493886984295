import React, {useEffect, useState} from "react";
import MDBox from "common/components/MDBox";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextInput from "common/components/TextInput/TextInput";
import PersonIcon from "@mui/icons-material/Person";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DropDown from "common/components/DropDown/DropDown";
import CommonButton from "common/components/Button";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {saveBooking} from "services/booking/booking";
import {
    sendSuccessNotification,
    sendWarningNotification,
} from "common/components/Notification/SendNotification";
import {getPatientByMobileNo} from "services/patient/patient";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import {generateDate} from "common/utils/CommonFunctions";
import {getAllDoctorsWithSlotCounts} from "services/doctor/doctor";
import MobileNumber from "common/components/MobileNumber";

function AppointmentBookingAdd({handleClose, slotDetails, branchDetails}) {

    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [saveLoader, setSaveLoader] = useState(false);
    const [patientAvailability, setPatientAvailability] = useState("");
    const [patientId, setPatientId] = useState("");
    const [title, setTitle] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [nic, setNic] = useState("");
    const [birthday, setBirthday] = useState(null);
    const [age, setAge] = useState("");
    const patientData = useSelector(
        (state) => state.PatientByMobileNoSlice.patient
    );
    const savaBookingData = useSelector(
        (state) => state.SaveBookingSlice.booking
    );
    const [mobileNoError, setMobileNoError] = useState("");

    const resetFields = () => {
        setAge("");
        setTitle("");
        setFirstName("");
        setLastName("");
        setNic("");
        setBirthday(null);
        setMobileNo("");
        setPatientId("");
    };

    const isDataComplete =
        slotDetails &&
        (patientId ||
            (title && firstName && lastName && age && mobileNo && nic && birthday));

    function calculateAge(birthdate) {
        const birthdateObj = dayjs(birthdate);
        const currentDatetime = dayjs();
        return currentDatetime.diff(birthdateObj, "year");
    }

    const handleSave = () => {
        const bookingData =
            patientId === ""
                ? {
                    status: "latest-4",
                    slotId: slotDetails?.slotId,
                    patient: {
                        title: title,
                        birthDay: generateDate(birthday),
                        firstName: firstName,
                        lastName: lastName,
                        nic: nic,
                        mobileNumber: mobileNo,
                    },
                }
                : {
                    patientId: patientId,
                    status: "latest-4",
                    slotId: slotDetails?.slotId,
                };

        setSaveLoader(true);
        dispatch(saveBooking(bookingData));
    };

    useEffect(() => {
        if (saveLoader) {
            setSaveLoader(false);
            if (savaBookingData.isSuccess && !savaBookingData.isLoading) {
                resetFields();
                dispatch(getAllDoctorsWithSlotCounts(branchDetails.branchId));
                handleClose();
                sendSuccessNotification({
                    title: "Success",
                    message: "Booking Saved Success",
                });
            } else if (!savaBookingData.isSuccess && !savaBookingData.isLoading) {
                sendWarningNotification({
                    title: "Error",
                    message: "Booking Error",
                });
            }
        }
    }, [savaBookingData.data]);

    const handleSearch = () => {
        resetFields();
        setPatientAvailability("Patient Not Found");
        if (search !== "") {
            dispatch(getPatientByMobileNo(search));
        }
    };

    useEffect(() => {
        if (patientData.isSuccess && !patientData.isLoading) {
            const val = patientData?.data;
            setPatientAvailability("");
            setAge(calculateAge(val.birthDay));
            setPatientId(val.id);
            setTitle(val.title);
            setFirstName(val.firstName);
            setLastName(val.lastName);
            setNic(val.nic);
            setBirthday(dayjs(val.birthDay));
            setMobileNo(val.mobileNumber);
        }
    }, [patientData.data]);

    const titleOptions = [
        {value: "Mr.", label: "Mr."},
        {value: "Mrs.", label: "Mrs."},
        {value: "Miss.", label: "Miss."},
        {value: "Ven.", label: "Ven."},
    ];

    return (
        <div>
            <MDBox>
                <Typography
                    sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#100f0f",
                        margin: "5px",
                    }}
                >
                    Add Patient
                </Typography>
                <Divider
                    sx={{
                        borderBottom: "1.3px solid black",
                        marginTop: "5px",
                        marginBottom: "5px",
                    }}
                />
            </MDBox>
            <Grid container>
                <Grid item xs={12} sm={12} md={5} lg={4.5}>
                    <MDBox sx={{margin: "5px"}}>
                        <TextInput
                            placeholder={"Patient Mobile No/NIC No"}
                            endIcon={<SearchIcon/>}
                            onEndIconClick={handleSearch}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={4.5}>
                    <MDBox
                        sx={{display: "flex", justifyItems: "center", margin: "5px"}}
                    >
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "red",
                                margin: "5px",
                            }}
                        >
                            {patientAvailability}
                        </Typography>
                    </MDBox>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <MDBox sx={{margin: "5px"}}>
                        <DropDown
                            label={"Title"}
                            options={titleOptions}
                            value={title}
                            onChange={(val) => {
                                setTitle(val);
                            }}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    <MDBox sx={{margin: "5px"}}>
                        <TextInput
                            placeholder={"First Name"}
                            startIcon={<PersonIcon fontSize={"medium"}/>}
                            value={firstName}
                            onChange={(e) => {
                                setFirstName(e.target.value);
                            }}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    <MDBox sx={{margin: "5px"}}>
                        <TextInput
                            placeholder={"Last Name"}
                            startIcon={<PersonIcon fontSize={"medium"}/>}
                            value={lastName}
                            onChange={(e) => {
                                setLastName(e.target.value);
                            }}
                        />
                    </MDBox>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={2}>
                    <MDBox sx={{margin: "5px"}}>
                        <TextInput
                            placeholder={"Age"}
                            value={age}
                            onChange={(e) => {
                                setAge(e.target.value);
                            }}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3.3}>
                    <MDBox sx={{margin: "5px"}}>
                        <TextInput
                            placeholder={"NIC / Passport"}
                            startIcon={<AccountBoxIcon fontSize={"medium"}/>}
                            value={nic}
                            onChange={(e) => {
                                setNic(e.target.value);
                            }}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3.3}>
                    <MDBox sx={{margin: "5px"}}>
                        <MobileNumber
                            value={mobileNo}
                            onChange={(value) => {
                                setMobileNo(value);
                                const MobilefirstTwoNumbers = value.substring(0, 2);
                                if (MobilefirstTwoNumbers === "94") {
                                    if (!/^(\94)\d{9}$/.test(value)) {
                                        setMobileNoError("Invalid Contact");
                                    } else {
                                        setMobileNoError("");
                                    }
                                }
                            }}
                            defaultCountry="lk"
                            placeholder={"Enter Mobile No"}
                            validationMessage={"Please enter a valid mobile number."}
                            placeholder={"Enter Mobile No"}
                        />
                        {mobileNoError && (
                            <div style={{color: "red", fontSize: "12px"}}>
                                {mobileNoError}
                            </div>
                        )}
                    </MDBox>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3.3}>
                    <MDBox sx={{margin: "5px"}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Birthday"
                                slotProps={{textField: {fullWidth: true}}}
                                value={birthday}
                                onChange={(val) => {
                                    setBirthday(val);
                                }}
                            />
                        </LocalizationProvider>
                    </MDBox>
                </Grid>
            </Grid>
            <Box
                display={{xs: "block", sm: "flex", md: "flex", lg: "flex"}}
                justifyContent={{
                    xs: "center",
                    sm: "flex-end",
                    md: "flex-end",
                    lg: "flex-end",
                }}
            >
                <div style={{margin: "5px"}}>
                    <CommonButton
                        name={"Cancel"}
                        variant={"outline"}
                        fullWidth={true}
                        onClick={() => {
                            handleClose();
                        }}
                    />
                </div>
                <div style={{margin: "5px"}}>
                    <CommonButton
                        name={"Confirm"}
                        fullWidth={true}
                        onClick={handleSave}
                        disabled={!isDataComplete}
                    />
                </div>
            </Box>
        </div>
    );
}

export default AppointmentBookingAdd;
