import MDBox from "common/components/MDBox";
import ModalComponent from "common/components/Modal";
import MDTable from "common/components/MDTable";
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {getToothHistory} from "services/toothChart/toothChart";
import CommonButton from "common/components/Button";
import PropTypes from "prop-types";

const tableColumns = [
    {field: "date", headerName: "date", accessor: "date", textAlign: 'left'},
    {field: "description", headerName: "Description", accessor: "description", textAlign: 'left'},
];
const searchKey = ['pName', 'age'];
export default function ToothHistoryModal({open, onClose, id}) {

    const dispatch = useDispatch();
    const [historyData, setHistoryData] = useState([]);
    const toothHistory = useSelector(state => state.ToothHistorySlice.toothHistory);

    useEffect(() => {
        dispatch(getToothHistory(id));
    }, []);

    useEffect(()=> {
        if(toothHistory.isSuccess && !toothHistory.isLoading){
            const array = [];
            toothHistory.data.forEach((val)=> {
                array.push({
                    date: val.date,
                    description: val.description
                })
            })
            setHistoryData(array);
        }
    },[toothHistory.data])

    return(
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="md">
            <Typography sx={{
                color: '#1C5E7C',
                fontWeight: 450,
                fontSize: '19px',
                marginBottom: '25px',
                position: 'absolute',
                top: '15px',
                left: '15px'
            }}>Tooth History</Typography>
            <MDBox margin={2} sx={{maxWidth: 600, minWidth: 400}}>
                <MDTable tableData={historyData} tableColumns={tableColumns} searchKey={searchKey} isSearch={false}/>
                <MDBox sx={{display: 'flex', justifyContent: 'end'}}>
                    <CommonButton
                        sx={{width: '90px'}}
                        onClick={onClose}
                        name={'Cancel'}
                        variant={'Fill'}
                    />
                </MDBox>
            </MDBox>
        </ModalComponent>
    )
}

ToothHistoryModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    id: PropTypes.any.isRequired,
};
