import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "services/AxiosOrders";

export const getPatientToothDetails = createAsyncThunk('get-tooth-details', async (id, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/patient/patient_tooth_details/${id}`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateRCT = createAsyncThunk('update-RCT', async ({id,updateData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/rct/rct_round/${id}`,  updateData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updatePrimaryStatus = createAsyncThunk('update-primary-status', async ({id,updateData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/rct/rct_primary_status/${id}`,  updateData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateDirection = createAsyncThunk('update-direction', async ({id,updateData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/direction/${id}`,  updateData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateMissingStatus = createAsyncThunk('update-missing', async ({id,updateData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/missing_tooth/${id}`,  updateData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateFillignType = createAsyncThunk('update-filling', async ({id,updateData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/filling_tooth/${id}`,  updateData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateCrown = createAsyncThunk('update-crown', async ({id,updateData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/crown_bridge/${id}`,  updateData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateBridge = createAsyncThunk('update-bridge', async ({id,updateData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/crown_bridge/bridge/${id}`,  updateData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getToothHistory = createAsyncThunk('get-tooth-history', async (id, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/history/${id}`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});
