import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "services/AxiosOrders";

export const getAllMainGrn = createAsyncThunk('get-main-grn', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/main_grn`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const postMainGrn = createAsyncThunk('api', async (grnData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/main_grn`, grnData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const searchMainGrn = createAsyncThunk('search-main-grn', async (id, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/main_grn/${id}`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateMainGrn = createAsyncThunk('update-grn-item', async ({id,grnUpData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/main_grn/${id}`,  grnUpData,{
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const deleteMainGrn = createAsyncThunk('delete-main-grn', async (id, { rejectWithValue }) => {
        try {
            const data = await instance.delete(`/main_grn/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateMainGrnStatus = createAsyncThunk('update-grn-status', async ({id,statusUpData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/main_grn/status_update/${id}`,  statusUpData,{
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});
