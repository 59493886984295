import React, {useEffect, useState} from 'react';
import WideCard from "common/components/WideCard";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import CommonButton from "common/components/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {useDispatch, useSelector} from "react-redux";
import {postExtraCharge} from "services/extraCharge/extraCharge";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";
import {updateBookingStatus} from "services/dashboard/dashboard";
import DropDown from "common/components/DropDown/DropDown";
import FormControlLabel from "@mui/material/FormControlLabel";
import {FormGroup} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";

function ExtraCharge({changePage, bookingId}) {
    const dispatch = useDispatch();
    const statusUpdate = useSelector((state) => state.UpdateBookingStatusSlice.booking);
    const [desc, setDesc] = useState("");
    const [extra, setExtra] = useState("");
    const [extraError, setExtraError] = useState("");
    const [saveLoader, setSaveLoader] = useState(false);
    const [waveOff, setWaveOff] = useState("");
    const [waveOffError, setWaveOffError] = useState("");
    const [selectedValue, setSelectedValue] = useState(null);
    const [showOtherTextField, setShowOtherTextField] = useState(false);
    const [foreign , setForeign] = useState(false)

    const isComplete = (desc && extra) || waveOff || selectedValue || foreign

    const disabled = !extraError && !waveOffError;

    const handleWaveOffChange = (e) => {
        setWaveOff(e.target.value);
        if (!/^\d*(\.\d{1,2})?$/.test(e.target.value)) {
            setWaveOffError("Invalid Wave Off Amount");
        }
    };

    const handleExtraChange = (e) => {
        const inputValue = e.target.value;
        setExtra(inputValue);

        if (inputValue.trim() === "") {
            setExtraError("");
        } else if (!/^\d+(\.\d{1,2})?$/.test(inputValue)) {
            setExtraError("Invalid Extra Charge");
        } else {
            setExtraError("");
        }
    };

    const savedExtraCharge = () => {
        let selectedWaveOff;

        if (showOtherTextField) {
            selectedWaveOff = waveOff;
        } else {
            selectedWaveOff = selectedValue ? selectedValue : null;
        }

        const chargeData = {
            extraFee: extra,
            waiveOff: selectedWaveOff,
            description: desc,
            bookingId: bookingId,
            foreignStatus : foreign ? 1 : 0,
        };

        dispatch(postExtraCharge(chargeData));
    };

    const updateStatus = () => {
        setSaveLoader(true);
        dispatch(updateBookingStatus({id: bookingId, statusUpData: {status: "checkout"}}));
    }

    useEffect(() => {
        if (saveLoader) {
            setSaveLoader(false);
            if (statusUpdate.isSuccess && !statusUpdate.isLoading) {
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Checkout Success',
                })
                changePage('checking-list')
            } else if (!statusUpdate.isSuccess && !statusUpdate.isLoading) {
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Checkout Warning'
                })
            }
        }
    }, [statusUpdate.data]);

    const waveList = [
        5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100
    ];

    const waveArray = waveList.map(value => ({value: value.toString(), label: value.toString()}));

    return (
        <WideCard>
            <Typography
                sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '19px',}}>
                Extra Charges And Fees
            </Typography>
            <Box>
                <Divider sx={{borderBottom: '1.3px solid black', marginTop: '6px',}}/>
            </Box>

            <Box sx={{display: 'flex', justifyContent: 'center', marginY: '10px'}}>
                <Box width={{xs: '100%', sm: '100%', md: '85%', lg: '80%'}}>
                    <Box>
                        <TextField
                            id="outlined-multiline-flexible"
                            label="Description"
                            fullWidth
                            multiline
                            rows={3}
                            value={desc}
                            onChange={(e) => {
                                setDesc(e.target.value)
                            }}
                        />
                    </Box>
                    <Box sx={{marginTop: '40px'}}>
                        <TextField
                            id="outlined-multiline-flexible"
                            label="Extra Rs"
                            fullWidth
                            multiline
                            rows={1}
                            value={extra}
                            onChange={handleExtraChange}
                            error={extraError !== ""}
                        />
                        {extraError && <div style={{color: 'red', fontSize: '12px'}}>{extraError}</div>}
                    </Box>

                    <Grid container spacing={2} sx={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <Typography sx={{fontSize: '14px'}}>Waive off</Typography>
                        </Grid>

                        {!showOtherTextField ? (
                            <Grid item xs={6} sm={4} md={4} lg={4}>
                                <DropDown
                                    options={waveArray}
                                    value={selectedValue}
                                    onChange={(val) => setSelectedValue(val)}
                                />
                            </Grid>
                        ) : null}

                        {showOtherTextField && (
                            <Grid item xs={6} sm={4} md={4} lg={4}>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="Waive off"
                                    fullWidth
                                    multiline
                                    rows={1}
                                    value={waveOff}
                                    onChange={handleWaveOffChange}
                                    error={waveOffError !== ""}
                                />
                                {waveOffError && <div style={{color: 'red', fontSize: '12px'}}>{waveOffError}</div>}
                            </Grid>
                        )}

                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={showOtherTextField}
                                            onChange={(e) => {
                                                setSelectedValue(e.target.checked ? null : selectedValue);
                                                setShowOtherTextField(e.target.checked);
                                            }}
                                        />
                                    }
                                    label="Other"
                                />
                            </FormGroup>
                        </Grid>

                        <Grid item xs={6} sm={4} md={4} lg={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={foreign}
                                            onChange={(e) => {
                                                setForeign(e.target.checked)
                                            }}

                                        />
                                    }
                                    label="Foreign"
                                />
                            </FormGroup>
                        </Grid>


                    </Grid>

                    <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>
                        <IconButton aria-label="back">
                            <ArrowBackIosNewIcon
                                sx={{color: '#2D9DE5'}}
                                onClick={() => {
                                    changePage('clinical-note');
                                }}
                            />
                        </IconButton>
                        <CommonButton
                            name={'CheckOut'}
                            variant={'Fill'}
                            onClick={() => {
                                if (isComplete) {
                                    savedExtraCharge();
                                    updateStatus();

                                } else {
                                    updateStatus();
                                }
                            }}
                            disabled={!disabled}
                        />
                    </Box>
                </Box>
            </Box>
        </WideCard>
    );
}

export default ExtraCharge;
