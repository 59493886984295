import WideCard from "common/components/WideCard";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAllPayments} from "services/payment/payment";
import {generateDate} from "common/utils/CommonFunctions";
import CommonButton from "common/components/Button";
import PaymentDetailsViewModal from "components/PaymentDetailsViewModal";

function PaymentDetails(){

    const dispatch = useDispatch();
    const paymentDetailsData = useSelector(state => state.PaymentSlice.payment);
    const [paymentDetails,setPaymentDetails] = useState([]);
    const [details,setDetails] = useState([]);
    const [isBookingModalOpen, setBookingModalOpen] = useState(false);

    const paymentData = [
        {
            date:'2024-10-15',
            doctorCharge:'25000',
            extraCharge:'4500',
            extraCost:'2500',
            labCost:'1000',
            myDentistCost:'12000',
            viewDetails:'',
        },
    ]

    const tableColumns = [
        {field: "date", headerName: "Date", accessor: "date", textAlign: 'left'},
        {field: "doctorCharge", headerName: "Doctor Charge", accessor: "doctorCharge", textAlign: 'left'},
        {field: "extraCharge", headerName: "Extra Charge", accessor: "extraCharge", textAlign: 'left'},
        {field: "extraCost", headerName: "Extra Cost", accessor: "extraCost", textAlign: 'left'},
        {field: "labCost", headerName: "Lab Cost", accessor: "labCost", textAlign: 'left'},
        {field: "myDentistCost", headerName: "My Dentist Cost", accessor: "myDentistCost", textAlign: 'left'},
        {field: "viewDetails", headerName: "View Details", accessor: "viewDetails", textAlign: 'left'},
    ];

    const searchKey = ['date', 'doctorCharge'];

    const handleClick = (val,payment)=>{

        let bookingDetails = {};

        if (val === 'booking'){
            bookingDetails = {
                action:'booking',
                value: [
                    {label:'Name',value:payment.booking.patient.title + ' ' + payment.booking.patient.firstName + ' ' + payment.booking.patient.lastName },
                    {label:'Number',value:payment.booking.patient.mobileNumber},
                    {label:'NIC/Passport',value:payment.booking.patient.nic},
                    {label:'Date Of Birth',value:payment.booking.patient.birthDay},
                    {label:'Doctor Name',value:payment.booking.slot.doctor.title + ' ' + payment.booking.slot.doctor.name},
                    {label:'Date',value:generateDate(payment.date)},
                    {label:'Time',value:payment.booking.slot.startTime},
                    {label:'Location',value:payment.booking.slot.chair.branch.location},
                ]
            }
        }else {
            bookingDetails = {
                action:'discount',
                value: [
                    {label:'Discount',value:payment?.discount === null ? "0%" : payment?.discount.percentage + '%' },
                    {label:'Description',value:payment?.discount?.description}
                ]
            }
        }
        setDetails(bookingDetails);
        setBookingModalOpen(true);
    }

    useEffect(() => {
        dispatch(getAllPayments())
    }, []);

    useEffect(() => {
        if(paymentDetailsData.isSuccess && !paymentDetailsData.isLoading){
            const data = paymentDetailsData?.data;
            if(Array.isArray(data)){
                const array = data.map((payment)=>(
                    {
                        date:generateDate(payment.date),
                        doctorCharge:payment.doctorCharge,
                        extraCharge:payment.extraCharge,
                        extraCost:payment.extraConsCost,
                        labCost:payment.labCost,
                        myDentistCost:payment.myDentistCost,
                        viewDetails:(
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <CommonButton
                                    name={'Booking'}
                                    variant={"outline"}
                                    circular={true}
                                    onClick={()=>{
                                       handleClick('booking',payment);
                                    }}
                                />

                                <div style={{marginLeft:'5px'}}>
                                    <CommonButton
                                        name={'Discount'}
                                        variant={"outline"}
                                        circular={true}
                                        onClick={()=>{
                                            handleClick('discount',payment);
                                        }}
                                    />
                                </div>
                            </div>
                        ),
                    }
                ));
                setPaymentDetails(array);
            }
        }
    }, [paymentDetailsData.data]);

    return(
        <div>
            <WideCard>
                <Typography
                    sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '19px',}}>Payment Details</Typography>
                <Box>
                    <Divider sx={{borderBottom: '1.3px solid black', marginTop: '6px',}}/>
                </Box>

                <MDBox sx={{marginY: 2}}>
                   <MDTable tableData={paymentDetails} tableColumns={tableColumns} searchKey={searchKey} isSearch={true}/>
                </MDBox>
            </WideCard>

            {isBookingModalOpen &&
                <PaymentDetailsViewModal
                    onClose={()=>{setBookingModalOpen(false)}}
                    open={isBookingModalOpen}
                    details={details}
                />
            }
        </div>
    )
}

export default PaymentDetails
