import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "services/AxiosOrders";


export const saveClinicalNote = createAsyncThunk('save-clinical-note', async (clinicalNoteData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/clinical_note`, clinicalNoteData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});
export const getClinicalNotesByPatient = createAsyncThunk('get-notes-by-patient', async (id, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/clinical_note/${id}`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const deleteClinicalNote = createAsyncThunk('delete-note', async (id, { rejectWithValue }) => {
        try {
            const {data} = await instance.delete(`/clinical_note/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
