import { createSlice } from "@reduxjs/toolkit";
import {updateBookingStatus} from "services/dashboard/dashboard";

const initialState = {
    booking: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const updateBookingStatusSlice = createSlice({
    name: "updateBookingStatus",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateBookingStatus.pending, (state) => {
                state.booking.isLoading = true;
            })
            .addCase(updateBookingStatus.fulfilled, (state, { payload }) => {
                state.booking.isLoading = false;
                state.booking.isSuccess = true;
                state.booking.data = payload;
            })
            .addCase(updateBookingStatus.rejected, (state, { payload }) => {
                state.booking.isLoading = false;
                state.booking.isSuccess = false;
                state.booking.errorMessage = payload;
            });
    },
});

export default updateBookingStatusSlice.reducer;
