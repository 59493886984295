import Box from "@mui/material/Box";
import React, {useState} from "react";
import CheckoutList from "components/CheckoutList";
import Payment from "components/Payment";

export default function PaymentFlow() {

    const [page,setPage] = useState('checkout-list');
    const [bookingId,setBookingId] = useState('');

    return(
        <Box>
            {page === 'checkout-list'?
                <CheckoutList changePage={(val)=> {
                    setBookingId(val);
                    setPage('payment');
                }}/>
                : page === 'payment' ?
                    <Payment bookingId={bookingId} changePage={(val)=> setPage(val)}/>
                    : <></> }
        </Box>
    )
}
