import ModalComponent from "common/components/Modal";
import Typography from "@mui/material/Typography";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CommonButton from "common/components/Button";
import MDTable from "common/components/MDTable";
import React, {useEffect, useState} from "react";
import Avatar from "@mui/material/Avatar";
import { inventoryDashboardImage } from "config/images";
import {useDispatch, useSelector} from "react-redux";
import {getAllStockTransfers, updateStockTransferStatus} from "services/stockTransfer/stockTransfer";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";

function StockTransferViewModel({ open, onClose ,data }) {

    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([])
    const updateStatusData = useSelector(state => state.UpdateStockTransferStatusSlice.stockTransfer);
    const [loader, setLoader] = useState(false);

  const tableColumns = [
    { field: "item", headerName: "Item", accessor: "item", textAlign: "left" },
    { field: "qty", headerName: "Qty", accessor: "qty", textAlign: "left" },
  ];
  const searchKey = ["item", "qty"];

    useEffect(() => {
        if(Array.isArray(data.stockTransferDetailsDto)){
            const array = data.stockTransferDetailsDto.map((itm)=>({

                item: (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Avatar
                            sx={{
                                width: 30,
                                height: 30,
                                margin: "0px",
                                padding: "0px",
                                backgroundColor: "#EAF5FC",
                                marginRight: "5px",
                                marginTop: "8px",
                            }}
                            src={inventoryDashboardImage}
                        />
                        <div style={{ marginTop: "10px" }}>{itm.itemId.itemName}</div>
                    </div>
                ),
                qty: itm.qty,
            }));
            setTableData(array)
        }
    }, []);

    useEffect(() => {
        if (loader){
            setLoader(false);
            if (updateStatusData.isSuccess && !updateStatusData.isLoading) {
                dispatch(getAllStockTransfers())
                onClose();
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Stock Transfer Confirm Success',
                })
            } else if (!updateStatusData.isSuccess && !updateStatusData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Stock Transfer Confirm Error'
                })
            }
        }
    }, [updateStatusData.data]);

  return (
    <ModalComponent showModal={open} setShowModal={onClose} maxWidth="md">
      <MDBox sx={{ margin: "10px" }}>
        <Box>
          <div>
            <Typography
              sx={{
                color: "#1C5E7C",
                fontWeight: 450,
                fontSize: "16px",
                marginBottom: "25px",
                position: "absolute",
                top: "15px",
                left: "15px",
              }}
            >
              {" "}
              {"Item View"}{" "}
            </Typography>
          </div>
        </Box>
        <div style={{ overflow: "hidden" }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Box sx={{ width: "500px", margin: "0 auto" }}></Box>
            </Grid>
          </Grid>
        </div>

        <Typography
          sx={{
            fontSize: "14px",
            margin: "10px 0px 8px 5px",
            color: "#000000",
            fontWeight: 450,
          }}
        >
         Received Branch - <span style={{ color: "#1C5D7C" }}>{data.sentBranch.name}</span>
        </Typography>

        <MDBox sx={{ marginY: 2 }}>
          <MDTable
            tableData={tableData}
            tableColumns={tableColumns}
            searchKey={searchKey}
            isSearch={false}
          />
        </MDBox>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ margin: "5px" }}>
            <CommonButton
              name={"Cancel"}
              variant={"outline"}
              onClick={onClose}
            />
          </div>

          <div style={{ margin: "5px" }}>
            <CommonButton
                name={"Comfirm"}
                variant={"Fill"}
                onClick={()=>{
                    setLoader(true)
                    dispatch(updateStockTransferStatus({id:data.stockTransferId,updateData:{transferStatus:"confirm"}}))
                }}
                disabled={data.transferStatus !== 'pending'}
            />
          </div>
        </Box>
      </MDBox>
    </ModalComponent>
  );
}

StockTransferViewModel.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
    data:PropTypes.object
};

export default StockTransferViewModel;
