import React, {useEffect, useState} from "react";
import MDBox from "../MDBox";
import Card from "@mui/material/Card";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import IconButton from '@mui/material/IconButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
function Calender (){

    const [isCalendarVisible, setCalendarVisibility] = React.useState(false);
    const toggleCalendar = () => {
        setCalendarVisibility(!isCalendarVisible);
    };

    return(
        <MDBox>
            <Card>
                <IconButton  onClick={toggleCalendar} color="primary">
                    <CalendarMonthIcon style={{ color: '#2D9DE5' }}/>
                </IconButton>
            </Card>
            <Card sx={{position:'absolute'}}>
                {isCalendarVisible && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar />
                    </LocalizationProvider>
                )}
            </Card>
        </MDBox>
    );
}
export default Calender;
