import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "services/AxiosOrders";


export const getAllBranchPO = createAsyncThunk('get-all-branch-po', async (page, { rejectWithValue }) => {
        try {
            const {data} = await instance.get(`/branch_po`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getBranchPOItemsById = createAsyncThunk('get-branch-po-items-by-id', async (id, { rejectWithValue }) => {
        try {
            const {data} = await instance.get(`/branch_po/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const saveBranchPOItem = createAsyncThunk('save-branch-po-item', async (poItemData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/branch_po`, poItemData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateBranchPOStatus = createAsyncThunk('update-branch-po-status', async ({id,updateData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/branch_po/status_update/${id}`,  updateData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateBranchPOItem = createAsyncThunk('update-branch-po-item', async ({id,updateData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/branch_po/${id}`,  updateData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const deleteBranchPOItem = createAsyncThunk('delete-branch-po-item', async (id, { rejectWithValue }) => {
        try {
            const data = await instance.delete(`/branch_po/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
