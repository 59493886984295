import WideCard from "common/components/WideCard";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CommonButton from "common/components/Button";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import POStockApprovalModal from "components/POStockApprovalModal";
import {useDispatch, useSelector} from "react-redux";
import {getAllBranchPO} from "services/purchaseOrderBranch/purchaseOrderBranch";

function POStockView(){

    const dispatch = useDispatch();
    const [isModalOpen,setModalOpen] = useState(false);
    const [purchaseOrders,setPurchaseOrders] = useState([]);
    const [selectedPO, setSelectedPO] = useState({})
    const poData = useSelector(state => state.BranchPOSlice.branchPurchaseOrder);

    const tableColumns = [
        {field: "pono", headerName: "PO No", accessor: "pono", textAlign: 'left'},
        {field: "date", headerName: "Date", accessor: "date", textAlign: 'left'},
        {field: "branch", headerName: "Branch", accessor: "branch", textAlign: 'left'},
        {field: "status", headerName: "Status", accessor: "status", textAlign: 'left'},
        {field: "action", headerName: "Action", accessor: "action", textAlign: 'left'},
    ];
    const searchKey = ['pono', 'date'];

    useEffect(() => {
        dispatch(getAllBranchPO());
    }, []);

    useEffect(() => {
        if(poData.isSuccess && !poData.isLoading){
            const data = poData?.data;
            if(Array.isArray(data)){
                const array = data.map((item)=>(
                    {
                        pono:item.poNo,
                        branch:item.branchId.name,
                        status:(
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <CommonButton
                                    sx={{width: '100px',backgroundColor:item.postatusPo === 'approval'? '#2D9DE5' :item.postatusPo === 'approved'? '#1C5D7C' : '' }}
                                    name={item.postatusPo}
                                    circular={true}
                                    variant={item.postatusPo === 'pending'? 'outline': 'Fill'}
                                    onClick={() => {

                                    }}
                                />
                            </div>
                        ),
                        date : item.poDate,
                        action:(
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <IconButton
                                    onClick={() => {
                                        setSelectedPO(item);
                                        setModalOpen(true);
                                    }}>
                                    <RemoveRedEyeIcon sx={{color: '#2D9DE5'}}/>
                                </IconButton>
                            </div>
                        ),

                    }
                ));
                setPurchaseOrders(array)
            }
        }
    }, [poData.data]);

    return(
        <WideCard>
            <Typography sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '18px'}}>Purchase Order View</Typography>
            <Box>
                <Divider sx={{borderBottom: '1.3px solid black'}}/>
            </Box>

            <MDBox sx={{marginY: 2,}}>
                <MDTable tableData={purchaseOrders} tableColumns={tableColumns} searchKey={searchKey} isSearch={true}/>
            </MDBox>

            {isModalOpen &&
                <POStockApprovalModal onClose={()=>{setModalOpen(false)}} open={isModalOpen} poData={selectedPO}/>
            }
        </WideCard>
    )
}

export default POStockView;
