import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "services/AxiosOrders";

export const getAvailableSlotsByDoctorAndDate = createAsyncThunk('get-doctors', async ({id , date}, { rejectWithValue }) => {
        try {
            const {data} = await instance.get('/slot/get_available_slots_by_date_and_doctor',{
                params:{
                    date:date,
                    doctorId:id,
                },
            });
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getAllSlots = createAsyncThunk('get-slots', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/slot/search_slot_relate_details`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});


export const postSlot = createAsyncThunk('api', async (slotData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/slot`, slotData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const searchSlots = createAsyncThunk('search-slot', async (searchData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/slot/search_slot_more_details`, searchData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});
