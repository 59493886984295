import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../AxiosOrders";
export const getSuppliers = createAsyncThunk('get-supplier', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/supplier`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const postSupplier = createAsyncThunk('post-supplier', async (supData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/supplier`, supData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateSupplier = createAsyncThunk('supplier-update', async ({id,supUpData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/supplier/${id}`,  supUpData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const deleteSupplier = createAsyncThunk('delete-supplier', async (id, { rejectWithValue }) => {
        try {
            const data = await instance.delete(`/supplier/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);