import { createSlice } from "@reduxjs/toolkit";
import { getProcedureWiseSales } from "services/report/report";

const initialState = {
    procedureWiseSales: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const procedureWiseSalesSlice = createSlice({
    name: "procedureWiseSales",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProcedureWiseSales.pending, (state) => {
                state.procedureWiseSales.isLoading = true;
            })
            .addCase(getProcedureWiseSales.fulfilled, (state, { payload }) => {
                state.procedureWiseSales.isLoading = false;
                state.procedureWiseSales.isSuccess = true;
                state.procedureWiseSales.data = payload;
            })
            .addCase(getProcedureWiseSales.rejected, (state, { payload }) => {
                state.procedureWiseSales.isLoading = false;
                state.procedureWiseSales.isSuccess = false;
                state.procedureWiseSales.errorMessage = payload;
            });
    },
});

export default procedureWiseSalesSlice.reducer;