import { createSlice } from "@reduxjs/toolkit";
import { deleteCategory } from "services/category/category";

const initialState = {
  category: {
    data: [],
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
  },
};

export const deleteCategorySlice = createSlice({
  name: "deleteCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteCategory.pending, (state) => {
        state.category.isLoading = true;
      })
      .addCase(deleteCategory.fulfilled, (state, { payload }) => {
        state.category.isLoading = false;
        state.category.isSuccess = true;
        state.category.data = payload;
      })
      .addCase(deleteCategory.rejected, (state, { payload }) => {
        state.category.isLoading = false;
        state.category.isSuccess = false;
        state.category.errorMessage = payload;
      });
  },
});

export default deleteCategorySlice.reducer;
