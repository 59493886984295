import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../AxiosOrders";

export const getAllBookings = createAsyncThunk('get-bookings', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/booking`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const saveBooking = createAsyncThunk('save-booking', async (bookingData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/booking`, bookingData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getCheckinList = createAsyncThunk('get-checkin-list', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/booking/role_wise_checking`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getCheckoutList = createAsyncThunk('get-checkout-list', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/booking/role_wise_checkout`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});


