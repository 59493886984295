import { createSlice } from "@reduxjs/toolkit";
import {deletePatientCheck} from "services/PatientCheck/patientCheck";

const initialState = {
    patientCheck: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const deletePatientCheckSlice = createSlice({
    name: "deletePatientCheck",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(deletePatientCheck.pending, (state) => {
                state.patientCheck.isLoading = true;
            })
            .addCase(deletePatientCheck.fulfilled, (state, { payload }) => {
                state.patientCheck.isLoading = false;
                state.patientCheck.isSuccess = true;
                state.patientCheck.data = payload;
            })
            .addCase(deletePatientCheck.rejected, (state, { payload }) => {
                state.patientCheck.isLoading = false;
                state.patientCheck.isSuccess = false;
                state.patientCheck.errorMessage = payload;
            });
    },
});

export default deletePatientCheckSlice.reducer;
