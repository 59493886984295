import React from 'react';
import { styled,useTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        border: '10px',
        paddingLeft: 15,
        paddingRight: 15,
        '&.Mui-selected': {
            color: '#000',
            fontWeight: 500,
            backgroundColor: '#ffffff',
            boxShadow: '1px 1px 1px 1px #d7d7d7',
        },
        '&.Mui-disabled': {
            border: 10,
        },
        '&:not(:first-of-type)': {
            borderRadius: 20,
            paddingY: 20,
        },
        '&:first-of-type': {
            borderRadius: 20,
        },
    },
}));

export default function Toggle ({data, value, onChange,isDisable}){
    const theme = useTheme();
    return (
        <Box
            elevation={0}
            sx={{
                width:'fit-content',
                display: 'flex',
                padding: "4px",
                borderRadius: 20,
                backgroundColor: '#EAF5FC',
                flexWrap: 'wrap',
            }}
        >
            <StyledToggleButtonGroup
                size="small"
                value={value}
                exclusive
                onChange={onChange}
                aria-label="text alignment"
            >
                {data.map((val)=> (
                    <ToggleButton
                        disabled={isDisable}
                        value={val.value}
                        key={val}
                        aria-label="left aligned"
                        sx={{
                            fontSize: '12px',
                            [theme.breakpoints.down('xs')]: {
                                fontSize: '10px',
                            },
                            [theme.breakpoints.down('sm')]: {
                                fontSize: '10px',
                            },
                        }}
                    >
                        {val.view}
                    </ToggleButton>
                ))}
            </StyledToggleButtonGroup>
        </Box>
    )
}

Toggle.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        view: PropTypes.node.isRequired,
    })).isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isDisable: PropTypes.bool,
};
