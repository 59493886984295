import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import Typography from '@mui/material/Typography';

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 4,
    outline: '1.5px solid #2D9DE5',
    width: 14.5,
    height: 14.5,
    backgroundColor: theme.palette.mode === 'dark' ? '#0a4c7a' : 'rgba(0, 0, 0, 0)',
    '.Mui-focusVisible &': {
        outline: '2px auto #2D9DE5',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#2D9DE5',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: 14.5,
        height: 14.5,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#2D9DE5',
    },
});

function CommonCheckBox({onChange,label,variant, ...rest}) {

    const handleChange = (event) => {
        if(onChange){
            onChange(event.target.checked);
        }
    };

    return (

        (variant === 'Fill') ?
            <div style={{display :'flex',alignItems:'center'}}>
                <div style={{backgroundColor:'rgba(45, 157, 229, 0.2)',borderRadius:'7px',margin:2}}>
                    <Checkbox
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                        onChange={handleChange}
                        {...rest}
                    />
                </div>

                <Typography sx={{fontSize: 14,fontWeight:'normal', color: '#2D9DE5'}}>
                    {label}
                </Typography>

            </div>
            :
            <div style={{display :'flex',alignItems:'center'}}>

                <Checkbox
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    onChange={handleChange}
                    {...rest}
                />

                <Typography sx={{fontSize: 14,fontWeight:'normal', color: '#2D9DE5'}}>
                    {label}
                </Typography>

            </div>
    );
}

export default CommonCheckBox;

CommonCheckBox.defaultProps = {
    variant: "Outline",
};

CommonCheckBox.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    variant:PropTypes.oneOf(['Fill','Outline'])
};
