import {Divider, Grid, IconButton, Typography} from "@mui/material";
import {Box} from "@mui/system";
import MDTable from "common/components/MDTable";
import WideCard from "common/components/WideCard";
import React, {useEffect,useState} from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import GrnIssueViewModal from "components/GrnIssueViewModal";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useDispatch, useSelector} from "react-redux";
import {getGrn} from "services/grn/grn";
import {generateDate} from "common/utils/CommonFunctions";

export default function GrnIssueView() {
    const dispatch = useDispatch();
    const getGrnData = useSelector(state => state.GrnSlice.grn);
    const [modelOpen, setModelOpen] = useState(false);
    const [joinedDate, setJoinedDate] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [selectedGrn, setSelectedGrn] = useState(null);

    useEffect(() => {
        dispatch(getGrn({date: joinedDate}));
    }, []);

    useEffect(() => {
        if (joinedDate) {
            dispatch(getGrn({date: generateDate(joinedDate)}));
        }
    }, [joinedDate]);

    useEffect(() => {
        if (getGrnData.isSuccess && !getGrnData.isLoading) {
            const array = []
            getGrnData.data?.forEach((val) => {
                array.push({
                    grnDate: val.date,
                    grnData : val,
                    action: (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "start",
                                justifyContent: "start",
                            }}
                        >
                            <IconButton onClick={() => handleEyeIconClick(val)}>
                                <RemoveRedEyeIcon sx={{color: "#2D9DE5"}}/>
                            </IconButton>
                        </div>
                    ),
                })
            })
            setTableData(array)
        }
    }, [getGrnData.data])

    const tableColumns = [
        {
            field: "grnDate",
            headerName: "GRN Date",
            accessor: "grnDate",
            textAlign: "left",
        },
        {
            field: "action",
            headerName: "Action",
            accessor: "action",
            textAlign: "left",
        },
    ];

    const searchKey = ["grnDate"];

    const handleDateChange = (date) => {
        setJoinedDate(date)
    }

    const handleEyeIconClick = (grnData) => {
        setSelectedGrn(grnData);
        setModelOpen(true);
    }

    return (
        <WideCard>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                        sx={{color: "#1C5D7C", fontWeight: 410, fontSize: "18px"}}
                    >
                        GRN Issue View
                    </Typography>
                    <Box>
                        <Divider sx={{borderBottom: "1.3px solid black"}}/>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker sx={{width: "100%"}} label="Date" value={joinedDate} onChange={handleDateChange}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MDTable tableData={tableData} tableColumns={tableColumns} searchKey={searchKey} isSearch={false}/>
                </Grid>
            </Grid>

            {modelOpen &&
                <GrnIssueViewModal open={modelOpen} onClose={() => setModelOpen(false)} grnData={selectedGrn}/>
            }

        </WideCard>
    );
}
