import {
    Typography,
    Box,
    Divider,
    Grid,
    Avatar,
    IconButton,
} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import DropDown from "common/components/DropDown/DropDown";
import WideCard from "common/components/WideCard";
import React, {useEffect, useState} from "react";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import TextInput from "common/components/TextInput/TextInput";
import CommonButton from "common/components/Button";
import MDTable from "common/components/MDTable";
import {itm} from "config/images";
import {Delete} from "@mui/icons-material";
import {getItemsByBranch} from "services/item/item";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteDailyUsage,
    getDailyUsage,
    saveDailyUsage,
    updateDailyUsage,
    updateDailyUsageStatus
} from "services/DailyUsage/DailyUsage";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";
import {generateDate} from "common/utils/CommonFunctions";
import dayjs from "dayjs";

export default function DailyUsage() {
    const dispatch = useDispatch();
    const itemData = useSelector(state => state.ItemsByBranchSlice.item);
    const dailyUsageSaveData = useSelector(state => state.SaveDailyUsageSlice.dailyUsage);
    const dailyUsageData = useSelector(state => state.DailyUsageSlice.dailyUsage);
    const dailyUsageUpdateData = useSelector(state => state.UpdateDailyUsageSlice.dailyUsage);
    const dailyUsageDeleteData = useSelector(state => state.DeleteDailyUsageSlice.dailyUsage);
    const dailyUsageUpdateStatusData = useSelector(state => state.UpdateDailyUsageStatusSlice.dailyUsage);
    const authData = useSelector(state => state.AuthSlice.auth);
    const [date, setDate] = useState(dayjs());
    const [selectedItem, setSelectedItem] = useState('');
    const [currentStock, setCurrentStock] = React.useState("");
    const [qty, setQty] = useState('');
    const [qtyError, setQtyError] = useState('');
    const [items, setItems] = useState([]);
    const [dailyUsageItems, setDailyUsageItems] = useState([]);
    const [saveLoader, setSaveLoader] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [selectedUsageId,setSelectedUsageId] = useState('')
    const [isUpdate,setIsUpdate] = useState(false);
    const [status,setStatus] = useState('');

    const isComplete = date && selectedItem && qty && !qtyError

    const clearFields = ()=>{
        setSelectedItem('')
        setCurrentStock('');
        setQty('');
    }

    const isItemExist = ()=>{
        if(dailyUsageItems.length > 0){
            const filterItem = dailyUsageItems.filter(itm => itm.itemId === selectedItem);
            return filterItem.length > 0;
        }else {
            return false
        }
    }

    const savedDailyUsage = () => {

        const usageData = {
            date: generateDate(date),
            itemId:selectedItem ,
            qty:qty
        };

        if(isUpdate){
            setSaveLoader(true)
            dispatch(updateDailyUsage({id:selectedUsageId,usageData:usageData}))
        }else {
            if(isItemExist()){
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Item Already Exist'
                })
            }else {
                setSaveLoader(true);
                dispatch(saveDailyUsage(usageData));
            }
        }

    };

    useEffect(() => {
        if (saveLoader) {
            setSaveLoader(false);
            if (dailyUsageSaveData.isSuccess && !dailyUsageSaveData.isLoading) {
                dispatch(getDailyUsage({branchId:authData.data.branchId,date:generateDate(date)}))
                clearFields()
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Daily Usage Saved Success',
                })

            } else if (!dailyUsageSaveData.isSuccess && !dailyUsageSaveData.isLoading) {
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Daily Usage Saved Warning'
                })
            }
        }
    }, [dailyUsageSaveData.data]);

    useEffect(() => {
        if (saveLoader) {
            setSaveLoader(false);
            if (dailyUsageUpdateData.isSuccess && !dailyUsageUpdateData.isLoading) {
                setIsUpdate(false);
                dispatch(getDailyUsage({branchId:authData.data.branchId,date:generateDate(date)}))
                clearFields()
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Daily Usage Update Success',
                })

            } else if (!dailyUsageUpdateData.isSuccess && !dailyUsageUpdateData.isLoading) {
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Daily Usage Update Warning'
                })
            }
        }
    }, [dailyUsageUpdateData.data]);

    useEffect(() => {
        if (deleteLoader) {
            setDeleteLoader(false);
            if (dailyUsageDeleteData.isSuccess && !dailyUsageDeleteData.isLoading) {
                dispatch(getDailyUsage({branchId:authData.data.branchId,date:generateDate(date)}))
                clearFields();
                setIsUpdate(false);
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Daily Usage Delete Success',
                })

            } else if (!dailyUsageDeleteData.isSuccess && !dailyUsageDeleteData.isLoading) {
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Daily Usage Delete Warning'
                })
            }
        }
    }, [dailyUsageDeleteData.data]);

    useEffect(() => {
        if (saveLoader) {
            setSaveLoader(false);
            if (dailyUsageUpdateStatusData.isSuccess && !dailyUsageUpdateStatusData.isLoading) {
                dispatch(getDailyUsage({branchId:authData.data.branchId,date:generateDate(date)}));
                dispatch(getItemsByBranch());
                clearFields()
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Daily Usage Sent Success',
                })

            } else if (!dailyUsageUpdateStatusData.isSuccess && !dailyUsageUpdateStatusData.isLoading) {
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Daily Usage Sent Warning'
                })
            }
        }
    }, [dailyUsageUpdateStatusData.data]);

    useEffect(() => {
        dispatch(getItemsByBranch());
        dispatch(getDailyUsage({branchId:authData.data.branchId,date:generateDate(date)}))
    }, []);

    useEffect(() => {
        if(itemData.isSuccess && !itemData.isLoading){
            const data = itemData?.data;
            if(Array.isArray(data)){
                const array = data.map((item)=>(
                    {
                        label:item.item.itemName,
                        value:item.item.itemId,
                        qty:item.qty
                    }
                ));
                setItems(array);
            }
        }
    }, [itemData.data]);

    useEffect(() => {
        if(dailyUsageData.isSuccess && !dailyUsageData.isLoading){
            const data = dailyUsageData?.data;
            if(Array.isArray(data)){
                setStatus(data[0]?.status)
                const array = data.map((item)=>{
                    const filteredItems = items.filter(itm => itm.value === item.item.itemId);
                    return (
                        {
                            itemId:item.item.itemId,
                            item:(
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <Avatar
                                        sx={{
                                            width: 35,
                                            height: 35,
                                            margin: "0px",
                                            padding: "5px",
                                            backgroundColor: "#FFFFFF",
                                            marginRight: "10px",
                                            marginTop: "8px",
                                        }}
                                        src={itm}
                                    />

                                    <div style={{marginTop: "10px"}}>{filteredItems[0]?.label}</div>
                                </div>
                            ),
                            itemName:filteredItems[0]?.label,
                            qty:item.qty,
                            action: (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <CommonButton
                                        name={'Update'}
                                        variant={'Fill'}
                                        circular={true}
                                        onClick={()=>{
                                            setQty(item.qty);
                                            setSelectedItem(item.item.itemId);
                                            setCurrentStock(filteredItems[0].qty);
                                            setSelectedUsageId(item.id);
                                            setIsUpdate(true);
                                        }}
                                        disabled={data[0]?.status === 'send'}
                                    />

                                    <IconButton
                                        onClick={()=>{
                                            setDeleteLoader(true);
                                            dispatch(deleteDailyUsage(item.id))
                                        }}
                                        disabled={data[0]?.status === 'send'}
                                    >
                                        <Delete sx={{color: "#1C5D7C"}}/>
                                    </IconButton>
                                </div>
                            ),
                        }
                    )
                });
                setDailyUsageItems(array);
            }
        }
    }, [dailyUsageData.data]);

    const tableColumns = [
        {field: "item",headerName: "Item",accessor: "item",textAlign: "left"},
        {field: "qty", headerName: "Qty", accessor: "qty", textAlign: "left"},
        {field: "action", headerName: "", accessor: "action", textAlign: "left"},
    ];
    const searchKey = ["item", "qty"];
    return (
        <WideCard>
            <Typography sx={{color: "#1C5D7C", fontWeight: 410, fontSize: "18px"}}>
                Daily Usage
            </Typography>
            <Box>
                <Divider sx={{borderBottom: "1.3px solid black"}}/>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{width: "100%"}}
                            label="Date"
                            value={date}
                            onChange={(date) => {
                                dispatch(getDailyUsage({branchId:authData.data.branchId,date:generateDate(date)}))
                                setDate(date)
                                setSelectedItem('');
                                setCurrentStock('');
                            }}
                            disabled={isUpdate}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <DropDown
                        label={"Item"}
                        options={items}
                        value={selectedItem}
                        onChange={(value) =>{
                            setSelectedItem(value)
                            const filteredItems = items.filter(item => item.value === value);
                            setCurrentStock(filteredItems[0].qty);
                        }}
                        disabled={status === 'send' || isUpdate}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <TextInput
                        placeholder={"Current Stock"}
                        value={currentStock}
                        onChange={(val)=>{
                            setCurrentStock(val.target.value)
                        }}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2}>
                    <TextInput
                        placeholder={'Qty'}
                        value={qty}
                        onChange={(e)=>{
                            setQty(e.target.value)
                            if (!/^\d+$/.test(e.target.value)) {
                                setQtyError("Invalid Qty");
                            } else if(parseInt(currentStock) < parseInt(e.target.value)) {
                                setQtyError("Error");
                            }else{
                                setQtyError("");
                            }
                        }}
                        error={qtyError !== ""}
                        variant="outlined"
                        disabled={status === 'send'}
                    />
                    {qtyError && <div style={{color: 'red', fontSize: '12px'}}>{qtyError}</div>}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} sx={{textAlign: "right"}}>
                    {isUpdate &&
                        <CommonButton
                            name={"Cancel"}
                            variant={"outline"}
                            style={{padding: "10px 40px",marginRight:'10px'}}
                            onClick={()=>{
                                clearFields();
                                setIsUpdate(false)
                            }}
                        />
                    }
                    <CommonButton
                        name={isUpdate? 'Update' : "+ Add"}
                        variant={"Fill"}
                        style={{padding: "10px 40px"}}
                        onClick={()=>{
                            savedDailyUsage();
                        }}
                        disabled={!isComplete}
                    />

                </Grid>
            </Grid>
            <MDTable
                tableData={dailyUsageItems}
                tableColumns={tableColumns}
                searchKey={searchKey}
                isSearch={false}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <CommonButton
                    name={status}
                    variant={"outline"}
                    style={{borderRadius: "20px", padding: "2px 20px"}}
                />
                <CommonButton
                    name={"Send"}
                    variant={"Fill"}
                    style={{padding: "10px 30px"}}
                    onClick={()=>{
                        setSaveLoader(true);
                        dispatch(updateDailyUsageStatus({date:generateDate(date)}));
                    }}
                    disabled={status === 'send' || dailyUsageItems.length===0}
                />
            </div>
        </WideCard>
    );
}
