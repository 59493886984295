import Grid from "@mui/material/Grid";
import DropDown from "common/components/DropDown/DropDown";
import MDBox from "common/components/MDBox";
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {Avatar} from "@mui/material";
import {itm} from "config/images";
import CommonButton from "common/components/Button";
import StockTransferItemAddModal from "components/StockTransferItemAddModal";
import {useDispatch, useSelector} from "react-redux";
import {getAllBranches} from "services/branch/branch";
import {getReceiverBranchItems, getSendBranchItems} from "services/stockTransfer/stockTransfer";

function StockTransferAdd() {

    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const [branches,setBranches] = useState([])
    const [sendBranch,setSendBranch] = useState('');
    const [receiveBranch,setReceiveBranch] = useState('');
    const [sentItems,setSentItems] = useState([]);
    const [receiveItems,setReceiveItems] = useState([]);
    const branchData = useSelector(state => state.BranchSlice.branch);
    const receiverBranchItemData = useSelector(state => state.ReceiverBranchStockTransferItemsSlice.stockTransfer);
    const sendBranchItemData = useSelector(state => state.SentBranchStockTransferItemsSlice.stockTransfer);

    const isComplete = sendBranch && receiveBranch

    useEffect(() => {
        dispatch(getAllBranches())
    }, []);

    useEffect(() => {
        if(branchData.isSuccess && !branchData.isLoading){
            const data = branchData?.data;
            if(Array.isArray(data)){
                const array = data.map((branch)=>(
                    {
                        label:branch.name,
                        value:branch
                    }
                ));
                setBranches(array);
            }
        }
    }, [branchData.data]);

    useEffect(() => {
        if(loader){
            setLoader(false)
            if(receiverBranchItemData.isSuccess && !receiverBranchItemData.isLoading){
                const data = receiverBranchItemData?.data;
                if(Array.isArray(data)){
                    const array = data.map((item)=>(
                        {
                            itemName:item.item.itemName,
                            qty:item.qty
                        }
                    ));
                    setReceiveItems(array);
                }
            }
        }

    }, [receiverBranchItemData.data]);

    useEffect(() => {
        if(loader){
            setLoader(false)
            if(sendBranchItemData.isSuccess && !sendBranchItemData.isLoading){
                const data = sendBranchItemData?.data;
                if(Array.isArray(data)){
                    const array = data.map((item)=>(
                        {
                            itemId:item.item.itemId,
                            itemName:item.item.itemName,
                            qty:item.qty,
                            isDisable:false
                        }
                    ));
                    setSentItems(array);
                }
            }
        }

    }, [sendBranchItemData.data]);

    return(
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <MDBox sx={{marginY: 2}}>
                        <DropDown
                            label={'Sent Branch'}
                            options={branches}
                            value={sendBranch}
                            onChange={(value) => {
                                setSendBranch(value)
                                setLoader(true);
                                dispatch(getSendBranchItems(value.branchId));
                            }}
                        />
                    </MDBox>
                    <MDBox sx={{margin: 2,display:'flex',justifyContent:'space-between'}}>
                        <Typography sx={{ color: "#1C5E7C", fontWeight: 410, fontSize: "16px" }}>
                            Item
                        </Typography>
                        <Typography sx={{ color: "#1C5E7C", fontWeight: 410, fontSize: "16px" }}>
                            Qty
                        </Typography>
                    </MDBox>

                    <div style={{height:'250px',overflowY:"auto"}}>
                        {
                            sentItems.map((item)=>(
                                <MDBox sx={{margin: 2,display:'flex',justifyContent:'space-between'}} >
                                    <MDBox sx={{display:'flex',alignItems:'center'}}>
                                        <Avatar
                                            sx={{
                                                width: 35,
                                                height: 35,
                                                margin: "0px",
                                                backgroundColor: "#FFFFFF",
                                                marginRight: "10px",
                                            }}
                                            src={itm}
                                        />

                                        <Typography sx={{ color: "#1C5E7C", fontWeight: 410, fontSize: "14px" }}>
                                            {item.itemName}
                                        </Typography>

                                    </MDBox>

                                    <MDBox>
                                        <Typography sx={{ color: "#1C5E7C", fontWeight: 410, fontSize: "14px" }}>
                                            {item.qty}
                                        </Typography>
                                    </MDBox>
                                </MDBox>
                            ))
                        }
                    </div>

                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <MDBox sx={{marginY: 2}}>
                        <DropDown
                            label={'Receive Branch'}
                            options={branches}
                            value={receiveBranch}
                            onChange={(value) => {
                                setReceiveBranch(value);
                                setLoader(true);
                                dispatch(getReceiverBranchItems(value.branchId));
                            }}
                        />
                    </MDBox>
                    <MDBox sx={{margin: 2,display:'flex',justifyContent:'space-between'}}>
                        <Typography sx={{ color: "#1C5E7C", fontWeight: 410, fontSize: "16px" }}>
                            Item
                        </Typography>
                        <Typography sx={{ color: "#1C5E7C", fontWeight: 410, fontSize: "16px" }}>
                            Qty
                        </Typography>
                    </MDBox>

                    <div style={{height:'250px',overflowY:"auto"}}>
                        {
                            receiveItems.map((item)=>(
                                <MDBox sx={{margin: 2,display:'flex',justifyContent:'space-between'}} >
                                    <MDBox sx={{display:'flex',alignItems:'center'}}>
                                        <Avatar
                                            sx={{
                                                width: 35,
                                                height: 35,
                                                margin: "0px",
                                                backgroundColor: "#FFFFFF",
                                                marginRight: "10px",
                                            }}
                                            src={itm}
                                        />

                                        <Typography sx={{ color: "#1C5E7C", fontWeight: 410, fontSize: "14px" }}>
                                            {item.itemName}
                                        </Typography>

                                    </MDBox>

                                    <MDBox>
                                        <Typography sx={{ color: "#1C5E7C", fontWeight: 410, fontSize: "14px" }}>
                                            {item.qty}
                                        </Typography>
                                    </MDBox>
                                </MDBox>
                            ))
                        }
                    </div>
                </Grid>
            </Grid>

            <MDBox sx={{margin:2,display:'flex',justifyContent:'end'}}>
                <CommonButton
                    name={"Next"}
                    variant={"outline"}
                    onClick={()=>{
                        setIsAddFormOpen(true);
                    }}
                    disabled={!isComplete}
                />
            </MDBox>

            {isAddFormOpen &&
                <StockTransferItemAddModal
                    onClose={()=>{setIsAddFormOpen(false)}}
                    open={isAddFormOpen}
                    sentItems={sentItems}
                    branchDetails={{
                        sendBranchId:sendBranch.branchId,
                        sendBranchName:sendBranch.name,
                        recBranchId:receiveBranch.branchId,
                        recBranchName:receiveBranch.name
                    }}

                />
            }

        </div>
    )
}

export default StockTransferAdd;
