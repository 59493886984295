import * as React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import {useState} from "react";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function  FileUploadButton({ onFileSelect, setImageUrl, multiple, disabled }) {
    const [fileName,setFileName]= useState('Choose file');
    const handleFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            onFileSelect(event.target.files[0]);
            let reader = new FileReader();
            reader.onload = (e) => {
                let base64Img = e.target.result;
                setImageUrl(base64Img);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
        // const file = event.target.files[0];
        // if (file) {
        //     onFileSelect(file);
        //     setFileName(file.name);
        // }
    };

    return (
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',padding:'2px',border: '1.5px solid #C6C6C6',borderRadius:'6px'}}>
            <Typography margin={'0px 14px'} variant="body2" gutterBottom>{fileName}</Typography>
            <Button disabled={disabled} component="label" style={{ backgroundColor: 'rgba(198, 198, 198, 0.2)',color: '#999999',borderRadius:'6px',boxShadow: 'none',textDecoration: 'none',textTransform: 'capitalize' }} variant="contained">
                Browse
                <VisuallyHiddenInput multiple={multiple} type="file" onChange={handleFileChange} />
            </Button>
        </div>

    );
}

FileUploadButton.defaultProps = {
    multiple:false,
    disabled:false,
}

FileUploadButton.propTypes = {
    onFileSelect:PropTypes.func,
    multiple:PropTypes.bool,
    disabled:PropTypes.bool,
    setImageUrl:PropTypes.func,
}
