import ModalComponent from "common/components/Modal";
import Typography from "@mui/material/Typography";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TextInput from "common/components/TextInput/TextInput";
import CommonButton from "common/components/Button";
import React, {useEffect, useState} from "react";
import IconButton from '@mui/material/IconButton';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import {useDispatch, useSelector} from "react-redux";
import {getSuppliers, postSupplier, updateSupplier} from "services/suppllier/supplier";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";

function SupplierAddModal({open, onClose, isView = false, supplierDetails}) {

    const dispatch = useDispatch();
    const supplierSaveData = useSelector(state => state.SaveSupplierSlice.supplier);
    const supplierUpdateData = useSelector(state => state.UpdateSupplierSlice.supplier);
    const [viewMode, setViewMode] = useState(isView);
    const [editMode, setEditMode] = useState(!viewMode);
    const [name, setName] = useState(isView ? supplierDetails.name : '');
    const [address, setAddress] = useState(isView ? supplierDetails.address : '');
    const [email, setEmail] = useState(isView ? supplierDetails.email : '');
    const [contactNo, setContactNo] = useState(isView ? supplierDetails.contactNo : '');
    const [emailError, setEmailError] = useState('');
    const [nameError, setNameError] = useState('');
    const [contactNoError, setContactNoError] = useState('');
    const [saveLoader, setSaveLoader] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);

    const isComplete = name && address && email && contactNo && !contactNoError && !emailError && !nameError;

    const handleAction = () => {

        const supData = {
            name: name,
            address: address,
            email: email,
            contactNo: contactNo,
        }
        const supUpData = {
            name: name,
            address: address,
            email: email,
            contactNo: contactNo,
        }

        if (viewMode) {
            setUpdateLoader(true);
            dispatch(updateSupplier({id: supplierDetails.supplierId, supUpData: supUpData}));
        } else {
            setSaveLoader(true);
            dispatch(postSupplier(supData));
        }
    }

    useEffect(() => {
        if (saveLoader) {
            setSaveLoader(false);
            if (supplierSaveData.isSuccess && !supplierSaveData.isLoading) {

                setViewMode(true);
                dispatch(getSuppliers());
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Supplier Saved Success',
                })
                onClose();
            } else if (!supplierSaveData.isSuccess && !supplierSaveData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Save Error'
                })
            }
        }
    }, [supplierSaveData.data]);

    useEffect(() => {
        if (updateLoader) {
            setUpdateLoader(false);
            if (supplierUpdateData.isSuccess && !supplierUpdateData.isLoading) {
                dispatch(getSuppliers());
                setEditMode(false);
                setViewMode(true);

                sendSuccessNotification({
                    title: 'Success',
                    message: 'Supplier Update Success',
                })
            } else if (!supplierUpdateData.isSuccess && !supplierUpdateData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Update Error'
                })
            }
        }
    }, [supplierUpdateData.data]);

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="sm">
            <MDBox sx={{margin: '10px'}}>
                <Box>
                    <div>
                        <Typography sx={{
                            color: '#1C5E7C',
                            fontWeight: 450,
                            fontSize: '16px',
                            marginBottom: '25px',
                            position: 'absolute',
                            top: '15px',
                            left: '15px'
                        }}> {viewMode ? 'Supplier' : 'Add Supplier'} </Typography>
                    </div>
                    <div style={{
                        position: 'absolute',
                        top: '8px',
                        left: '70px'
                    }}
                    >
                        {viewMode && <IconButton aria-label="delete">
                            <DriveFileRenameOutlineIcon fontSize={'small'} onClick={() => {
                                if (editMode) {
                                    setEditMode(false);
                                } else {
                                    setEditMode(true);
                                }
                            }}/>
                        </IconButton>}
                    </div>
                </Box>

                <div style={{overflow: 'hidden'}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Box sx={{width: '500px', margin: '0 auto'}}>
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={{marginY: '10px'}}>
                            <TextInput
                                placeholder={'Name'}
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                    if (!/^[a-zA-Z]+( [a-zA-Z]+)*$/.test(e.target.value)) {
                                        setNameError("Invalid Name");
                                    } else {
                                        setNameError("");
                                    }
                                }}
                                error={nameError !== ""}
                                disabled={!editMode}
                                variant="outlined"
                            />
                            {nameError && <div style={{color: 'red', fontSize: '12px'}}>{nameError}</div>}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={{marginY: '10px'}}>
                            <TextInput
                                placeholder={'Address'}
                                value={address}
                                onChange={(e) => {
                                    setAddress(e.target.value)
                                }}
                                disabled={!editMode}
                                variant="outlined"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={{marginY: '10px'}}>
                            <TextInput
                                placeholder={'Email'}
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value)) {
                                        setEmailError("Invalid Email");
                                    } else {
                                        setEmailError("");
                                    }
                                }}
                                error={emailError !== ""}
                                disabled={!editMode}
                                variant="outlined"
                            />
                            {emailError && <div style={{color: 'red', fontSize: '12px'}}>{emailError}</div>}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={{marginY: '10px'}}>
                            <TextInput
                                placeholder={'ContactNo'}
                                value={contactNo}
                                onChange={(e) => {
                                    setContactNo(e.target.value)
                                    if (!/^(\+94|0)\d{9}$/.test(e.target.value)) {
                                        setContactNoError("Invalid Mobile No");
                                    } else {
                                        setContactNoError("");
                                    }
                                }}
                                error={contactNoError !== ""}
                                disabled={!editMode}
                                variant="outlined"
                            />
                            {contactNoError && <div style={{color: 'red', fontSize: '12px'}}>{contactNoError}</div>}
                        </Box>
                    </Grid>
                </Grid>

                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <div style={{margin: '5px'}}>
                        <CommonButton
                            name={'Cancel'}
                            variant={'outline'}
                            onClick={() => {
                                onClose();
                            }}
                        />
                    </div>
                    <div style={{margin: '5px'}}>
                        {editMode &&
                            <CommonButton
                                name={'Confirm'}
                                variant={'Fill'}
                                disabled={!editMode || !isComplete}
                                onClick={handleAction}
                            />
                        }

                    </div>
                </Box>
            </MDBox>
        </ModalComponent>
    );
}

SupplierAddModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isView: PropTypes.bool,
    supplierDetails: PropTypes.object,
};

export default SupplierAddModal;
