import { createSlice } from "@reduxjs/toolkit";
import {getAllBranches} from "services/branch/branch";

const initialState = {
    branch: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const branchSlice = createSlice({
    name: "getAllBranches",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllBranches.pending, (state) => {
                state.branch.isLoading = true;
            })
            .addCase(getAllBranches.fulfilled, (state, { payload }) => {
                state.branch.isLoading = false;
                state.branch.isSuccess = true;
                state.branch.data = payload;
            })
            .addCase(getAllBranches.rejected, (state, { payload }) => {
                state.branch.isLoading = false;
                state.branch.isSuccess = false;
                state.branch.errorMessage = payload;
            });
    },
});

export default branchSlice.reducer;
