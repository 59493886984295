import axios from "axios";
import {getItem} from "common/utils/Storage/Storage";
import {getBaseUrl} from './apiBaseUrls'
const token = getItem('my-dentist-token')

const instance = axios.create({
    baseURL: getBaseUrl(),
    headers: { Authorization : `Bearer ${token}`},
});

export default instance;
