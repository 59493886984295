import { createSlice } from "@reduxjs/toolkit";
import {saveUsers} from "services/user/user";

const initialState = {
    user: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const saveUserSlice = createSlice({
    name: "saveUser",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(saveUsers.pending, (state) => {
                state.user.isLoading = true;
            })
            .addCase(saveUsers.fulfilled, (state, { payload }) => {
                state.user.isLoading = false;
                state.user.isSuccess = true;
                state.user.data = payload;
            })
            .addCase(saveUsers.rejected, (state, { payload }) => {
                state.user.isLoading = false;
                state.user.isSuccess = false;
                state.user.errorMessage = payload;
            });
    },
});

export default saveUserSlice.reducer;
