import React, {useEffect, useState} from "react";
import WideCard from "common/components/WideCard";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import CommonButton from "common/components/Button";
import {useDispatch, useSelector} from "react-redux";
import {getCheckoutList} from "services/booking/booking";
import PropTypes from "prop-types";
import ConfirmAlert from "components/ConfirmAlert";
import {patientAdd} from "config/images";
import {updateBookingStatus} from "services/dashboard/dashboard";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";

function CheckoutList({changePage}){

    const dispatch = useDispatch();
    const [checkoutList,setCheckoutList] = useState([]);
    const checkoutListData = useSelector(state => state.CheckoutListSlice.booking);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [bookId, setBookId] = useState('');
    const [patientName, setPatientName] = useState('');
    const [updateLoader, setUpdateLoader] = useState(false);
    const statusUpdate = useSelector((state) => state.UpdateBookingStatusSlice.booking);

    const closeAlert = () => {
        setIsAlertOpen(false);
    };
    const openAlert = (id, patient) => {
        setBookId(id);
        setPatientName(`${patient.title} ${patient.firstName} ${patient.lastName}`);
        setIsAlertOpen(true);
    };
    useEffect(() => {
        if (updateLoader) {
            setUpdateLoader(false);
            if (statusUpdate.isSuccess && !statusUpdate.isLoading) {
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Confirmation Success',
                })
                dispatch(getCheckoutList());
                closeAlert();
            } else if (!statusUpdate.isSuccess && !statusUpdate.isLoading) {
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Confirmation Error'
                })
            }
        }
    }, [statusUpdate.data]);

    const tableColumns = [
        {field: "pName", headerName: "Patient Name", accessor: "pName", textAlign: 'left'},
        {field: "doctor", headerName: "Doctor", accessor: "doctor", textAlign: 'left'},
        {field: "date", headerName: "Date", accessor: "date", textAlign: 'left'},
        {field: "time", headerName: "Time", accessor: "time", textAlign: 'left'},
        {field: "action", headerName: "Action", accessor: "action", textAlign: 'left'},
        {field: "add_procedures", headerName: "", accessor: "action", textAlign: 'left'},
    ];

    const searchKey = ['pName', 'doctor'];

    useEffect(() => {
        dispatch(getCheckoutList());
    }, []);

    useEffect(() => {
        if(checkoutListData.isSuccess){
            const data = checkoutListData?.data;
            if(Array.isArray(data)){
                const array = data.map((val,index)=>({
                    id: index,
                    pName: `${val.patient.title} ${val.patient.firstName} ${val.patient.lastName}`,
                    doctor:`${val.slot.doctor.title} ${val.slot.doctor.name}`,
                    date:val.slot.date,
                    time:`${val.slot.startTime} - ${val.slot.endTime}`,
                    action:(<div style={{display: 'flex', flexDirection: 'row'}}>
                        <CommonButton
                            name={'Payments'}
                            variant={'outline'}
                            circular={true}
                            onClick={()=>{
                                changePage(val?.bookingId);
                            }}
                        />
                    </div>),
                    add_procedures: (
                        <div key={index} style={{display: 'flex', flexDirection: 'row', marginTop: '5px'}}>
                            <CommonButton
                                name={'Re add procedures'}
                                variant={'Fill'}
                                circular={true}
                                onClick={() => {
                                    openAlert(val.bookingId, val.patient);
                                }}
                            />
                        </div>
                    )
                }));
                setCheckoutList(array);
            }
        }
    }, [checkoutListData.data]);

    return(
        <div>
            <WideCard>
                <Typography
                    sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '19px',}}>Checkout List</Typography>
                <Box>
                    <Divider sx={{borderBottom: '1.3px solid black', marginTop: '6px',}}/>
                </Box>

                <MDBox sx={{marginY: 2}}>
                    <MDTable tableData={checkoutList} tableColumns={tableColumns} searchKey={searchKey} isSearch={true}/>
                </MDBox>

            </WideCard>
            <ConfirmAlert
                src={patientAdd}
                name={patientName}
                open={isAlertOpen}
                onClose={closeAlert}
                title="Are you sure"
                subtitle={`You want to add new procedures`}
                buttonText="Confirm"
                onclick={() => {
                    setUpdateLoader(true);
                    dispatch(updateBookingStatus({id: bookId, statusUpData: {status: "checking"}}));
                    setIsAlertOpen(false);
                }}
            />
        </div>
    );
}

CheckoutList.propTypes = {
    changePage: PropTypes.func.isRequired,
};
export default CheckoutList;
