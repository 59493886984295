import React, {useState} from "react";
import CheckingList from "components/CheckingList";
import ClinicalNote from "components/ClinicalNote";
import SelectProcedure from "components/SelectProcedure";
import ExtraCharge from "components/ExtraCharge";
import ToothChart from "../../components/ToothChart";

function PatientProcessFlow(){

   const [page,setPage] = useState('checking-list');
   const [selectedAppointment,setSelectedAppointment] = useState({});

    return(
        <div>
            {page === 'checking-list'?
                <CheckingList
                    changePage={(val)=>{
                        console.log("ccc",val.bookingId)
                        setSelectedAppointment(val)
                        setPage('select-procedures')
                    }}
                />

                : page === 'clinical-note' ?
                    <ClinicalNote
                        changePage={(val)=>{
                            setPage(val)
                        }}
                        patientId={selectedAppointment.patient.id}
                    />

                    : page === 'select-procedures' ?
                        <SelectProcedure
                            changePage={(val)=>{
                                setPage(val)
                            }}
                            bookingId={selectedAppointment.bookingId}
                        />

                        : page === 'extra-charge' ?
                            <ExtraCharge
                                changePage={(val)=>{
                                    setPage(val)
                                }}
                                bookingId={selectedAppointment.bookingId}
                            />

                            : page === 'tooth-chart' ?
                                <ToothChart
                                    changePage={(val)=>{
                                        setPage(val)
                                    }}
                                    patientId={selectedAppointment.patient.id}
                                />
                                :
                                <></> }
        </div>
    );
}

export default PatientProcessFlow
