import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const StyledButton = styled(Button)(({theme,circular,variant,color})=>{

    const {borders,palette} = theme;
    const { borderRadius } = borders;
    const { transparent} = palette;

    const containedStyle = {
        padding: circular === true ? '9px 15px' : '9px 9px',
        backgroundColor: color,
        color:'white',
        textTransform: 'none',
        fontWeight: 'normal',
        fontSize: 13,

        '&:hover': {
            backgroundColor: color,
            color:'white',
        },
        '&:active': {
            backgroundColor: color,
            color:'white',
        },

    }

    const outlineStyle = {
        padding: circular === true ? '9px 15px' : '9px 9px',
        backgroundColor: transparent.main,
        color:'#2D9DE5',
        border: '1px solid',
        borderColor: '#2D9DE5',
        textTransform: 'none',
        fontWeight: 'normal',
        fontSize: 13,

        '&:hover': {
            backgroundColor: transparent.main,
            color:'#2D9DE5',
            border: '1px solid',
            borderColor: '#2D9DE5',
        },
        '&:active': {
            backgroundColor: transparent.main,
            color:'#2D9DE5',
            border: '1px solid',
            borderColor: '#2D9DE5',
        },

    }

    const circularStyle = {
        borderRadius: borderRadius.section,
    }

    return {
        ...(variant === "Fill" && containedStyle),
        ...(variant === "outline" && outlineStyle),
        ...(circular && circularStyle),

    }
});

export default StyledButton;
