import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../AxiosOrders";

export const postExtraCharge = createAsyncThunk('post-charge', async (chargeData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/extra_charge_and_fees`, chargeData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});
