import { createSlice } from "@reduxjs/toolkit";
import {dailyUsagePending} from "services/stockDashboard/stockDashboard";

const initialState = {
    daily: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const dailyUsage = createSlice({
    name: "dailyUsagePending",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(dailyUsagePending.pending, (state) => {
                state.daily.isLoading = true;
            })
            .addCase(dailyUsagePending.fulfilled, (state, { payload }) => {
                state.daily.isLoading = false;
                state.daily.isSuccess = true;
                state.daily.data = payload;
            })
            .addCase(dailyUsagePending.rejected, (state, { payload }) => {
                state.daily.isLoading = false;
                state.daily.isSuccess = false;
                state.daily.errorMessage = payload;
            });
    },
});

export default dailyUsage.reducer;
