import React, {useEffect, useState} from 'react';
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CommonButton from "common/components/Button";
import {DoctorBag, tooth} from "config/images";
import WideCard from "common/components/WideCard";
import ManageProceduresAddModal from "components/ManageProceduresAddModal";
import ManageProceduresUpdateModal from "components/ManageProceduresUpdateModal";
import ConfirmAlert from "components/ConfirmAlert";
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import DropDown from "common/components/DropDown/DropDown";
import Grid from "@mui/material/Grid";
import {auto} from "@popperjs/core";
import {useDispatch, useSelector} from "react-redux";
import {deleteProcedure, getAllProcedures} from "services/procedure/procedure";
import {getAllBranches} from "services/branch/branch";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";

function ManageProcedures() {
    const dispatch = useDispatch();
    const getProcedureData = useSelector(state => state.ProcedureSlice.procedure);
    const dltProcedureData = useSelector(state => state.DeleteProcedureSlice.procedure);
    const getBranchData = useSelector(state => state.BranchSlice.branch);
    const [branch, setBranch] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({});
    const [openAdd, setOpenAdd] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [selectedProcedure, setSelectedProcedure] = useState({});

    const handleDelete = (id) => {
        setDeleteLoader(true)
        dispatch(deleteProcedure(id));
    };

    useEffect(() => {
        if (deleteLoader) {
            setDeleteLoader(false)
            if (dltProcedureData.isSuccess) {
                dispatch(getAllProcedures(selectedProcedure.branch.branchId))
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Procedure Deleted Success',
                })
                setIsAlertOpen(false);
            } else if (!dltProcedureData.isSuccess && !dltProcedureData.isLoading) {
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Procedure Deleted Warning'
                })
            }
        }
    }, [dltProcedureData.data]);

    useEffect(() => {
        if (getProcedureData.isSuccess && !getProcedureData.isLoading) {
            const array = []
            getProcedureData.data?.forEach((val) => {
                array.push({
                    id: val.procedureId,
                    procedures: (
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Avatar
                                sx={{
                                    width: 30,
                                    height: 30,
                                    margin: '0px',
                                    padding: '0px',
                                    backgroundColor: '#EAF5FC',
                                    marginRight: '5px',
                                    marginTop: '8px',
                                }}
                            >
                                <MedicalServicesIcon sx={{color: '#2D9DE5'}}/>

                            </Avatar>

                            <div style={{marginTop: '10px'}}>
                                {val.procedureName}
                            </div>

                        < /div>
                    ),
                    name:val.procedureName,
                    type: val.type,
                    totalCharge: val.totalCharge,
                    action: (
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <IconButton
                                onClick={() => {
                                    handleOpenUpdate(val)
                                }}>
                                <RemoveRedEyeIcon sx={{color: '#2D9DE5'}}/>
                            </IconButton>

                            <IconButton onClick={() => {
                                setSelectedProcedure(val)
                                openDltAlert()
                            }}>
                                <DeleteIcon sx={{color: '#1C5D7C'}}/>
                            </IconButton>
                        </div>
                    )
                })
            })
            setTableData(array)
        }
    }, [getProcedureData.data])

    useEffect(() => {
        dispatch(getAllBranches());
    }, []);

    useEffect(() => {
        if (getBranchData.isSuccess) {
            const data = getBranchData.data;
            if (Array.isArray(data)) {
                setBranch(data.map((branch) => ({value: branch, label: branch.name})));
                const defaultBranch = data.find(branch => branch.branchId === 1);
                if (defaultBranch) {
                    setSelectedBranch({value: defaultBranch, label: defaultBranch.name});
                    dispatch(getAllProcedures(defaultBranch.branchId));
                    setSelectedBranch(defaultBranch)
                }
            }
        }
    }, [getBranchData.data]);

    const openDltAlert = () => {
        setIsAlertOpen(true);
    };

    const handleOpenUpdate = (val) => {
        setSelectedProcedure(val)
        setOpenUpdate(true)
    }
    const handleOpenAdd = () => {
        setOpenAdd(true)
    }

    const closePopup = () => {
        setOpenAdd(false)
        setOpenUpdate(false)
        setIsAlertOpen(false)
    }

    const tableColumns = [
        {field: "procedures", headerName: "Procedures", accessor: "procedures", textAlign: 'left'},
        {field: "type", headerName: "Type", accessor: "type", textAlign: 'left'},
        {field: "totalCharge", headerName: "Total Charge", accessor: "totalCharge", textAlign: 'left'},
        {field: "action", headerName: "Action", accessor: "action", textAlign: 'left'},
    ];

    const searchKey = ['procedures', 'type'];

    return (
        <WideCard>
            <Typography sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '18px'}}>Manage Procedures</Typography>
            <Box>
                <Divider sx={{borderBottom: '1.3px solid black'}}/>
            </Box>

            <Grid container sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Grid item xs={12} sm={5} md={4.3} lg={3.2} sx={{marginBottom: {xs: 1, sm: 1,}}}>
                    <DropDown
                        label={'Clinic'}
                        options={branch}
                        value={selectedBranch}
                        onChange={(value) => {
                            setSelectedBranch(value);
                            dispatch(getAllProcedures(value.branchId));
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={3} lg={auto}>
                    <CommonButton
                        fullWidth
                        onClick={handleOpenAdd}
                        name={'+ Add New Procedure'}
                        variant={'Fill'}
                    />
                </Grid>
            </Grid>

            <MDBox sx={{marginY: 2,}}>
                <MDTable tableData={tableData} tableColumns={tableColumns} searchKey={searchKey} isSearch={true}/>
            </MDBox>

            <ManageProceduresAddModal
                onClose={closePopup}
                open={openAdd}
            />

            {openUpdate &&
                <ManageProceduresUpdateModal
                    procedureData={selectedProcedure}
                    open={openUpdate}
                    onClose={closePopup}
                />
            }

            <ConfirmAlert
                src={DoctorBag}
                name={selectedProcedure.procedureName}
                open={isAlertOpen}
                onClose={closePopup}
                title="Are you sure"
                subtitle={'you want to procedures'}
                buttonText={"Delete"}
                onclick={() => {
                    handleDelete(selectedProcedure.procedureId)
                }}
            />

        </WideCard>
    );
}

export default ManageProcedures;