import { createSlice } from "@reduxjs/toolkit";
import {updateMainGrn} from "services/MainGrn/MainGrn";

const initialState = {
    mainGrn: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const updateMainGrnSlice = createSlice({
    name: "updateMainGrn",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateMainGrn.pending, (state) => {
                state.mainGrn.isLoading = true;
            })
            .addCase(updateMainGrn.fulfilled, (state, { payload }) => {
                state.mainGrn.isLoading = false;
                state.mainGrn.isSuccess = true;
                state.mainGrn.data = payload;
            })
            .addCase(updateMainGrn.rejected, (state, { payload }) => {
                state.mainGrn.isLoading = false;
                state.mainGrn.isSuccess = false;
                state.mainGrn.errorMessage = payload;
            });
    },
});

export default updateMainGrnSlice.reducer;
