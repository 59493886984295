import React, {useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import {CardContent} from "@mui/material";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import FilterPopup from "components/FilterPopup";
import CommonButton from "common/components/Button";
import ConfirmAlert from "components/ConfirmAlert";
import DoctorAddModal from "components/DoctorAddModal";
import {useDispatch, useSelector} from "react-redux";
import {deleteDoctor, getDoctors, updateDoctor} from "services/doctor/doctor";
import {dentistImg, doctor} from "config/images";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

function DoctorList() {
    const dispatch = useDispatch();
    const doctorr = useSelector(state => state.DoctorSlice.doctor);
    const doctorDlt = useSelector(state => state.DeleteDoctorSlice.doctor);
    const updateDoctorData = useSelector(state => state.UpdateDoctorSlice.doctor)
    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const [isDocAddOpen, setIsDocAddOpen] = useState(false);
    const [isDocUpdateOpen, setIsDocUpdateOpen] = useState(false);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState();
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [docId, setDocId] = useState('');

    useEffect(() => {
        dispatch(getDoctors());
    }, []);

    const openDltAlert = () => {
        setAlertOpen(true);
    };

    const openDocAdd = () => {
        setIsDocAddOpen(true)
    }

    const openDocUpdate = (data) => {
        setIsDocUpdateOpen(true)
    }

    const closeAlert = () => {
        setIsAlertOpen(false);
    };

    const openActiveAlert = (val) => {
        setDocId(val);
        setIsAlertOpen(true);
    };

    const closeForm = () => {
        setIsAddFormOpen(false);
        setIsDocAddOpen(false);
        setIsDocUpdateOpen(false);
        setAlertOpen(false)
    };

    const getStatusInfo = (status) => {
        if (status === 1) {
            return {text: 'Active', variant: 'Fill'};
        } else {
            return {text: 'Inactive', variant: 'outline'};
        }
    };

    const handleDelete = (id) => {
        setDeleteLoader(true)
        dispatch(deleteDoctor(id));
    };

    useEffect(() => {
        if (deleteLoader) {
            setDeleteLoader(false)
            if (doctorDlt.isSuccess) {
                dispatch(getDoctors())
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Doctor Deleted Success',
                })
                setAlertOpen(false);
            } else if (!doctorDlt.isSuccess && !doctorDlt.isLoading) {
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Doctor Deleted Warning'
                })
            }
        }
    }, [doctorDlt.data]);

    useEffect(() => {
        if (doctorr.isSuccess) {
            const data = doctorr?.data;
            if (Array.isArray(data)) {
                const array = data.map((val) => {
                    const {text, variant} = getStatusInfo(val.status);

                    return {
                        id: val.doctor_id,
                        dName: (
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Avatar
                                    sx={{
                                        width: 30,
                                        height: 30,
                                        margin: '0px',
                                        padding: '0px',
                                        backgroundColor: '#EAF5FC',
                                        marginRight: '5px',
                                        marginTop: '8px',
                                    }}
                                    src={doctor}
                                />

                                <div style={{marginTop: '10px'}}>{val.title + val.name}</div>
                            </div>
                        ),
                        doctorName: val.name,
                        specialist: val.specialty,
                        experience: val.experience,
                        location: val.address,
                        status: (
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <CommonButton
                                    sx={{width: '100px'}}
                                    name={text}
                                    circular={true}
                                    variant={variant}
                                    onClick={() => {
                                        openActiveAlert(val);
                                    }}
                                />
                            </div>
                        ),
                        action: (
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

                                <IconButton
                                    onClick={() => {
                                        setSelectedDoctor(val);
                                        openDocUpdate();
                                    }}>
                                    <RemoveRedEyeIcon sx={{color: '#2D9DE5'}}/>
                                </IconButton>

                                <IconButton onClick={() => {
                                    setSelectedDoctor(val);
                                    openDltAlert();
                                }}>
                                    <DeleteIcon sx={{color: '#1C5D7C'}}/>
                                </IconButton>

                            </div>
                        ),
                    };
                });
                setTableData(array);
            }
        }
    }, [doctorr.isSuccess, doctorr.data]);

    const tableColumns = [
        {field: "dName", headerName: "Doctor Name", accessor: "dName", textAlign: 'left'},
        {field: "specialist", headerName: "Specialist", accessor: "specialist", textAlign: 'left'},
        {field: "experience", headerName: "Experience", accessor: "experience", textAlign: 'left'},
        {field: "location", headerName: "Location", accessor: "location", textAlign: 'left'},
        {field: "status", headerName: "Status", accessor: "status", textAlign: 'left'},
        {field: "action", headerName: "Action", accessor: "action", textAlign: 'left'},
    ];

    const searchKey = ['dName', 'specialist'];

    useEffect(() => {
        if (updateLoader) {
            setUpdateLoader(false);
            if (updateDoctorData.isSuccess && !updateDoctorData.isLoading) {
                dispatch(getDoctors());
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Status Updated Success',
                })
                setIsAlertOpen(false);

            } else if (!updateDoctorData.isSuccess && !updateDoctorData.isLoading) {
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Status Updated Warning'
                })
            }
        }
    }, [updateDoctorData.data]);

    return (
        <Card sx={{width: '100%'}}>
            <CardContent>
                <Typography sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '18px'}}>Doctor List</Typography>
                <Box>
                    <Divider sx={{borderBottom: '1.3px solid black'}}/>
                </Box>

                <Box sx={{display: 'flex', justifyContent: 'end'}}>
                    <CommonButton
                        onClick={openDocAdd}
                        name={'+  Add New Doctor'}
                        variant={'Fill'}
                    />
                </Box>

                <FilterPopup
                    open={isAddFormOpen}
                    onClose={closeForm}
                />

                <MDBox sx={{marginY: 2,}}>
                    <MDTable tableData={tableData} tableColumns={tableColumns} searchKey={searchKey} isSearch={true}/>
                </MDBox>

                <ConfirmAlert
                    src={dentistImg}
                    name={docId?.title + docId?.name}
                    open={isAlertOpen}
                    onClose={closeAlert}
                    title="Are you sure"
                    subtitle={docId.status === 1 ? "you want to inactive doctor ?" : "you want to active doctor ?"}
                    buttonText={docId.status === 1 ? "Inactive" : "Active"}
                    onclick={() => {
                        const docUpData = {
                            status: docId.status === 1 ? '0' : '1'
                        };
                        setUpdateLoader(true);
                        dispatch(updateDoctor({id: docId.doctorId, docUpData}));
                    }}
                />

                {isDocAddOpen &&
                    <DoctorAddModal
                        open={isDocAddOpen}
                        onClose={closeForm}
                    />
                }

                {isDocUpdateOpen &&
                    <DoctorAddModal
                        open={isDocUpdateOpen}
                        onClose={closeForm}
                        update={true}
                        updateData={selectedDoctor}
                    />
                }
                <ConfirmAlert onClose={closeForm} open={alertOpen} buttonText={"Delete"} title={'Are you sure'}
                              name={selectedDoctor?.title + selectedDoctor?.name}
                              subtitle={'you want to delete doctor ?'} src={dentistImg} onclick={() => {
                    handleDelete(selectedDoctor.doctorId)
                }}/>
            </CardContent>
        </Card>
    );
}

export default DoctorList;
