import { createSlice } from "@reduxjs/toolkit";
import { getCategory } from "services/category/category";

const initialState = {
  category: {
    data: [],
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
  },
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategory.pending, (state) => {
        state.category.isLoading = true;
      })
      .addCase(getCategory.fulfilled, (state, { payload }) => {
        state.category.isLoading = false;
        state.category.isSuccess = true;
        state.category.data = payload;
      })
      .addCase(getCategory.rejected, (state, { payload }) => {
        state.category.isLoading = false;
        state.category.isSuccess = false;
        state.category.errorMessage = payload;
      });
  },
});

export default categorySlice.reducer;
