import ModalComponent from "common/components/Modal";
import Typography from "@mui/material/Typography";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CommonButton from "common/components/Button";
import MDTable from "common/components/MDTable";
import React, {useEffect, useState} from "react";
import Avatar from "@mui/material/Avatar";
import {inventoryDashboardImage} from "config/images";

function GrnIssueViewModal({open, onClose, grnData}) {

    const [tableData, setTableData] = useState([])

    const tableColumns = [
        {field: "item", headerName: "Item", accessor: "item", textAlign: "left"},
        {field: "qty", headerName: "Qty", accessor: "qty", textAlign: "left"},
    ];
    const searchKey = ["item", "qty"];

    useEffect(() => {
        const arr = grnData.grnDetails.map((item) => (
            {
                item: (
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <Avatar
                            sx={{
                                width: 30,
                                height: 30,
                                margin: "0px",
                                padding: "0px",
                                backgroundColor: "#EAF5FC",
                                marginRight: "5px",
                                marginTop: "8px",
                            }}
                            src={inventoryDashboardImage}
                        />
                        <div style={{marginTop: "10px"}}>{item.itemId?.itemName}</div>
                    </div>
                ),
                qty: item.qty,
            }))
        setTableData(arr)
    }, []);

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="md">

            <MDBox sx={{margin: "10px"}}>
                <Box>
                    <div>
                        <Typography
                            sx={{
                                color: "#1C5E7C",
                                fontWeight: 450,
                                fontSize: "16px",
                                marginBottom: "25px",
                                position: "absolute",
                                top: "15px",
                                left: "15px",
                            }}
                        >
                            {" "}
                            {"GRN Issue View"}{" "}
                        </Typography>
                    </div>
                </Box>

                <div style={{overflow: "hidden"}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Box sx={{width: "500px", margin: "0 auto"}}></Box>
                        </Grid>
                    </Grid>
                </div>

                <MDBox sx={{marginY: 2}}>
                    <MDTable
                        tableData={tableData}
                        tableColumns={tableColumns}
                        searchKey={searchKey}
                        isSearch={false}
                    />
                </MDBox>

                <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                    <div style={{margin: "5px"}}>
                        <CommonButton
                            name={"Cancel"}
                            variant={"outline"}
                            onClick={onClose}
                        />
                    </div>
                </Box>
            </MDBox>
        </ModalComponent>
    );
}

GrnIssueViewModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    grnData : PropTypes
};

export default GrnIssueViewModal;
