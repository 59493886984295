import { createSlice } from "@reduxjs/toolkit";
import {postProcedure} from "services/procedure/procedure";

const initialState = {
    procedure: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const saveProcedureSlice = createSlice({
    name: "saveProcedures",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(postProcedure.pending, (state) => {
                state.procedure.isLoading = true;
            })
            .addCase(postProcedure.fulfilled, (state, { payload }) => {
                state.procedure.isLoading = false;
                state.procedure.isSuccess = true;
                state.procedure.data = payload;
            })
            .addCase(postProcedure.rejected, (state, { payload }) => {
                state.procedure.isLoading = false;
                state.procedure.isSuccess = false;
                state.procedure.errorMessage = payload;
            });
    },
});

export default saveProcedureSlice.reducer;
