import React, { useEffect, useState } from "react";
import MDBox from "common/components/MDBox";
import ModalComponent from "common/components/Modal";
import Grid from "@mui/material/Grid";
import PersonIcon from "@mui/icons-material/Person";
import TextInput from "common/components/TextInput/TextInput";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DropDown from "common/components/DropDown/DropDown";
import MailIcon from "@mui/icons-material/Mail";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TextsmsIcon from "@mui/icons-material/Textsms";
import Typography from "@mui/material/Typography";
import CommonButton from "common/components/Button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getAllPatients, savePatient } from "services/patient/patient";
import { updatePatient } from "services/patient/patient";
import {
  sendSuccessNotification,
  sendWarningNotification,
} from "common/components/Notification/SendNotification";
import {
  generateDate,
  getImageUrl,
  onImageEdit,
} from "common/utils/CommonFunctions";
import dayjs from "dayjs";
import MobileNumber from "common/components/MobileNumber";

function PatientAddModal({
  open,
  onClose,
  update = false,
  patientData,
  onClosePopup,
}) {
  const dispatch = useDispatch();
  const [saveLoader, setSaveLoader] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [title, setTitle] = useState(update ? patientData.title : "");
  const [birthday, setBirthday] = useState(
    update ? dayjs(patientData.birthDay, { format: "YYYY-MM-DD" }) : null
  );
  const [firstName, setFirstName] = useState(
    update ? patientData.firstName : ""
  );
  const [lastName, setLastName] = useState(update ? patientData.lastName : "");
  const [gender, setGender] = useState(update ? patientData.gender : "");
  const [occupation, setOccupation] = useState(
    update ? patientData.occupation : ""
  );
  const [mobileNo, setMobileNo] = useState(
    update ? patientData.mobileNumber : ""
  );
  const [telNo, setTelNo] = useState(update ? patientData.telNumber : "");
  const [email, setEmail] = useState(update ? patientData.email : "");
  const [nic, setNic] = useState(update ? patientData.nic : "");
  const [description, setDescription] = useState(
    update ? patientData.description : ""
  );
  const [address, setAddress] = useState(update ? patientData.address : "");
  const [country, setCountry] = useState(update ? patientData.country : "");
  const [comment, setComment] = useState(update ? patientData.comment : "");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [mobileNoError, setMobileNoError] = useState();
  const [telNoError, setTelNoError] = useState();
  const [emailError, setEmailError] = useState();

  const isDataComplete =
    title &&
    firstName &&
    lastName &&
    gender &&
    occupation &&
    mobileNo &&
    telNo &&
    email &&
    nic &&
    description &&
    address &&
    country &&
    comment &&
    !firstNameError &&
    !lastNameError &&
    !mobileNoError &&
    !telNoError &&
    !emailError;

  const savedPatientData = useSelector(
    (state) => state.SavePatientSlice.patient
  );
  const updatePatientData = useSelector(
    (state) => state.UpdatePatientSlice.patient
  );

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  const tittleOptions = [
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Miss.", label: "Miss." },
    { value: "Ven.", label: "Ven." },
    { value: "Doctor.", label: "Doctor." },
    { value: "Baby.", label: "Baby." },
  ];

  const clearFields = () => {
    setTitle("");
    setBirthday(null);
    setFirstName("");
    setLastName("");
    setGender("");
    setOccupation("");
    setMobileNo("");
    setTelNo("");
    setEmail("");
    setNic("");
    setDescription("");
    setAddress("");
    setCountry("");
    setComment("");
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (update) {
      // onImageEdit(
      //     getImageUrl(patientData.image)
      // ).then((file) => setImage(file));
    }
  }, [update]);

  const handleAction = () => {
    const formdata = new FormData();
    formdata.append("title", title);
    formdata.append("birthDay", generateDate(birthday));
    formdata.append("firstName", firstName);
    formdata.append("lastName", lastName);
    formdata.append("gender", gender);
    formdata.append("occupation", occupation);
    formdata.append("mobileNumber", mobileNo);
    formdata.append("telNumber", telNo);
    formdata.append("email", email);
    formdata.append("nic", nic);
    formdata.append("description", description);
    formdata.append("address", address);
    formdata.append("country", country);
    formdata.append("comment", comment);

    if (!update) {
      setSaveLoader(true);
      dispatch(savePatient(formdata));
    } else {
      setUpdateLoader(true);
      dispatch(
        updatePatient({ id: patientData.id, updatedPatientData: formdata })
      );
    }
  };

  useEffect(() => {
    if (saveLoader) {
      setSaveLoader(false);
      if (savedPatientData.isSuccess && !savedPatientData.isLoading) {
        dispatch(getAllPatients());
        clearFields();
        handleClose();
        sendSuccessNotification({
          title: "Success",
          message: "Patient Saved Success",
        });
      } else if (savedPatientData.errorMessage === "Mobile number is exist") {
        sendWarningNotification({
          title: "Warning",
          message: "Mobile number is already saved",
        });
      } else if (!savedPatientData.isSuccess && !savedPatientData.isLoading) {
        sendWarningNotification({
          title: "Error",
          message: "Save Error",
        });
      }
    }
  }, [savedPatientData.data, savedPatientData.errorMessage]);

  useEffect(() => {
    if (updateLoader) {
      setUpdateLoader(false);
      if (updatePatientData.isSuccess && !updatePatientData.isLoading) {
        dispatch(getAllPatients());
        clearFields();
        handleClose();
        onClosePopup();
        sendSuccessNotification({
          title: "Success",
          message: "Patient Update Success",
        });
      } else if (!updatePatientData.isSuccess && !updatePatientData.isLoading) {
        sendWarningNotification({
          title: "Error",
          message: "Update Error",
        });
      }
    }
  }, [updatePatientData.data]);

  return (
    <ModalComponent showModal={open} setShowModal={onClose} maxWidth="lg">
      <MDBox
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        sx={{ margin: "15px" }}
      >
        <Typography
          sx={{
            color: "#1C5E7C",
            fontWeight: 450,
            fontSize: "19px",
            marginBottom: "25px",
            position: "absolute",
            top: "15px",
            left: "15px",
          }}
        >
          {" "}
          {update ? "Update Patients " : "Add Patients "}
        </Typography>

        <Grid container spacing={2} sx={{ marginTop: "10px" }}>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <DropDown
              label={"Tittle"}
              options={tittleOptions}
              value={title}
              onChange={(value) => {
                setTitle(value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3.5}>
            <TextInput
              placeholder={"First Name"}
              startIcon={<PersonIcon />}
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                if (!/^[a-zA-Z]+( [a-zA-Z]+)*$/.test(e.target.value)) {
                  setFirstNameError("Invalid Name");
                } else {
                  setFirstNameError("");
                }
              }}
              error={firstNameError !== ""}
            />
            {firstNameError && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {firstNameError}
              </div>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3.5}>
            <TextInput
              placeholder={"Last Name"}
              startIcon={<PersonIcon />}
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                if (!/^[a-zA-Z]+( [a-zA-Z]+)*$/.test(e.target.value)) {
                  setLastNameError("Invalid Name");
                } else {
                  setLastNameError("");
                }
              }}
              error={lastNameError !== ""}
            />
            {lastNameError && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {lastNameError}
              </div>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Birthday (Optional)"
                value={birthday}
                onChange={(date) => setBirthday(date)}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <DropDown
              label={"Gender"}
              options={genderOptions}
              value={gender}
              onChange={(value) => {
                setGender(value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextInput
              placeholder={"Occupation"}
              startIcon={<PersonIcon />}
              value={occupation}
              onChange={(e) => {
                setOccupation(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <MobileNumber
              value={mobileNo}
              onChange={(value) => {
                setMobileNo(value);
                const MobilefirstTwoNumbers = value.substring(0, 2);
                if(MobilefirstTwoNumbers === "94"){
                  if (!/^(\94)\d{9}$/.test(value)) {
                    setMobileNoError("Invalid Contact");
                  } else {
                    setMobileNoError("");
                  }
                }
              }}
              defaultCountry="lk"
              placeholder={"Enter Mobile No"}
              validationMessage={"Please enter a valid mobile number."}
              placeholder={"Enter Mobile No"}
            />
            {mobileNoError && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {mobileNoError}
              </div>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <MobileNumber
              value={telNo}
              onChange={(value) => {
                setTelNo(value);
                const TelefirstTwoNumbers = value.substring(0, 2);
                if(TelefirstTwoNumbers === "94"){
                  if (!/^(\94)\d{9}$/.test(value)) {
                    setTelNoError("Invalid Contact");
                  } else {
                    setTelNoError("");
                  }
                }
              }}
              defaultCountry="lk"
              placeholder={"Enter TelePhone No"}
              validationMessage={"Please enter a valid mobile number."}
              placeholder={"Enter TelePhone No"}
            />
            {telNoError && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {telNoError}
              </div>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextInput
              placeholder={"Email"}
              startIcon={<MailIcon />}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value)) {
                  setEmailError("Invalid Email");
                } else {
                  setEmailError("");
                }
              }}
              error={emailError !== ""}
            />
            {emailError && (
              <div style={{ color: "red", fontSize: "12px" }}>{emailError}</div>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextInput
              placeholder={"NIC / Passport"}
              startIcon={<RecentActorsIcon />}
              value={nic}
              onChange={(e) => {
                setNic(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextInput
              placeholder={"How did you hear about us?"}
              startIcon={<PersonIcon />}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextInput
              placeholder={"Address"}
              startIcon={<LocationOnIcon />}
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextInput
              placeholder={"Comments"}
              startIcon={<TextsmsIcon />}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextInput
              placeholder={"Country"}
              startIcon={<LocationOnIcon />}
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            />
          </Grid>
        </Grid>

        <MDBox
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "15px",
          }}
        >
          <CommonButton
            name={update ? "Update Patient" : "Add Patient"}
            variant={"Fill"}
            onClick={handleAction}
            disabled={!isDataComplete}
          />
        </MDBox>
      </MDBox>
    </ModalComponent>
  );
}

PatientAddModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PatientAddModal;
