import WideCard from "common/components/WideCard";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CommonButton from "common/components/Button";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ManageGRNAddModal from "components/ManageGRNAddModal";
import {useDispatch, useSelector} from "react-redux";
import {getAllMainGrn} from "services/MainGrn/MainGrn";
import dayjs from "dayjs";

function ManageGRN() {
    const dispatch = useDispatch();
    const getAllGrnData = useSelector(state => state.MainGrnSlice.mainGrn);
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [isViewModalOpen, setViewModalOpen] = useState(false);
    const [tableData, setTableData] = useState([])
    const [selectedGrn, setSelectedGrn] = useState({})

    useEffect(() => {
        dispatch(getAllMainGrn())
    }, []);

    const tableColumns = [
        {field: "invNo", headerName: "Invoice Number", accessor: "invNo", textAlign: 'left'},
        {field: "supplier", headerName: "Supplier", accessor: "supplier", textAlign: 'left'},
        {field: "date", headerName: "GRN Date", accessor: "date", textAlign: 'left'},
        {field: "status", headerName: "Status", accessor: "status", textAlign: 'left'},
        {field: "action", headerName: "Action", accessor: "action", textAlign: 'left'},
    ];
    const searchKey = ['invNo', 'supplier'];

    const getStatusInfo = (status) => {
        if (status === "pending") {
            return {text: 'pending', variant: 'Fill', color: '#2D9DE5'};
        } else {
            return {text: 'submit', variant: 'Fill', color: '#1C5D7C'};
        }
    };

    useEffect(() => {
        if (getAllGrnData.isSuccess) {
            const data = getAllGrnData?.data;
            if (Array.isArray(data)) {
                const array = data.map((val) => {
                    const {text, variant, color} = getStatusInfo(val.statusGrn);
                    return {
                        invNo: val.invoiceNo,
                        supplier: val.supplier?.name,
                        date: val.grnDate,
                        status: (
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <CommonButton
                                    sx={{width: '100px'}}
                                    name={text}
                                    circular={true}
                                    variant={variant}
                                    color={color}
                                />
                            </div>
                        ),

                        action: (
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <IconButton
                                    onClick={() => {
                                        const data = {
                                            mainGrnId: val.mainGrnId,
                                            invoiceNo: val.invoiceNo,
                                            exDate: dayjs(val.exDate) ,
                                            supplierId: val.supplier?.supplierId,
                                            status : val.statusGrn,
                                        }
                                        setViewModalOpen(true);
                                         setSelectedGrn(data)
                                    }}>
                                    <RemoveRedEyeIcon sx={{color: '#2D9DE5'}}/>
                                </IconButton>
                            </div>
                        ),
                    };
                });
                setTableData(array);
            }
        }
    }, [getAllGrnData.isSuccess, getAllGrnData.data]);

    return (
        <WideCard>
            <Typography sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '18px'}}>Manage GRN</Typography>
            <Box>
                <Divider sx={{borderBottom: '1.3px solid black'}}/>
            </Box>

            <Box sx={{display: 'flex', justifyContent: 'end'}}>
                <CommonButton
                    onClick={() => {
                        setAddModalOpen(true)
                    }}
                    name={'+  Add GRN'}
                    variant={'Fill'}
                />
            </Box>

            <MDBox sx={{marginY: 2,}}>
                <MDTable tableData={tableData} tableColumns={tableColumns} searchKey={searchKey} isSearch={true}/>
            </MDBox>

            {isAddModalOpen &&
                <ManageGRNAddModal
                    open={isAddModalOpen}
                    onClose={() => {
                        setAddModalOpen(false)
                    }}
                />
            }

            {isViewModalOpen &&
                <ManageGRNAddModal
                    open={isViewModalOpen}
                    onClose={() => {
                        setViewModalOpen(false)
                    }}
                    isView={true}
                    grnDetails={selectedGrn}
                />
            }
        </WideCard>
    )
}

export default ManageGRN;
