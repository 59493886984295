import React from 'react';
import { Card, CardContent,  Box } from '@mui/material';
import PropTypes from "prop-types";

const NavbarCard = ({ isOpen,children }) => {
    return (
        <div >
            {isOpen && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50px',
                        right: '10px',
                        width: '350px',

                        '@media (max-width: 600px)': {
                            top: '50px',
                            right: '10px',
                            maxWidth: '300px',
                        },
                        '@media (max-width: 440px)': {
                            top: '50px',
                            right: '10px',
                            maxWidth: '220px',
                        },
                    }}
                >
                    <Card>
                        <CardContent>
                            {children}
                        </CardContent>
                    </Card>
                </Box>
            )}
        </div>
    );
};

NavbarCard.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.node,
};

export default NavbarCard;
