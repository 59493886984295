import { createSlice } from "@reduxjs/toolkit";
import {getPatientByMobileNo} from "services/patient/patient";

const initialState = {
    patient: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const patientByMobileNoSlice = createSlice({
    name: "patientByMobileNo",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPatientByMobileNo.pending, (state) => {
                state.patient.isLoading = true;
            })
            .addCase(getPatientByMobileNo.fulfilled, (state, { payload }) => {
                state.patient.isLoading = false;
                state.patient.isSuccess = true;
                state.patient.data = payload;
            })
            .addCase(getPatientByMobileNo.rejected, (state, { payload }) => {
                state.patient.isLoading = false;
                state.patient.isSuccess = false;
                state.patient.errorMessage = payload;
            });
    },
});

export default patientByMobileNoSlice.reducer;
