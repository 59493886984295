
import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../AxiosOrders";
export const getUsers = createAsyncThunk('api', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/user/get_all_user`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const saveUsers = createAsyncThunk('saveUsers', async (docData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/user/register`, docData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});
