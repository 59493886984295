import { createSlice } from "@reduxjs/toolkit";
import {saveTreatment} from "services/treatment/treatment";

const initialState = {
    treatment: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const saveTreatmentSlice = createSlice({
    name: "saveTreatment",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(saveTreatment.pending, (state) => {
                state.treatment.isLoading = true;
            })
            .addCase(saveTreatment.fulfilled, (state, { payload }) => {
                state.treatment.isLoading = false;
                state.treatment.isSuccess = true;
                state.treatment.data = payload;
            })
            .addCase(saveTreatment.rejected, (state, { payload }) => {
                state.treatment.isLoading = false;
                state.treatment.isSuccess = false;
                state.treatment.errorMessage = payload;
            });
    },
});

export default saveTreatmentSlice.reducer;
