import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {CardContent} from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {generateDate} from "common/utils/CommonFunctions";
import MDTable from "common/components/MDTable";
import MDBox from "common/components/MDBox";
import DropDown from "common/components/DropDown/DropDown";
import {useDispatch, useSelector} from "react-redux";
import {getProcedureWiseSales} from 'services/report/report';
import {getAllBranches} from "services/branch/branch";
import {getDoctorsByBranch} from "services/doctor/doctor";



const tableColumns = [
    {field: "id", headerName: "#", accessor: "id", textAlign: 'left'},
    {field: "procedureName", headerName: "Procedure Name", accessor: "procedureName", textAlign: 'left'},
    {field: "location", headerName: "Location", accessor: "location", textAlign: 'left'},
    {field: "doctorName", headerName: "Doctor Name", accessor: "doctorName", textAlign: 'left'},
    {field: "date", headerName: "Date", accessor: "date", textAlign: 'left'},
    {field: "bookingNo", headerName: "Booking Number", accessor: "bookingNo", textAlign: 'left'},
    {field: "patientName", headerName: "Patient Name", accessor: "patientName", textAlign: 'left'},
    {field: "fees", headerName: "Fees", accessor: "fees", textAlign: 'left'},
    {field: "extra", headerName: "Extra", accessor: "extra", textAlign: 'left'},
    {field: "lab", headerName: "Lab", accessor: "lab", textAlign: 'left'},
    {field: "total", headerName: "Total", accessor: "total", textAlign: 'left'}
];

export default function ProcedureWiseSalesReport() {

    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([])
    const [branch, setBranch] = useState([]);
    const [doctor, setDoctor] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [selectedDoctor, setSelectedDoctor] = useState({});
    const [timesOfDay, setTimesOfDay] = useState('');
    const [searchDetails, setSearchDetails] = useState({ startDate: '', endDate: '' });
    const getBranchData = useSelector((state) => state.BranchSlice.branch);
    const getDoctData = useSelector(state => state.DoctorsByBranchSlice.doctor);
    const procedureWiseSalesData = useSelector(state => state.ProcedureWiseSalesSlice.procedureWiseSales);

    const timesOfDayData = [
        {value: "morning", label: "Morning"},
        {value: "evening", label: "Evening"},
    ]

    const handleBranchChange = (value) => {
        setSelectedBranch(value);
        setSearchDetails((prev) => ({
            ...prev,
            branchId: value
        }));
        dispatch(getDoctorsByBranch(value));    
    };

    const handleDoctorChange = (value) => {
        setSelectedDoctor(value);
        setSearchDetails((prev) => ({
            ...prev,
            doctorId: value
        }));
    }
     
    const handleShiftChange = (value) => {
        setTimesOfDay(value);
        setSearchDetails((prev) => ({
            ...prev,
            shift: value
        }));
    } 

    useEffect(() => {
        dispatch(getAllBranches());
    }, [dispatch]);

    useEffect(() => {
        if (getBranchData.isSuccess) {
            const data = getBranchData.data;
            if (Array.isArray(data)) {
                setBranch(
                    data.map((branch) => ({ value: branch.branchId, label: branch.name}))
                );
            }
        }
    }, [getBranchData.isSuccess, getBranchData.data]);
    
    useEffect(() => {
        if (getDoctData.isSuccess) {
            const data = getDoctData.data;
            setDoctor(
                data.map((doctor) => ({ value: doctor.doctorId, label: doctor.name}))
            );
        }
    }, [getDoctData.isSuccess, getDoctData.data]);
    

    useEffect(() => {
        if (searchDetails.startDate && searchDetails.endDate && searchDetails.branchId && searchDetails.doctorId && searchDetails.shift ) {
            dispatch(getProcedureWiseSales(searchDetails))
        }
    }, [searchDetails, dispatch]);
    
    useEffect(()=> {
        if(procedureWiseSalesData.isSuccess && !procedureWiseSalesData.isLoading){
            const array = [];
            procedureWiseSalesData.data?.forEach((val, index)=> {
                array.push({
                    id: ( 1 + index ),
                    procedureName: val.procedureName,
                    location: val.location,
                    doctorName: val.doctorName,
                    date: val.date,
                    bookingNo: val.bookingNo,
                    patientName: val.patientName,
                    fees: val.fees,
                    extra: val.extraCost,
                    lab:val.labCost,
                    total: val.fees+val.extraCost+val.labCost 
                })
            })
            setTableData(array)
        }
    },[procedureWiseSalesData])

    return(
        <Card sx={{width: '100%'}}>
            <CardContent>
                <Typography sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '18px'}}>Procedure Wise Sales Report</Typography>

                <Box>
                    <Divider sx={{borderBottom: '1.3px solid black'}}/>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'start', gap: 2 }}> 
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{ width: '100%', fontSize: '14px', '.MuiInputBase-input': { padding: '8px' } }}
                            label="From"
                            value={searchDetails.startDate || ""}
                            onChange={(date) => {
                                setSearchDetails((prev) => ({
                                    ...prev,
                                    startDate: generateDate(date),
                                }));
                            }}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        sx={{ width: '100%', fontSize: '14px', '.MuiInputBase-input': { padding: '8px' } }}
                        label="To"
                        value={searchDetails.endDate || ""}
                        onChange={(date) => {
                            setSearchDetails((prev) => ({
                            ...prev,
                            endDate: generateDate(date),
                            }));
                        }}
                        />
                    </LocalizationProvider>

                    <DropDown
                        label={"Clinic"}
                        options={branch}
                        value={selectedBranch || ''}
                        onChange={handleBranchChange}
                        />

                    <DropDown
                        label={"Doctor"}
                        options={doctor}
                        value={selectedDoctor || ''}
                        onChange={handleDoctorChange}
                        disabled={!selectedBranch}
                    />

                    <DropDown
                        label={"Shift"}
                        options={timesOfDayData}
                        value= {timesOfDay || ''}
                        onChange={handleShiftChange}
                    />

                </Box>

                <MDBox sx={{marginY: 2,}}>
                    <MDTable
                        tableData={tableData}
                        tableColumns={tableColumns}    
                        isSearch={false}
                    />
                </MDBox>
            </CardContent>
        </Card>
    )
}
