import React, {useEffect, useState} from "react";
import MDBox from "common/components/MDBox";
import ModalComponent from "common/components/Modal";
import Grid from "@mui/material/Grid";
import PersonIcon from "@mui/icons-material/Person";
import TextInput from "common/components/TextInput/TextInput";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Typography from "@mui/material/Typography";
import CommonButton from "common/components/Button";
import PropTypes from "prop-types";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {getDoctors, postDoctor, updateDoctor} from "services/doctor/doctor";
import {
    sendSuccessNotification,
    sendWarningNotification,
} from "common/components/Notification/SendNotification";
import DropDown from "common/components/DropDown/DropDown";
import IconButton from "@mui/material/IconButton";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CloseIcon from "@mui/icons-material/Close";
import {getAllBranches} from "services/branch/branch";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

function DoctorAddModal({open, onClose, update = false, updateData}) {
    const getBranchData = useSelector((state) => state.BranchSlice.branch);
    const [editMode, setEditMode] = useState(!update);
    const dispatch = useDispatch();
    const doctorSave = useSelector((state) => state.SaveDoctorSlice.doctor);
    const doctorUpdate = useSelector((state) => state.UpdateDoctorSlice.doctor);
    const [title, setTitle] = useState(update ? updateData.title : "");
    const [name, setName] = useState(update ? updateData.name : "");
    const [specialist, setSpecialist] = useState(
        update ? updateData.specialty : ""
    );
    const [experience, setExperience] = useState(
        update ? updateData.experience : ""
    );
    const [contact, setContact] = useState(
        update ? updateData.mobileNumberOne : ""
    );
    const [contactTwo, setContactTwo] = useState(
        update ? updateData.mobileNumberTwo : ""
    );
    const [regNo, setRegNo] = useState(
        update ? updateData.registrationNumber : ""
    );
    const [designation, setDesignation] = useState(
        update ? updateData.designation : ""
    );
    const [joinedDate, setJoinedDate] = useState(
        update ? dayjs(updateData.joinedDate, {format: "YYYY-MM-DD"}) : null
    );
    const [address, setAddress] = useState(update ? updateData.address : "");
    const [currentHospital, setCurrentHospital] = useState(
        update ? updateData.currentHospital : ""
    );
    const [branch, setBranch] = useState([]);
    const [currentBranch, setCurrentBranch] = useState(
        update ? updateData.branches : []
    );
    const [saveLoader, setSaveLoader] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [nameError, setNameError] = useState("");
    const [specialistError, setSpecialistError] = useState("");
    const [experienceError, setExperienceError] = useState("");
    const [feeError, setFeeError] = useState("");
    const [percentageError, setPercentageError] = useState("");
    const [contactError, setContactError] = useState("");
    const [contactTwoError, setContactTwoError] = useState("");
    const [addressError, setAddressError] = useState("");
    const [currentHospitalError, setCurrentHospitalError] = useState("");
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [addedBranches, setAddedBranches] = useState([]);
    const [isBranchSelected, setIsBranchSelected] = useState(false);

    const handleBranchChange = (value) => {
         setSelectedBranch(value);
        setIsBranchSelected(!!value);
    };

    useEffect(() => {
        dispatch(getAllBranches());
    }, []);

    useEffect(() => {
        if (getBranchData.isSuccess) {
            const data = getBranchData.data;
            if (Array.isArray(data)) {
                setBranch(
                    data.map((branch) => ({value: branch, label: branch.name}))
                );
            }
        }
    }, [getBranchData.data]);

    const isDataComplete =
        title &&
        name &&
        specialist &&
        experience &&
        contact &&
        contactTwo &&
        designation &&
        joinedDate &&
        regNo &&
        address &&
        !nameError &&
        !specialistError &&
        !experienceError &&
        !feeError &&
        !contactError &&
        !addressError;

    const clear = () => {
        setTitle("");
        setName("");
        setSpecialist("");
        setExperience("");
        setContact("");
        setContactTwo("");
        setRegNo("");
        setDesignation("");
        setJoinedDate(null);
        setAddress("");
        setCurrentHospital("");
        setBranch([]);
        setCurrentBranch([]);
        setAddedBranches([]);
        setNameError("");
        setSpecialistError("");
        setExperienceError("");
        setFeeError("");
        setPercentageError("");
        setContactError("");
        setAddressError("");
        setCurrentHospitalError("");
    };

    const updatedDoctor = (id) => {
        const branches = addedBranches.map((value) => ({
            branchId: value.id,
            percentage: value.percentage,
        }));

        const branches2 = currentBranch.map((value) => ({
            branchId: value.branchId,
            percentage: value.percentage,
        }));

        const docUpData = {
            address: address,
            mobileNumberOne: contact,
            mobileNumberTwo: contactTwo,
            registrationNumber: regNo,
            joinedDate: joinedDate,
            designation: designation,
            experience: experience,
            branchWisePercentage: branches.concat(branches2),
            title: title,
            name: name,
            specialty: specialist,
            currentHospital: currentHospital,
        };
        setUpdateLoader(true);
        dispatch(updateDoctor({id: updateData.doctorId, docUpData}));
    };

    useEffect(() => {
        if (updateLoader) {
            setUpdateLoader(false);
            if (doctorUpdate.isSuccess && !doctorUpdate.isLoading) {
                dispatch(getDoctors());
                sendSuccessNotification({
                    title: "Success",
                    message: "Doctor Updated Success",
                });
                onClose();
                clear();
            } else if (!doctorUpdate.isSuccess && !doctorUpdate.isLoading) {
                sendWarningNotification({
                    title: "Warning",
                    message: "Doctor Updated Warning",
                });
            }
        }
    }, [doctorUpdate.data]);

    const saveDoctor = () => {
        const branchesPercent = addedBranches.map((value) => ({
            branchId: value.id,
            percentage: value.percentage,
        }));

        const docData = {
            address: address,
            mobileNumberOne: contact,
            mobileNumberTwo: contactTwo,
            registrationNumber: regNo,
            joinedDate: joinedDate,
            designation: designation,
            experience: experience,
            title: title,
            name: name,
            specialty: specialist,
            currentHospital: currentHospital,
            branchWisePercentage: branchesPercent,
        };

        setSaveLoader(true);
        dispatch(postDoctor(docData));
    };

    useEffect(() => {
        if (saveLoader) {
            if (doctorSave.isSuccess && !doctorSave.isLoading) {
                dispatch(getDoctors());
                sendSuccessNotification({
                    title: "Success",
                    message: "Doctor Saved Success",
                });
                onClose();
                clear();
                setSaveLoader(false);
            } else if (doctorSave.errorMessage === "Registration number is already saved") {
                sendWarningNotification({
                    title: "Warning",
                    message: "Registration number is already saved",
                });
                setSaveLoader(false);
            } else if (!doctorSave.isSuccess && !doctorSave.isLoading) {
                sendWarningNotification({
                    title: "Warning",
                    message: "Doctor Saved Warning",
                });
                setSaveLoader(false);
            }
        }
    }, [doctorSave.data, doctorSave.errorMessage]);

    const handleTitleChange = (e) => {
        setTitle(e);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
        if (!/^[a-zA-Z]+( [a-zA-Z]+)*$/.test(e.target.value)) {
            setNameError("Invalid Name");
        } else {
            setNameError("");
        }
    };

    const handleSpecChange = (e) => {
        setSpecialist(e.target.value);
        if (!/^[A-Za-z\s]+$/.test(e.target.value)) {
            setSpecialistError("Invalid Specialist");
        } else {
            setSpecialistError("");
        }
    };

    const handleExpChange = (e) => {
        setExperience(e.target.value);
        if (!/\b(\w+)$/.test(e.target.value)) {
            setExperienceError("Invalid Experience");
        } else {
            setExperienceError("");
        }
    };

    const handleContactChange = (e) => {
        setContact(e.target.value);
        if (!/^(\+94|0)\d{9}$/.test(e.target.value)) {
            setContactError("Invalid Contact");
        } else {
            setContactError("");
        }
    };

    const handleContactTwoChange = (e) => {
        setContactTwo(e.target.value);
        if (!/^(\+94|0)\d{9}$/.test(e.target.value)) {
            setContactTwoError("Invalid Contact");
        } else {
            setContactTwoError("");
        }
    };

    const handleRegNo = (e) => {
        setRegNo(e.target.value);
    };

    const handleDesignation = (e) => {
        setDesignation(e.target.value);
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
        setAddress(e.target.value);
        if (!/^[a-zA-Z0-9\s,./'"()-]+$/.test(e.target.value)) {
            setAddressError("Invalid Address");
        } else {
            setAddressError("");
        }
    };

    const handleCurrentHospitalChange = (e) => {
        setCurrentHospital(e.target.value);
        if (!/^[a-zA-Z0-9\s,./'"()-]+$/.test(e.target.value)) {
            setCurrentHospitalError("Invalid Hospital");
        } else {
            setCurrentHospitalError("");
        }
    };

    const handleClose = () => {
        onClose();
        clear();
    };

    const titleList = [
        {value: "MR.", label: "MR."},
        {value: "MRS.", label: "MRS."},
        {value: "DR.", label: "DR."},
        {value: "VEN.", label: "VEN."},
    ];

    const deleteBranch = (index) => {
        const array = [...addedBranches];
        array.splice(index, 1);
        setAddedBranches(array);
    };

    const deleteCurrentBranch = (index) => {
        const array = [...currentBranch];
        array.splice(index, 1);
        setCurrentBranch(array);
    };

    const handleBranchPercentageChange = (e, index, isNewBranch) => {
        const array = isNewBranch ? [...addedBranches] : [...currentBranch];
        const updatedBranch = {...array[index], percentage: e.target.value};
        array[index] = updatedBranch;
        isNewBranch ? setAddedBranches(array) : setCurrentBranch(array);
    };

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="sm">
            <MDBox
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                sx={{margin: "15px"}}
            >
                <Typography
                    sx={{
                        color: "#1C5E7C",
                        fontWeight: 450,
                        fontSize: "19px",
                        marginBottom: "25px",
                        position: "absolute",
                        top: "15px",
                        left: "15px",
                    }}
                >
                    {" "}
                    {update ? "Doctor " : "Add Doctor "}
                </Typography>

                <div
                    style={{
                        position: "absolute",
                        top: "12px",
                        left: "75px",
                    }}
                >
                    {update && (
                        <IconButton aria-label="delete">
                            <DriveFileRenameOutlineIcon
                                fontSize={"small"}
                                onClick={() => {
                                    if (editMode) {
                                        setEditMode(false);
                                    } else {
                                        setEditMode(true);
                                    }
                                }}
                            />
                        </IconButton>
                    )}
                </div>

                <Grid container spacing={2} sx={{marginTop: "10px"}}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <DropDown
                            disabled={!editMode}
                            label={"Title"}
                            options={titleList}
                            value={title}
                            onChange={handleTitleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                        <TextInput
                            placeholder={"Name"}
                            startIcon={<PersonIcon/>}
                            value={name}
                            onChange={handleNameChange}
                            error={nameError !== ""}
                            disabled={!editMode}
                        />
                        {nameError && (
                            <div style={{color: "red", fontSize: "12px"}}>{nameError}</div>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextInput
                            placeholder={"Specialist"}
                            startIcon={<PersonIcon/>}
                            value={specialist}
                            onChange={handleSpecChange}
                            error={specialistError !== ""}
                            disabled={!editMode}
                        />
                        {specialistError && (
                            <div style={{color: "red", fontSize: "12px"}}>
                                {specialistError}
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextInput
                            placeholder={"Experience"}
                            value={experience}
                            onChange={handleExpChange}
                            error={experienceError !== ""}
                            disabled={!editMode}
                        />
                        {experienceError && (
                            <div style={{color: "red", fontSize: "12px"}}>
                                {experienceError}
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextInput
                            placeholder={"Current Hospital"}
                            value={currentHospital}
                            onChange={handleCurrentHospitalChange}
                            disabled={!editMode}
                            error={currentHospitalError !== ""}
                        />
                        {currentHospitalError && (
                            <div style={{color: "red", fontSize: "12px"}}>
                                {currentHospitalError}
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextInput
                            placeholder={"Mobile No 1"}
                            startIcon={<AccountBoxIcon/>}
                            value={contact}
                            onChange={handleContactChange}
                            error={contactError !== ""}
                            disabled={!editMode}
                        />
                        {contactError && (
                            <div style={{color: "red", fontSize: "12px"}}>
                                {contactError}
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextInput
                            placeholder={"Mobile No 2"}
                            startIcon={<AccountBoxIcon/>}
                            value={contactTwo}
                            onChange={handleContactTwoChange}
                            error={contactTwoError !== ""}
                            disabled={!editMode}
                        />
                        {contactTwoError && (
                            <div style={{color: "red", fontSize: "12px"}}>
                                {contactTwoError}
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextInput
                            placeholder={"Address"}
                            startIcon={<LocationOnIcon/>}
                            value={address}
                            onChange={handleAddressChange}
                            error={addressError !== ""}
                            disabled={!editMode}
                        />
                        {addressError && (
                            <div style={{color: "red", fontSize: "12px"}}>
                                {addressError}
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextInput
                            placeholder={"Registration Number"}
                            value={regNo}
                            onChange={handleRegNo}
                            disabled={!editMode}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextInput
                            placeholder={"Designation"}
                            value={designation}
                            onChange={handleDesignation}
                            disabled={!editMode}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{width: "100%"}}
                                label="Joined Date"
                                value={joinedDate}
                                onChange={(date) => setJoinedDate(date)}
                                disabled={!editMode}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <DropDown
                            label={"Clinic"}
                            options={branch}
                            value={selectedBranch}
                            onChange={handleBranchChange}
                            disabled={!editMode}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <CommonButton
                            disabled={!isBranchSelected || !editMode}
                            fullWidth
                            name={"+ Add Clinic"}
                            variant={"Fill"}
                            onClick={() => {
                                const exists = addedBranches.some(
                                    (branch) => branch.id === selectedBranch.branchId
                                ) || currentBranch.some(
                                    (branch) => branch.branchId === selectedBranch.branchId
                                );

                                if (!exists) {
                                    const array = [...addedBranches];
                                    array.push({
                                        branchName: selectedBranch.name,
                                        id: selectedBranch.branchId,
                                        percentage: "0",
                                    });
                                    setAddedBranches(array);
                                    setSelectedBranch(null);
                                    setIsBranchSelected(false);
                                } else {
                                    sendWarningNotification({
                                        title: "Error",
                                        message: "Clinic already exist!",
                                    });
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                <Box sx={{marginTop: "20px"}}>
                    {addedBranches.map((location, index) => (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                height: "42px",
                                border: "1.2px solid #2D9DE5",
                                borderRadius: "4px",
                                backgroundColor: "white",
                                marginBottom: "10px",
                            }}
                        >
                            <div>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        color: "#1C5D7C",
                                        margin: "5px",
                                    }}
                                >
                                    {location.branchName}
                                </Typography>
                            </div>

                            <div style={{margin: "10px", width: "85px"}}>
                                <input
                                    style={{width: "30px", padding: "5px", textAlign: "center"}}
                                    placeholder={"Percentage"}
                                    value={location.percentage}
                                    onChange={(e) => handleBranchPercentageChange(e, index, true)}
                                />
                            </div>

                            <div>
                                <CommonButton
                                    circular={true}
                                    name={"new"}
                                    variant={"outline"}
                                />
                                <IconButton aria-label="delete">
                                    <CloseIcon
                                        onClick={() => {
                                            deleteBranch(index);
                                        }}
                                    />
                                </IconButton>
                            </div>
                        </div>
                    ))}
                </Box>

                <Box sx={{marginTop: "20px"}}>
                    {currentBranch.map((location, index) => (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                height: "42px",
                                border: "1.2px solid #2D9DE5",
                                borderRadius: "4px",
                                backgroundColor: "white",
                                marginBottom: "10px",
                            }}
                        >
                            <div>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        color: "#1C5D7C",
                                        margin: "5px",
                                    }}
                                >
                                    {location.name}
                                </Typography>
                            </div>

                            <div style={{margin: "10px", width: "85px"}}>
                                <input
                                    style={{width: "30px", padding: "5px", textAlign: "center"}}
                                    disabled={!editMode}
                                    placeholder={"Percentage"}
                                    value={location.percentage}
                                    onChange={(e) =>
                                        handleBranchPercentageChange(e, index, false)
                                    }
                                />
                            </div>

                            <div>
                                <CommonButton
                                    circular={true}
                                    name={"old"}
                                    variant={"outline"}
                                />
                                <IconButton aria-label="delete" disabled={!editMode}>
                                    <CloseIcon
                                        onClick={() => {
                                            deleteCurrentBranch(index);
                                        }}
                                    />
                                </IconButton>
                            </div>
                        </div>
                    ))}
                </Box>

                <MDBox
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "15px",
                    }}
                >
                    <Box marginRight={"10px"}>
                        <CommonButton
                            sx={{width: "90px"}}
                            onClick={handleClose}
                            name={"Cancel"}
                            variant={"outline"}
                        />
                    </Box>

                    {editMode && (
                        <CommonButton
                            onClick={update ? updatedDoctor : saveDoctor}
                            name={update ? "Update Doctor" : "Add Doctor"}
                            variant={"Fill"}
                            disabled={
                                update ? false : !isDataComplete || addedBranches.length === 0
                            }
                        />
                    )}
                </MDBox>
            </MDBox>
        </ModalComponent>
    );
}

DoctorAddModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    update: PropTypes.bool,
    updateData: PropTypes.shape({
        title: PropTypes.string,
        name: PropTypes.string,
        specialist: PropTypes.string,
        experience: PropTypes.string,
        fee: PropTypes.string,
        percentage: PropTypes.string,
        contact_no: PropTypes.string,
        address: PropTypes.string,
        doctor_id: PropTypes.string,
    }),
};
export default DoctorAddModal;
