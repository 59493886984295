import { useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import {logo} from 'config/images'
import MDBox from "common/components/MDBox";
import MDTypography from "common/components/MDTypography";
import SidenavCollapse from "./SidenavCollapse";
import SidenavRoot from "./SidenavRoot";
import userRoleConfig from "config/userRoleConfig";
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import {Icon} from "@mui/material";
import {useSelector} from "react-redux";

function Sidenav({ color, brand, brandName, routes, ...rest }) {

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const collapseName = location.pathname;
  const auth = useSelector((state) => state.AuthSlice.auth);

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const subRouteActive = (sub, pathname) => {
    const r = sub.filter((val) => val.route === pathname)
    return r.length > 0
  }

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, userRole, title, noCollapse, key, href, route, subRoute }) => {
    let returnValue;
    if (type === "collapse") {
      returnValue = subRoute === undefined ? (
          userRoleConfig(userRole, auth?.data?.role) && <NavLink key={key} to={route}>
            <SidenavCollapse subRoute={subRoute} name={name} icon={icon} active={route === collapseName} />
          </NavLink>
      ) : (
          userRoleConfig(userRole, auth?.data?.role) &&
          <SidenavCollapse key={key} subRoute={subRoute} name={name} icon={icon} active={subRouteActive(subRoute, collapseName)} />
      );
    }

    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
            display={{ xs: "block", xl: "none" }}
            position="absolute"
            top={0}
            right={0}
            p={1.625}
            onClick={closeSidenav}
            sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="dark">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox>
          <img src={logo} alt="My PNG Image" />
        </MDBox>
      </MDBox>
      <Divider sx={{height:"3px"}} />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
