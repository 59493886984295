import React, { useState } from "react";
import WideCard from "common/components/WideCard";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Toggle from "common/components/Toggle/Toggle";
import MDBox from "common/components/MDBox";
import StockTransferView from "components/StockTransferView";
import StockTransferAdd from "components/StockTransferAdd";

const toggleData = [
    {
        view: "Stock Transfer View",
        value: "stock transfer view",
    },
    {
        view: "Stock Transfer Add",
        value: "stock transfer add",
    },
];

function StockTransfer( ) {
    const [onAlignment, setOnAlignment] = useState("stock transfer view")

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setOnAlignment(newAlignment);
        }
    };

    return (
        <WideCard>
            <Typography sx={{ color: "#1C5E7C", fontWeight: 410, fontSize: "18px" }}>
                Stock Transfer
            </Typography>
            <Box>
                <Divider sx={{ borderBottom: "1.3px solid black" }} />
            </Box>

            <Toggle
                data={toggleData}
                value={onAlignment}
                onChange={handleAlignment}
            />

            <MDBox>
                {onAlignment === "stock transfer view" ? (
                    <StockTransferView/>
                ) : onAlignment === "stock transfer add" ? (
                     <StockTransferAdd/>
                ) : null}
            </MDBox>

        </WideCard>
    );
}
export default StockTransfer;
