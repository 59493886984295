import React, {useEffect, useState} from 'react';
import WideCard from "common/components/WideCard";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import PaymentTable from "../PaymentTable";
import PaymentDoctor from "../PaymentDoctor";
import PropTypes from "prop-types";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import IconButton from "@mui/material/IconButton";
import {useDispatch} from "react-redux";
import {getPayment} from "services/payment/payment";
import {getActiveDiscount} from "services/discount/discount";

function Payment({bookingId, changePage}) {

    const dispatch = useDispatch();
    const [discount, setDiscount] = useState({});

    useEffect(() => {
        dispatch(getPayment(bookingId));
        dispatch(getActiveDiscount());
    }, []);

    return (
        <WideCard>

            <Typography sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '18px'}}>Payment</Typography>
            <Box>
                <Divider sx={{borderBottom: '1.3px solid black'}}/>
            </Box>

            <Grid container spacing={2} sx={{display: 'flex', flexDirection: 'row'}}>
                <Grid item xs={12} sm={12} md={12} lg={8}>
                    <PaymentTable ChangeDiscount={(val)=> setDiscount(val)}/>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={4} sx={{marginTop: '20px'}}>
                    <PaymentDoctor
                        discount={discount}
                        changePage={()=> {changePage('checkout-list')}}
                    />
                </Grid>
            </Grid>
            <Box>
                <IconButton aria-label="back">
                    <ArrowBackIosNewIcon
                        sx={{ color: '#2D9DE5' }}
                        onClick={()=>{
                            changePage('checkout-list');
                        }}
                    />
                </IconButton>
            </Box>
        </WideCard>
    );
}

Payment.propTypes = {
    bookingId: PropTypes.any.isRequired,
    changePage: PropTypes.func.isRequired,
};
export default Payment;
