import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "services/AxiosOrders";

export const getTodayAllDoctors = createAsyncThunk('get-doctors', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/doctor/get_today_doctors`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getDashboardCount = createAsyncThunk('get-counts', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/dashboard/get_dashboard_counts`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getTodayBooking = createAsyncThunk('get-booking', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/dashboard/get_today_booking`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateBookingStatus = createAsyncThunk('status-update', async ({id,statusUpData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/booking/update_only_booking_status/${id}`,  statusUpData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});
