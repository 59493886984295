import React, {useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import {CardActions} from "@mui/material";
import MDButton from "common/components/MDButton";
import Icon from "@mui/material/Icon";
import "./styles.css"
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

function ResTblCard({columns, dataJson, searchKey}) {
    const [expanded, setExpanded] = React.useState(false);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    let count = 0

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [windowDimensions]);

    function getWindowDimensions() {
        const {
            innerWidth: width, innerHeight: height
        } = window;
        return {
            width, height
        };
    }

    return (
        <Card style={{width: '100%'}}>
            <div>
                <div>
                    {Object.keys(columns).map((ele) => {
                        if (!expanded) {
                            if (columns[ele].accessor === searchKey[0] || columns[ele].accessor === searchKey[1]) {
                                count++
                                return (<Grid container className={count % 2 === 1 ? 'non-expand' : 'expand'} key={ele}>
                                    <Grid item xs={12} alignItems="center">

                                        <Grid item xs={12} container direction="row" alignItems="center">
                                            <Grid xs={5}>
                                                <label className="header-style">
                                                    {columns[ele].headerName}
                                                </label>
                                            </Grid>
                                            <Grid xs={7}>
                                                <label className="accessor-style">
                                                    {dataJson[columns[ele].accessor]}
                                                </label>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>)
                            }
                        } else {
                            return (<Grid container className={ele % 2 === 0 ? 'non-expand' : 'expand'} key={ele}>
                                <Grid item xs={12} alignItems="center">
                                    <Grid item xs={12} container direction="row" alignItems="center">
                                        <Grid xs={5}>
                                            <label className="header-style">
                                                {columns[ele].headerName}
                                            </label>
                                        </Grid>
                                        <Grid xs={7}>
                                            <label className="accessor-style">
                                                {dataJson[columns[ele].accessor]}
                                            </label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>)
                        }
                    })}
                </div>
            </div>
            <CardActions>
                <MDButton iconOnly={true}
                          onClick={handleExpandClick}
                          aria-expanded={expanded}
                >
                    {expanded ? <Icon className="icon-remove-color" fontSize="large">remove</Icon> :
                        <Icon className="icon-add-color" fontSize="large">add</Icon>}
                </MDButton>
            </CardActions>
        </Card>)
}

ResTblCard.propTypes = {
    columns: PropTypes.object.isRequired,
    dataJson: PropTypes.object.isRequired,
    searchKey: PropTypes.array.isRequired,
};
export default ResTblCard;