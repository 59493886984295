import { createSlice } from "@reduxjs/toolkit";
import {searchSlots} from "services/slot/slot";

const initialState = {
    slot: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const searchSlotSlice = createSlice({
    name: "searchSlots",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(searchSlots.pending, (state) => {
                state.slot.isLoading = true;
            })
            .addCase(searchSlots.fulfilled, (state, { payload }) => {
                state.slot.isLoading = false;
                state.slot.isSuccess = true;
                state.slot.data = payload;
            })
            .addCase(searchSlots.rejected, (state, { payload }) => {
                state.slot.isLoading = false;
                state.slot.isSuccess = false;
                state.slot.errorMessage = payload;
            });
    },
});

export default searchSlotSlice.reducer;
