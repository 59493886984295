import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "services/AxiosOrders";

export const getPayment = createAsyncThunk('get-payment', async (id, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/payment/${id}`  );
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const addPayment = createAsyncThunk('add-payment', async (proData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/payment`, proData, {
            headers: {
                "Content-type": "application/json",
                'Accept': "*/*",
                'Accept-Encoding': "gzip, deflate, br"
            },
            responseType: 'blob'
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getAllPayments = createAsyncThunk('get-all-payments', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/payment`  );
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});


