import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../AxiosOrders";

export const getAllBookingDetailsBookingWise = createAsyncThunk('get-booking-details', async (id, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/booking_details/${id}`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const saveBookingDetails = createAsyncThunk('save-booking-details', async (bookingData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/booking_details`, bookingData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});


export const deleteBookingDetails = createAsyncThunk('delete-booking-details', async (id, { rejectWithValue }) => {
        try {
            const data = await instance.delete(`/booking_details/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

