import React, {useEffect, useState} from 'react';
import ModalComponent from "common/components/Modal";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextInput from "common/components/TextInput/TextInput";
import CommonButton from "common/components/Button";
import IconButton from "@mui/material/IconButton";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import PropTypes from "prop-types";
import DropDown from "common/components/DropDown/DropDown";
import {useDispatch, useSelector} from "react-redux";
import {getItems, postItem, updateItem} from "services/item/item";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";
import {getCategory} from "services/category/category";

function ItemAddModal({open, onClose, isView = false,itemData}) {

    const dispatch = useDispatch();
    const [viewMode, setViewMode] = useState(isView);
    const [editMode, setEditMode] = useState(!viewMode);
    const [item, setItem] = useState(isView? itemData.itemName:'');
    const [code, setCode] = useState(isView? itemData.code:'');
    const [unit, setUnit] = useState(isView? itemData.unit:'');
    const [categoryId,setCategoryId] = useState(isView? itemData.category.categoryId: '');
    const [options,setOptions] = useState([]);
    const [loader,setLoader] = useState(false);
    const saveItemData = useSelector(state => state.SaveItemSlice.item);
    const updateItemData = useSelector(state => state.UpdateItemSlice.item);
    const categoryData = useSelector(state => state.CategorySlice.category);
    const isComplete = item && code && unit && categoryId;

    const handleItemChange = (e) => {
        setItem(e.target.value);
    };

    const handleCodeChange = (e) => {
        setCode(e.target.value);
    };

    const handleUnitChange = (e) => {
        setUnit(e.target.value);
    };

    const handleCategorySelect = (e) => {
        setCategoryId(e);
    };

    const handleAction = ()=>{
        const itmData = {
            itemName: item,
            code: code,
            unit: unit,
            categoryId: categoryId
        }

        if(viewMode){
            setLoader(true);
            dispatch(updateItem({id:itemData.itemId,itemUpData:itmData}));
        }else {
            setLoader(true);
            dispatch(postItem(itmData));
        }

    }

    useEffect(() => {
        dispatch(getCategory());
    }, []);

    useEffect(() => {
        if(categoryData.isSuccess && !categoryData.isLoading){
            const data = categoryData?.data;
            if(Array.isArray(data)){
                const array = data.map((item)=>(
                    {
                        value:item.categoryId,
                        label:item.description
                    }
                ));
               setOptions(array);
            }
        }
    }, [categoryData.data]);

    useEffect(() => {
        if (loader){
            setLoader(false);
            if (saveItemData.isSuccess && !saveItemData.isLoading) {
                dispatch(getItems());
                onClose();
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Item Saved Success',
                })
            } else if (!saveItemData.isSuccess && !saveItemData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Save Error'
                })
            }
        }
    }, [saveItemData.data]);

    useEffect(() => {
        if (loader){
            setLoader(false);
            if (updateItemData.isSuccess && !updateItemData.isLoading) {
                dispatch(getItems());
                onClose();
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Item Update Success',
                })
            } else if (!updateItemData.isSuccess && !updateItemData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Update Error'
                })
            }
        }
    }, [updateItemData.data]);

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="sm">
            <MDBox sx={{margin: '10px'}}>
                <div style={{overflow: 'hidden'}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Box sx={{width: '500px', margin: '0 auto'}}>
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <Typography sx={{
                    color: '#1C5E7C',
                    fontWeight: 450,
                    fontSize: '16px',
                    marginBottom: '25px',
                    position: 'absolute',
                    top: '15px',
                    left: '15px'
                }}> {viewMode ? 'Item' : 'Add Item'} </Typography>

                <div style={{
                    position: 'absolute',
                    top: '8px',
                    left: '70px'
                }}
                >
                    {viewMode && <IconButton aria-label="delete">
                        <DriveFileRenameOutlineIcon fontSize={'small'} onClick={() => {
                            if (editMode) {
                                setEditMode(false);
                            } else {
                                setEditMode(true);
                            }
                        }}/>
                    </IconButton>}
                </div>

                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DropDown
                            options={options}
                            disabled={!editMode}
                            label={"Category"}
                            value={categoryId}
                            onChange={handleCategorySelect}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextInput
                            placeholder={"Item"}
                            disabled={!editMode}
                            value={item}
                            onChange={handleItemChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextInput
                            placeholder={"Code"}
                            disabled={!editMode}
                            value={code}
                            onChange={handleCodeChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextInput
                            placeholder={"Unit"}
                            disabled={!editMode}
                            value={unit}
                            onChange={handleUnitChange}
                        />
                    </Grid>
                </Grid>

                <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px'}}>
                    <div style={{margin: '5px'}}>
                        <CommonButton
                            name={'Cancel'}
                            variant={'outline'}
                            onClick={() => {
                                onClose();
                            }}
                        />
                    </div>
                    <div style={{margin: '5px'}}>
                        {editMode &&
                            <CommonButton
                                name={viewMode ? 'Update' : 'Add'}
                                variant={'Fill'}
                                disabled={!isComplete}
                                onClick={handleAction}
                            />
                        }

                    </div>
                </Box>

            </MDBox>
        </ModalComponent>
    );
}

ItemAddModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isView:PropTypes.bool,
    itemData:PropTypes.object
};

export default ItemAddModal;
