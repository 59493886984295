import MDBox from "common/components/MDBox";
import ToothSelect from "common/components/ToothSelect";
import WideCard from "common/components/WideCard";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import toothDetails from "./toothDetailsJson";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ToothChartToolKit from "components/ToothChartToolKit";
import {useDispatch, useSelector} from "react-redux";
import ToothHistoryModal from "components/ToothHistoryModal";
import {
    getPatientToothDetails, updateCrown,
    updateDirection, updateFillignType, updateMissingStatus,
    updatePrimaryStatus,
    updateRCT
} from "services/toothChart/toothChart";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CommonButton from "common/components/Button";

export default function ToothChart({patientId,changePage}) {
    
    console.log("Image details : ",toothDetails );
    const dispatch = useDispatch();
    const toothDetailsData = useSelector(state => state.PatientToothDetailsSlice.toothDetails);
    const updateTctData = useSelector(state => state.UpdateRctSlice.toothDetails);
    const updatePrimaryStatusData = useSelector(state => state.UpdatePrimaryStatusSlice.toothDetails);
    const updateDirectionData = useSelector(state => state.UpdateDirectionSlice.toothDetails);
    const updateMissingData = useSelector(state => state.UpdateMissingSlice.toothDetails);
    const updateFillingData = useSelector(state => state.UpdateFillingSlice.toothDetails);
    const updateCrownData = useSelector(state => state.UpdateCrownSlice.toothDetails);
    const updateBridgeData = useSelector(state => state.UpdateBridgeSlice.toothDetails);

    const [updateRctLoader,setUpdateRctLoader] = useState(false);
    const [updatePrimaryLoader,setUpdatePrimaryLoader] = useState(false);
    const [updateDirectionLoader,setUpdateDirectionLoader] = useState(false);
    const [updateMissingLoader,setUpdateMissingLoader] = useState(false);
    const [updateLoader,setUpdateLoader] = useState(false);
    const [toothDataJson, setToothDataJson] = useState([]);
    const [selectHistoryId, setSelectHistoryId] = useState('');
    const [historyModalOpen, setHistoryModalOpen] = useState(false);
    const [actionData , setActionData] = useState({});
    const [clearData, setClearData] = useState(false);
    const [isDisable, setIsDisable] = useState(false);

    const handleClick = (val,data)=>{

        switch(val) {
            case 'rct-update':
                if(actionData.action === 'rct'){
                    setUpdateRctLoader(true)
                    dispatch(updateRCT({id:data.rctId,updateData:data.rctRound === actionData.value.rctRound ? {rctRound:0} : actionData.value }));
                    setActionData({});
                }
                break;
            case 'update-primary':
               if (actionData.action === 'primary'){
                   setUpdatePrimaryLoader(true)
                   //dispatch(updatePrimaryStatus({id:data.rctDto.rctId,updateData:actionData.value}));
                   dispatch(updatePrimaryStatus({id:data.rctDto.rctId,  updateData:data.rctDto.primaryStatus === actionData.value.primaryStatus ? {primaryStatus:0} : actionData.value}));
                   setActionData({});
               }

                if (actionData.action === 'overrupted'){
                    setUpdateDirectionLoader(true);
                    dispatch(updateDirection({id:data.directionDto.directionId,updateData:data.directionDto.directionType === actionData.value.directionType ? {directionType:0 } : actionData.value }));
                    setActionData({});
                }

                if (actionData.action === 'missing'){
                    setUpdateMissingLoader(true);
                    dispatch(updateMissingStatus({id:data.missingDto.missingId,updateData:actionData.value}));
                    setActionData({});
                }
                if(actionData.action === 'filling'){
                    console.log(actionData);
                    setUpdateLoader(true);
                    dispatch(updateFillignType({id:data.fillingDtoGets.occlusal.fillingId,updateData:actionData.value}));
                    setActionData({});
                }
                break;
            case 'update-filling':
                if (actionData.action === 'filling'){
                    setUpdateLoader(true);
                    dispatch(updateFillignType({id:data.fillingId,updateData:actionData.value}));
                    setActionData({});
                }
                break;
            case 'crown-bridge':
                if (actionData.action === 'crown'){
                    setUpdateLoader(true);
                    dispatch(updateCrown({id:data.id,updateData:{crown:data.type}}));
                    setActionData({});
                }

                if (actionData.action === 'bridge'){
                    setUpdateLoader(true);
                    dispatch(updateCrown({id:data.id,updateData:{bridge:data.type}}));
                    setActionData({});
                }
                break;
            default:
        }
    }

    useEffect(() => {
        dispatch(getPatientToothDetails(patientId));
    }, []);

    useEffect(() => {
        if(updateRctLoader){
            setUpdateRctLoader(false);
            if(updateTctData.isSuccess && !updateTctData.isLoading){
                setClearData(true);
                dispatch(getPatientToothDetails(patientId));
            }
        }
    }, [updateTctData.data]);

    useEffect(() => {
        if(updatePrimaryLoader){
            setUpdatePrimaryLoader(false);
            if(updatePrimaryStatusData.isSuccess && !updatePrimaryStatusData.isLoading){
                setClearData(true);
                dispatch(getPatientToothDetails(patientId));
            }
        }
    }, [updatePrimaryStatusData.data]);

    useEffect(() => {
        if(updateDirectionLoader){
            setUpdateDirectionLoader(false);
            if(updateDirectionData.isSuccess && !updateDirectionData.isLoading){
                setClearData(true);
                dispatch(getPatientToothDetails(patientId));
            }
        }
    }, [updateDirectionData.data]);

    useEffect(() => {
        if(updateMissingLoader){
            setUpdateMissingLoader(false);
            if(updateMissingData.isSuccess && !updateMissingData.isLoading){
                setClearData(true);
                dispatch(getPatientToothDetails(patientId));
            }
        }
    }, [updateMissingData.data]);

    useEffect(() => {
        if(updateLoader){
            setUpdateLoader(false);
            if(updateFillingData.isSuccess && !updateFillingData.isLoading){
                setClearData(true);
                dispatch(getPatientToothDetails(patientId));
            }
        }
    }, [updateFillingData.data]);

    useEffect(() => {
        if(updateLoader){
            setUpdateLoader(false);
            if(updateCrownData.isSuccess && !updateCrownData.isLoading){
                setClearData(true);
                dispatch(getPatientToothDetails(patientId));
            }
        }
    }, [updateCrownData.data]);

    useEffect(() => {
        if(updateLoader){
            setUpdateLoader(false);
            if(updateBridgeData.isSuccess && !updateBridgeData.isLoading){
                setClearData(true);
                dispatch(getPatientToothDetails(patientId));
            }
        }
    }, [updateBridgeData.data]);

    useEffect(() => {
        const data = [...toothDetails];
        toothDetailsData.data.forEach((val, index) => {
            data[index] = {...data[index], ...val}
        })
        setToothDataJson(data);
    }, [toothDetailsData.data]);

    const openHistoryModal = (e) => {
        setSelectHistoryId(e);
        setHistoryModalOpen(true);
    }
    return (
        <WideCard>
            <Typography sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '18px'}}>Tooth Chart</Typography>
            <Box>
                <Divider sx={{borderBottom: '1.3px solid black'}}/>
            </Box>
            <Box>
                <ToothChartToolKit
                    handleClick={(val)=>{
                        setActionData(val);
                    }}
                    clearData={clearData}
                    setClearData={()=>{
                        setClearData(false);
                    }}
                    setDisable={(val)=>{
                        setIsDisable(val);
                    }}
                />
            </Box>
            <MDBox sx={{border: 1, borderColor: '#bebebe', borderRadius: 5, padding: 2, marginY: 1}}>
                <Typography sx={{color: '#1C5E7C', fontWeight: 500, fontSize: '17px',}}>Upper Right</Typography>
                <MDBox sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    {toothDataJson.map((val, index) => {
                        return index < 8 && <ToothSelect disable={isDisable} historyOpen={(id) => openHistoryModal(id)} handleClick={handleClick} key={index} toothData={val}/>
                    })}
                </MDBox>
            </MDBox>
            <MDBox sx={{border: 1, borderColor: '#bebebe', borderRadius: 5, padding: 2, marginY: 1}}>
                <Typography sx={{color: '#1C5E7C', fontWeight: 500, fontSize: '17px',}}>Upper Left</Typography>
                <MDBox sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    {toothDataJson.map((val, index) => {
                        return 8 <= index && index < 16 && <ToothSelect disable={isDisable} historyOpen={(id) => openHistoryModal(id)} handleClick={handleClick} key={index} toothData={val}/>
                    })}
                </MDBox>
            </MDBox>
            <MDBox sx={{border: 1, borderColor: '#bebebe', borderRadius: 5, padding: 2, marginY: 1}}>
                <Typography sx={{color: '#1C5E7C', fontWeight: 500, fontSize: '17px',}}>Lower Right</Typography>
                <MDBox sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    {toothDataJson.map((val, index) => {
                        return 16 <= index && index < 24 && <ToothSelect disable={isDisable} historyOpen={(id) => openHistoryModal(id)} handleClick={handleClick} key={index} toothData={val}/>
                    })}
                </MDBox>
            </MDBox>
            <MDBox sx={{border: 1, borderColor: '#bebebe', borderRadius: 5, padding: 2, marginY: 1}}>
                <Typography sx={{color: '#1C5E7C', fontWeight: 500, fontSize: '17px',}}>Lower Left</Typography>
                <MDBox sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    {toothDataJson.map((val, index) => {
                        return 24 <= index && index < 32 && <ToothSelect disable={isDisable} historyOpen={(id) => openHistoryModal(id)} handleClick={handleClick} key={index} toothData={val}/>
                    })}
                </MDBox>
            </MDBox>
            <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>
                <IconButton aria-label="back">
                    <ArrowBackIosNewIcon sx={{ color: '#2D9DE5' }} onClick={()=>{
                        changePage('select-procedures');
                    }} />
                </IconButton>
                <CommonButton
                    name={'Next'}
                    variant={'Fill'}
                    onClick={()=>{
                        changePage('clinical-note');
                    }}
                />

            </Box>
            {historyModalOpen &&
                <ToothHistoryModal
                    id={selectHistoryId}
                    open={historyModalOpen}
                    onClose={()=> setHistoryModalOpen(false)}
                />
            }
        </WideCard>
    )
}

ToothChart.propTypes = {
    patientId:PropTypes.string,
    changePage:PropTypes.func
};
