import { createSlice } from "@reduxjs/toolkit";
import {getAvailableSlotsByDoctorAndDate} from "services/slot/slot";

const initialState = {
    slot: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const slotsByDoctorAndDateSlice = createSlice({
    name: "slotsByDoctorAndDate",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAvailableSlotsByDoctorAndDate.pending, (state) => {
                state.slot.isLoading = true;
            })
            .addCase(getAvailableSlotsByDoctorAndDate.fulfilled, (state, { payload }) => {
                state.slot.isLoading = false;
                state.slot.isSuccess = true;
                state.slot.data = payload;
            })
            .addCase(getAvailableSlotsByDoctorAndDate.rejected, (state, { payload }) => {
                state.slot.isLoading = false;
                state.slot.isSuccess = false;
                state.slot.errorMessage = payload;
            });
    },
});

export default slotsByDoctorAndDateSlice.reducer;
