import Grid from "@mui/material/Grid";
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import MDBox from "common/components/MDBox";
import TextInput from "common/components/TextInput/TextInput";
import {loginImage,myDentistLogo,receptionIconImage,inventoryIconImage,dentalIconImage} from 'config/images';
import CommonButton from "common/components/Button";
import Link from '@mui/material/Link';
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import instance from "services/AxiosOrders";
import {useEffect, useState} from "react";
import {sendDangerNotification, sendSuccessNotification} from "common/components/Notification/SendNotification";
import {setItem} from "common/utils/Storage/Storage";
import {getMainUrl} from "common/utils/CommonFunctions";
import CircularProgress from "@mui/material/CircularProgress";

function Basic() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const loginAction = () => {
    setLoading(true);
    instance.post('/user/login', {
      "userName": email,
      "password": password,
    }, {
      headers: {
        'Content-Type': 'application/json;'
      }
    }).then(function (res) {
      if(res.data?.massage === undefined){
        setLoading(false);
        sendSuccessNotification({
          title: 'Success',
          message: 'Login Success',
        })
        setItem("my-dentist-token", res.data.token);
        if(window.location.hostname === 'localhost'){
          window.location.reload();
        }else {
          window.location.href= getMainUrl();
        }
      }else if(res.data?.massage === "wrong details"){
        setLoading(false);
        sendDangerNotification({
          title: 'Error',
          message: 'Invalid username or password',
        })
      }
    })
        .catch(function () {
          setLoading(false);
          sendDangerNotification({
            title: 'Error',
            message: 'Invalid username or password',
          })
        });
  }

  const handleKeyDown =(e)=>{
    if (e.keyCode === 13) { // Check if the pressed key is Enter (key code 13)
      loginAction();
    }
  }

  return (
      <MDBox width="100%" height="100vh">
        <Grid container justifyContent="center" height="100%" style={{backgroundColor:'white'}}>

          <Grid item display={{xs:  'none', sm: 'none', md: 'block'}} xs={0} sm={0} md={6.75} lg={6.75}>
            <Box width="100%" height="100vh" style={{ display:'flex',justifyContent:'space-around',alignItems:'center', backgroundImage: `url(${loginImage})`, backgroundSize: 'cover', minHeight: '200px' }}>
              <div style={{width:'100%',height:'100%',backgroundColor:'rgba(45, 157, 229, 0.5)',display:'flex',justifyContent:'space-around',alignItems:'center'}}>
                <Box>
                  <div style={{display:'flex',justifyContent:'center',marginBottom:'18px'}}>
                    <img src={receptionIconImage} alt="reception"/>
                  </div>
                  <div style={{display:'flex',justifyContent:'center'}}>
                    <Typography variant="body1" gutterBottom style={{ fontSize: '12px',color:'white' }}>
                      Reception
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div style={{display:'flex',justifyContent:'center',marginBottom:'18px'}}>
                    <img src={dentalIconImage} alt="dental"/>
                  </div>
                  <div style={{display:'flex',justifyContent:'center'}}>
                    <Typography variant="body1" gutterBottom style={{ fontSize: '12px',color:'white' }}>
                      Dentist
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div style={{display:'flex',justifyContent:'center',marginBottom:'18px'}}>
                    <img src={inventoryIconImage} alt="inventory"/>
                  </div>
                  <div style={{display:'flex',justifyContent:'center'}}>
                    <Typography variant="body1" gutterBottom style={{ fontSize: '12px',color:'white' }}>
                      Inventory Management
                    </Typography>
                  </div>
                </Box>
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={5.25} lg={5.25} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <MDBox sx={{width:'75%'}}>
              <MDBox sx={{marginBottom:14,marginTop:2,display:'flex',justifyContent:'center'}}>
                <img src={myDentistLogo} alt="Login" style={{}}/>
              </MDBox>
              <MDBox sx={{margin:3}}>
                <TextInput
                    placeholder={'Username'}
                    startIcon={<PersonIcon/>}
                    onChange={(val)=>{setEmail(val.target.value)}}
                />
              </MDBox>
              <MDBox sx={{margin:3}}>
                <TextInput
                    type="password"
                    placeholder={'Password'}
                    startIcon={<LockIcon/>}
                    onChange={(val)=>{setPassword(val.target.value)}}
                    onKeyDown={handleKeyDown}
                />
              </MDBox>
              <MDBox display={{xs:  'block', sm: 'block', lg: 'flex'}} sx={{margin:3,justifyContent:'space-between'}}>
                <FormControlLabel control={<Checkbox/>} label="Remember me" />
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {

                    }}
                    sx={{fontSize: 12.5,fontWeight:'normal', marginLeft: 1, color: '#2D9DE5'}}
                >
                  Forgot Password?
                </Link>
              </MDBox>
              <MDBox sx={{margin:3,marginTop:10}}>
                <CommonButton
                    name={'Login'}
                    fullWidth
                    onClick={()=>{loginAction()}}
                    startIcon={loading && <CircularProgress sx={{color: '#efefef'}} size={20} />}
                />
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
  );
}

export default Basic;
