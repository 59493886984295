import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import React, {useEffect, useState} from "react";
import Toggle from "common/components/Toggle/Toggle";
import Typography from "@mui/material/Typography";
import WideCard from "common/components/WideCard";
import DoctorsList from "components/DoctorsList";
import TotalBookingView from "components/TotalBookingView";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import {useSelector, useDispatch} from "react-redux";
import {getAllOnlineAppointments} from "services/onlineAppointment/onlineAppointment";
import {getDashboardCount, getTodayBooking, updateBookingStatus} from "services/dashboard/dashboard";
import CommonButton from "common/components/Button";
import ConfirmAlert from "components/ConfirmAlert";
import {patientAdd} from "config/images";

const toggleData = [
    {
        view: 'Online Bookings',
        value: "booking",
    },
    {
        view: "Today Appointment",
        value: "appointment",
    },
];

export default function Dashboard() {
    const auth = useSelector((state) => state.AuthSlice.auth);
    const statusUpdate = useSelector((state) => state.UpdateBookingStatusSlice.booking);

    const onlineBookingColumns = [
        {field: "pName", headerName: "Patient Name", accessor: "pName", textAlign: 'left'},
        {field: "contactNo", headerName: "Contact No", accessor: "contactNo", textAlign: 'left'},
        auth.data?.role === 'admin' && {
            field: "location",
            headerName: "Location",
            accessor: "location",
            textAlign: 'left'
        },
        {field: "date", headerName: "Date", accessor: "date", textAlign: 'left'},
        {field: "time", headerName: "Time", accessor: "time", textAlign: 'left'},
    ];

    const todayAppointmentColumns = [
        {field: "pName", headerName: "Patient Name", accessor: "pName", textAlign: 'left'},
        {field: "contactNo", headerName: "Contact No", accessor: "contactNo", textAlign: 'left'},
        auth.data?.role === 'admin' && {
            field: "location",
            headerName: "Location",
            accessor: "location",
            textAlign: 'left'
        },
        {field: "doctor", headerName: "Doctor Name", accessor: "doctor", textAlign: 'left'},
        {field: "time", headerName: "Time", accessor: "time", textAlign: 'left'},
        {field: "action", headerName: "Action", accessor: "action", textAlign: 'left'},
    ];

    const searchKey = ['pName', 'age'];
    const dispatch = useDispatch();
    const [onAlignment, setOnAlignment] = useState("booking");
    const [bookingCount, setBookingCount] = useState({
        appointmentCount: 0,
        doctorCount: 0,
        todayBookingCount: 0
    })
    const [onlineAppointmentsData, setOnlineAppointmentsData] = useState([]);
    const [todayAppointmentsData, setTodayAppointmentsData] = useState([]);
    const [updateLoader, setUpdateLoader] = useState(false);
    const onlineAppointments = useSelector(state => state.OnlineAppointmentSlice.onlineAppointment);
    const count = useSelector(state => state.DashboardCountSlice.count);
    const todayBooking = useSelector(state => state.TodayBookingSlice.booking);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [bookId, setBookId] = useState(false);
    const [patientName, setPatientName] = useState(false);

    const closeAlert = () => {
        setIsAlertOpen(false);
    };

    const openAlert = (id, patient) => {
        setBookId(id);
        setPatientName(`${patient.title} ${patient.firstName} ${patient.lastName}`);
        setIsAlertOpen(true);
    };

    useEffect(() => {
        dispatch(getAllOnlineAppointments());
        dispatch(getDashboardCount());
        dispatch(getTodayBooking());
    }, []);

    useEffect(() => {
        if (count.isSuccess && !count.isLoading) {
            setBookingCount({
                appointmentCount: count.data?.appointment_count,
                doctorCount: count.data?.doctor_count,
                todayBookingCount: count.data?.today_booking_count
            })
        }
    }, [count.data]);

    useEffect(() => {
        if (todayBooking.isSuccess && !todayBooking.isLoading) {
            const data = todayBooking?.data;
            if (Array.isArray(data)) {
                const array = data.map((val) => {
                    const statusConfig = getStatusConfig(val?.status);

                    return {
                        pName: `${val?.patient?.title} ${val?.patient?.firstName} ${val?.patient?.lastName}`,
                        contactNo: val?.patient?.mobileNumber,
                        location: val?.slot?.chair?.branch?.name,
                        doctor: `${val?.slot?.doctor?.title} ${val?.slot?.doctor?.name}`,
                        time: val?.slot?.startTime,
                        action: (
                            <CommonButton
                                name={val?.status.charAt(0).toUpperCase() + val?.status.slice(1)}
                                variant={statusConfig.variant}
                                circular={statusConfig.circular}
                                color={statusConfig.color}
                                onClick={() => {
                                    if (val.status === "pending") {
                                        openAlert(val.bookingId, val.patient);
                                    }
                                }}
                            />
                        )
                    };
                });
                setTodayAppointmentsData(array);
            }
        }
    }, [todayBooking.data]);

    const getStatusConfig = (status) => {
        switch (status) {
            case "pending":
                return {variant: "Fill", circular: true, color: "#6ec1f6"};
            case "checking":
                return {variant: "Fill", circular: true, color: "#2D9DE5"};
            case "checkout":
                return {variant: "outline", circular: true, color: "#2D9DE5"};
            case "paid":
                return {variant: "Fill", circular: true, color: "#4A7D99"};
            default:
                return {variant: "Fill", circular: true, color: "#96CEF2"};
        }
    };

    useEffect(() => {
        if (onlineAppointments.isSuccess) {
            const data = onlineAppointments?.data;
            if (Array.isArray(data)) {
                const array = data.map((val, index) => ({
                    id: index,
                    pName: `${val.title} ${val.firstName} ${val.lastName}`,
                    contactNo: val.number,
                    location: val?.branch?.location,
                    date: val.startDate + " - " + val.endDate,
                    time: val.requestTime,
                }));
                setOnlineAppointmentsData(array)
            }
        }
    }, [onlineAppointments.data]);

    const handleOnAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setOnAlignment(newAlignment);
        }
    };

    useEffect(() => {
        if (updateLoader) {
            setUpdateLoader(false);
            if (statusUpdate.isSuccess && !statusUpdate.isLoading) {
                dispatch(getTodayBooking());
                closeAlert();
            } else if (!statusUpdate.isSuccess && !statusUpdate.isLoading) {
            }
        }
    }, [statusUpdate.data]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={9}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={7.7} lg={7.7}>
                        <WideCard>
                            <Grid container>
                                <Grid marginBottom={{xs: '10px', sm: 0}} item xs={12} sm={6} md={6} lg={6}>
                                    <TotalBookingView label={'Online Booking'} value={bookingCount.appointmentCount}/>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TotalBookingView label={'General Booking'} value={bookingCount.todayBookingCount}/>
                                </Grid>
                            </Grid>
                        </WideCard>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4.3} lg={4.3}>
                        <WideCard>
                            <TotalBookingView label={'Doctors'} value={bookingCount.doctorCount}/>
                        </WideCard>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <WideCard>
                            <MDBox sx={{marginBottom: '7px'}}>
                                <Toggle data={toggleData} value={onAlignment} onChange={handleOnAlignment}/>
                            </MDBox>
                            <MDBox>
                                {onAlignment === 'booking' ?
                                    <MDTable tableData={onlineAppointmentsData} tableColumns={onlineBookingColumns} searchKey={searchKey}/>
                                    :
                                    <MDTable tableData={todayAppointmentsData} tableColumns={todayAppointmentColumns} searchKey={searchKey}/>
                                }

                            </MDBox>
                        </WideCard>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3}>
                <Card>
                    <MDBox sx={{margin: '6px'}}>
                        <Typography sx={{fontSize: "14px", fontWeight: 500, color: '#1C5E7C'}}>
                            Available Doctors
                        </Typography>
                        <Box>
                            <Divider sx={{borderBottom: '1.3px solid black', marginTop: '5px', marginBottom: '5px'}}/>
                        </Box>
                    </MDBox>
                    <DoctorsList/>
                </Card>
            </Grid>

            <ConfirmAlert
                src={patientAdd}
                name={patientName}
                open={isAlertOpen}
                onClose={closeAlert}
                title="Are you sure"
                subtitle={`You want to check-in ${patientName}?`}
                buttonText="Check-in"
                onclick={() => {
                    setUpdateLoader(true);
                    dispatch(updateBookingStatus({id: bookId, statusUpData: {status: "checking"}}));
                }}
            />
        </Grid>
    )
}
