import React, {useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import CommonButton from "common/components/Button";
import {useDispatch, useSelector} from "react-redux";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import WideCard from "common/components/WideCard";
import TreatmentAddModal from "components/TreatmentAddModal";
import ConfirmAlert from "components/ConfirmAlert";
import {tooth} from "config/images";
import {deleteTreatment, getAllTreatment} from "services/treatment/treatment";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";
import Avatar from "@mui/material/Avatar";

function OnlineTreatment() {
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);
    const [isProcedureAddModalOpen,setProcedureAddModalOpen] = useState(false);
    const [isProcedureViewModalOpen,setProcedureViewModalOpen] = useState(false);
    const [isConfirmAlertOpen , setConfirmAlertOpen] = useState(false);
    const [selectedTreatment, setSelectedTreatment] = useState({});
    const [deleteLoader,setDeleteLoader] = useState(false);
    const treatmentData = useSelector(state => state.TreatmentSlice.treatment);
    const deleteTreatmentData = useSelector(state => state.DeleteTreatmentSlice.treatment);

    useEffect(() => {
        if(treatmentData.isSuccess && !treatmentData.isLoading){
            const array = []
            treatmentData.data?.forEach((val)=> {
                array.push({
                    treatment:(
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Avatar
                                sx={{
                                    width: 30,
                                    height: 30,
                                    margin: '0px',
                                    padding: '0px',
                                    backgroundColor: '#EAF5FC',
                                    marginRight: '5px',
                                    marginTop: '8px',
                                }}
                                src={tooth}
                            />
                            <div style={{marginTop: '10px'}}>{val.treatmentName}</div>
                        </div>
                    ),
                    name:val.treatmentName,
                    action:(
                        <div style={{display: 'flex', flexDirection: 'row',alignItems: 'center'}}>

                            <IconButton
                                onClick={() => {
                                    setProcedureViewModalOpen(true);
                                    setSelectedTreatment(val);
                                }}>
                                <RemoveRedEyeIcon sx={{color: '#2D9DE5'}}/>
                            </IconButton>

                            <IconButton onClick={() => {
                                setSelectedTreatment(val);
                                setConfirmAlertOpen(true)
                            }}>
                                <DeleteIcon sx={{color: '#1C5D7C'}}/>
                            </IconButton>

                        </div>
                    ),
                })
            })
            setTableData(array)
        }
    }, [treatmentData.data]);

    useEffect(() => {
        dispatch(getAllTreatment());
    }, []);

    useEffect(() => {
        if (deleteLoader){
            setDeleteLoader(false);
            if (deleteTreatmentData.isSuccess && !deleteTreatmentData.isLoading) {
                dispatch(getAllTreatment());
                setConfirmAlertOpen(false);
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Treatment Delete Success',
                })
            } else if (!deleteTreatmentData.isSuccess && !deleteTreatmentData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Delete Error'
                })
            }
        }
    }, [deleteTreatmentData.data]);

    const tableColumns = [
        {field: "treatment", headerName: "Treatment", accessor: "treatment", textAlign: 'left'},
        {field: "action", headerName: "Action", accessor: "action", textAlign: 'left',justifyContent:'right'},
    ];

    const searchKey = ['treatment'];

    return (
        <WideCard sx={{width: '100%'}}>
            <Typography sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '18px'}}>Online Treatment</Typography>
            <Box>
                <Divider sx={{borderBottom: '1.3px solid black'}}/>
            </Box>

            <Box sx={{display: 'flex', justifyContent: 'end'}}>
                <CommonButton
                    onClick={()=>{setProcedureAddModalOpen(true)}}
                    name={'+  Add New Treatment'}
                    variant={'Fill'}
                />
            </Box>

            <MDBox sx={{marginY: 2,}}>
                <MDTable tableData={tableData} tableColumns={tableColumns} searchKey={searchKey} isSearch={true}/>
            </MDBox>
            {isProcedureAddModalOpen &&
                <TreatmentAddModal onClose={()=>{setProcedureAddModalOpen(false)}} open={isProcedureAddModalOpen}/>
            }
            {isProcedureViewModalOpen &&
                <TreatmentAddModal onClose={()=>{setProcedureViewModalOpen(false)}} open={isProcedureViewModalOpen} isView={true} treatmentData={selectedTreatment}/>
            }
            {isConfirmAlertOpen &&
                <ConfirmAlert
                    src={tooth}
                    open={isConfirmAlertOpen}
                    onClose={()=>{setConfirmAlertOpen(false)}}
                    name={selectedTreatment.treatmentName}
                    title="Are you sure"
                    subtitle="you want to delete Treatment ?"
                    buttonText={'Delete'}
                    onclick={()=>{
                        setDeleteLoader(true);
                        dispatch(deleteTreatment(selectedTreatment.treatmentId));
                    }}
                />
            }
        </WideCard>
    );
}

export default OnlineTreatment;
