import React, {useState} from 'react';
import WideCard from "common/components/WideCard";
import BranchAddModal from "components/BranchAddModal/BranchAddModal";
import Toggle from "common/components/Toggle/Toggle";
import MDBox from "common/components/MDBox";
import Branch from "components/Branch";
import Slot from "components/Slot";

const toggleData = [
    {
        view: 'Clinic',
        value: "clinic",
    },
    {
        view: "Slot",
        value: "slot",
    },
];

function BranchAndSlot() {
    const [onAlignment, setOnAlignment] = useState("clinic");
    const [isBranchAddModalOpen , setIsBranchAddModalOpen] = useState(false);
    const [isBranchViewModalOpen , setIsBranchViewModalOpen] = useState(false);
    const [selectedBranch,setSelectedBranch] = useState(null);
    const handleBranchAddModalClosed = () => {
        setIsBranchAddModalOpen(false);
    }
    const handleBranchViewModalClosed = () => {
        setIsBranchViewModalOpen(false);
    }

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setOnAlignment(newAlignment);
        }
    };

    const handleViewBranchClick = (branch) =>{
        setSelectedBranch(branch);
        setIsBranchViewModalOpen(true);
    }
    return (
         <WideCard>
             <Toggle data={toggleData} value={onAlignment} onChange={handleAlignment}/>

             <MDBox>
                 {onAlignment === "clinic" ? (
                     <Branch
                         addBranchClick={()=>{setIsBranchAddModalOpen(true)}}
                         viewBranchClick={(val)=>{handleViewBranchClick(val)}}
                     />
                 ) :onAlignment === "slot" ? (
                     <Slot/>
                 ) : null}
             </MDBox>

             {isBranchAddModalOpen &&
                 <BranchAddModal onClose={handleBranchAddModalClosed} open={isBranchAddModalOpen}/>
             }
             {isBranchViewModalOpen &&
                 <BranchAddModal onClose={handleBranchViewModalClosed} open={isBranchViewModalOpen} isView={true} branchDetails={selectedBranch}/>
             }

         </WideCard>
    );
}

export default BranchAndSlot;
