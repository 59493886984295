import React from 'react';
import {Card, Box} from '@mui/material';
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";

const FilterModal = ({isOpen, children, closeModel}) => {
    return (
        <div>
            {isOpen && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '80px',
                        left: '120px',
                        width: '350px',
                        zIndex: 1,

                        '@media (max-width: 600px)': {
                            top: '80px',
                            left: '120px',
                            maxWidth: '280px',
                        },
                        '@media (max-width: 480px)': {
                            top: '115px',
                            left: '23px',
                            maxWidth: '300px',
                        },
                        '@media (max-width: 375px)': {
                            top: '115px',
                            left: '23px',
                            maxWidth: '224px',
                        },
                    }}
                >

                    <Card sx={{padding: '10px'}}>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Icon
                                className="model-icon-button"
                                fontSize="small"
                                onClick={closeModel}
                                sx={{
                                    backgroundColor: '#EAF5FC',
                                    borderRadius: '15px',
                                    color: '#2D9DE5',
                                    cursor: 'pointer'
                                }}
                            >
                                close
                            </Icon>
                        </Box>

                        <Box sx={{display: 'flex', justifyContent: 'left'}}>
                            <Typography variant="h6" color={'#1C5D7C'}>Filter</Typography>
                        </Box>

                        {children}

                    </Card>
                </Box>
            )}
        </div>
    );
};

FilterModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.node,
    closeModel: PropTypes.bool.isRequired,
};

export default FilterModal;
