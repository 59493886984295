import { createSlice } from "@reduxjs/toolkit";
import {getAllMainGrn} from "services/MainGrn/MainGrn";

const initialState = {
    mainGrn: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const mainGrnSlice = createSlice({
    name: "getAllMainGrn",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllMainGrn.pending, (state) => {
                state.mainGrn.isLoading = true;
            })
            .addCase(getAllMainGrn.fulfilled, (state, { payload }) => {
                state.mainGrn.isLoading = false;
                state.mainGrn.isSuccess = true;
                state.mainGrn.data = payload;
            })
            .addCase(getAllMainGrn.rejected, (state, { payload }) => {
                state.mainGrn.isLoading = false;
                state.mainGrn.isSuccess = false;
                state.mainGrn.errorMessage = payload;
            });
    },
});

export default mainGrnSlice.reducer;
