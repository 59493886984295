import React, {useEffect, useState} from 'react';
import WideCard from "common/components/WideCard";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CommonButton from "common/components/Button";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import DiscountAddModal from "components/DiscountAddModal";
import DiscountUpdateModal from "components/DiscountUpdateModal";
import {useDispatch, useSelector} from "react-redux";
import {deleteDiscount, getDiscount} from "services/discount/discount";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";
import {discount} from "config/images";
import ConfirmAlert from "components/ConfirmAlert";

const tableColumns = [
    {field: "desc", headerName: "Description", accessor: "desc", textAlign: 'left'},
    {field: "percent", headerName: "Percentage", accessor: "percent", textAlign: 'left' },
    {field: "status", headerName: "Status", accessor: "status", textAlign: 'left' },
    {field: "action", headerName: "Action", accessor: "action", textAlign: 'left' },

];

const searchKey = ['desc', 'percent'];

function ManageDiscount( ) {
    const [addPopup , setAddPopup] = useState(false)
    const [updatePopup , setUpdatePopup] = useState(false)
    const dispatch = useDispatch();
    const discountGet = useSelector(state => state.DiscountSlice.discount);
    const discountDlt = useSelector(state => state.DltDiscountSlice.discount);
    const [tableData , setTableData] = useState([]);
    const [selectedDiscount , setSelectedDiscount] = useState({})
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const openDltAlert = () => {
        setIsAlertOpen(true);
    };

    const handleDelete = (id) => {
        setDeleteLoader(true)
        dispatch(deleteDiscount(id));
    };

    useEffect(() => {
        if (deleteLoader) {
            setDeleteLoader(false)
            if (discountDlt.isSuccess) {
                dispatch(getDiscount( ))
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Discount Deleted Success',
                })
                setIsAlertOpen(false);
            } else if (!discountDlt.isSuccess && !discountDlt.isLoading) {
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Discount Deleted Warning'
                })
            }
        }
    }, [discountDlt.data]);

    useEffect(() => {
        dispatch(getDiscount())
    }, []);

    const  handleOpenPopup = () => {
        setAddPopup(true)
    }

    const  handleUpdatePopup = (id) => {
        setSelectedDiscount(id)
        setUpdatePopup(true)
    }

    const closePopup = () => {
        setAddPopup(false)
        setUpdatePopup(false)
        setIsAlertOpen(false)
    }

    const getStatusInfo = (status) => {
        if (status === 1) {
            return {text: 'Active', variant: 'Fill'};
        } else {
            return {text: 'Inactive', variant: 'outline'};
        }
    };

    useEffect(() => {
        if (discountGet.isSuccess) {
            const data = discountGet?.data;
            if (Array.isArray(data)) {
                const array = data.map((val) => {
                    const {text, variant} = getStatusInfo(val.status);
                    return {
                        desc: val.description,
                        percent: val.percentage,
                        status: (
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <CommonButton
                                    sx={{width: '100px'}}
                                    name={text}
                                    circular={true}
                                    variant={variant}
                                />
                            </div>
                        ),
                        action: (
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <IconButton
                                    onClick={() => {
                                        setSelectedDiscount(val);
                                        handleUpdatePopup(val);
                                    }}>
                                    <RemoveRedEyeIcon sx={{color: '#2D9DE5'}}/>
                                </IconButton>

                                <IconButton onClick={() => {
                                    setSelectedDiscount(val)
                                     openDltAlert();
                                }}>
                                    <DeleteIcon sx={{color: '#1C5D7C'}}/>
                                </IconButton>

                            </div>
                        ),
                    };
                });
                setTableData(array);
            }
        }
    }, [discountGet.isSuccess, discountGet.data]);

    return (
       <WideCard>

           <Typography sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '18px'}}>Manage Discount</Typography>
           <Box>
               <Divider sx={{borderBottom: '1.3px solid black'}}/>
           </Box>

           <Box sx={{display: 'flex', justifyContent: 'end'}}>
               <CommonButton
                   onClick={ handleOpenPopup}
                   name={'+  Add Discount'}
                   variant={'Fill'}
               />
           </Box>

           <MDBox sx={{marginY: 2,}}>
               <MDTable tableData={tableData} tableColumns={tableColumns} searchKey={searchKey} isSearch={true}/>
           </MDBox>

           {addPopup &&
               <DiscountAddModal
                   open={addPopup}
                   onClose={closePopup}
               />
           }

           {updatePopup &&
               <DiscountUpdateModal
                   open={updatePopup}
                   onClose={closePopup}
                   disData={selectedDiscount}
               />
           }

           <ConfirmAlert
               src={discount}
               name={selectedDiscount.procedureName}
               open={isAlertOpen}
               onClose={closePopup}
               title="Are you sure"
               subtitle={'you want to discount'}
               buttonText={"Delete"}
               onclick={() => {
                   handleDelete(selectedDiscount.discountId)
               }}
           />

       </WideCard>
    );
}

export default ManageDiscount;