import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import MDBox from "common/components/MDBox";
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "./styles/sidenavCollapse";
import userRoleConfig from "config/userRoleConfig";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import SidenavSubCollapse from "./SidenavSubCollapse";
import {useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

function SidenavCollapse({ icon, name, active, subRoute, ...rest }) {

  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const [subActive, setSubActive] = useState(active);
  const location = useLocation();
  const collapseName = location.pathname;
  const auth = useSelector((state) => state.AuthSlice.auth);

  return (
      <MDBox>
          <ListItem component="li">
              <MDBox
                  {...rest}
                  sx={(theme) =>
                      collapseItem(theme, {
                          active,
                          transparentSidenav,
                          whiteSidenav,
                          darkMode,
                          sidenavColor,
                      })
                  }
                  onClick={()=> setSubActive(!subActive)}
              >
                  <ListItemIcon
                      sx={(theme) =>
                          collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
                      }
                  >
                      {typeof icon === "string" ? (
                          <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
                      ) : (
                          icon
                      )}
                  </ListItemIcon>

                  <ListItemText
                      primary={name}
                      sx={(theme) =>
                          collapseText(theme, {
                              miniSidenav,
                              transparentSidenav,
                              whiteSidenav,
                              active,
                          })
                      }
                  />
                  {subRoute !== undefined &&
                      <ListItemIcon
                          sx={(theme) =>
                              collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
                          }
                      >
                          <Icon fontSize="small">{active ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}</Icon>
                      </ListItemIcon>
                  }
              </MDBox>
          </ListItem>
          <MDBox>
              {subRoute !== undefined && subActive &&
                  <MDBox>
                      {subRoute?.map((sub) => {
                          if (sub.type === "collapse" && userRoleConfig(sub?.userRole, auth?.data?.role)) {
                              return(
                                  <NavLink key={sub.key} to={sub.route}>
                                      <SidenavSubCollapse key={sub.key} subRoute={sub.route} name={sub.name} active={collapseName === sub.route} />
                                  </NavLink>
                              )
                          }
                      })}
                  </MDBox>
              }
          </MDBox>
      </MDBox>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
    subRoute: PropTypes.array,
};

export default SidenavCollapse;
