import {createSlice} from "@reduxjs/toolkit";
import {deleteChair} from "services/chair/chair";

const initialState = {
    chair: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const deleteChairSlice = createSlice({
    name: "deleteChair",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(deleteChair.pending, (state) => {
                state.chair.isLoading = true;
            })
            .addCase(deleteChair.fulfilled, (state, {payload}) => {
                state.chair.isLoading = false;
                state.chair.isSuccess = true;
                state.chair.data = payload;
            })
            .addCase(deleteChair.rejected, (state, {payload}) => {
                state.chair.isLoading = false;
                state.chair.isSuccess = false;
                state.chair.errorMessage = payload;
            });
    },
});

export default deleteChairSlice.reducer;
