import { createSlice } from "@reduxjs/toolkit";
import { getDailyUsage} from "services/DailyUsage/DailyUsage";

const initialState = {
    dailyUsage: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const dailyUsageSlice = createSlice({
    name: "getDailyUsage",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDailyUsage.pending, (state) => {
                state.dailyUsage.isLoading = true;
            })
            .addCase(getDailyUsage.fulfilled, (state, { payload }) => {
                state.dailyUsage.isLoading = false;
                state.dailyUsage.isSuccess = true;
                state.dailyUsage.data = payload;
            })
            .addCase(getDailyUsage.rejected, (state, { payload }) => {
                state.dailyUsage.isLoading = false;
                state.dailyUsage.isSuccess = false;
                state.dailyUsage.errorMessage = payload;
            });
    },
});

export default dailyUsageSlice.reducer;
