import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import CommonButton from "common/components/Button";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import {blueCross} from "config/images";
import ConfirmAlert from "components/ConfirmAlert";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {getAllBranchesWithChairCount, updateBranchWithChairs} from "services/branch/branch";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";

function Branch({addBranchClick,viewBranchClick}) {

    const dispatch = useDispatch();
    const [updateStatusLoader, setUpdateStatusLoader] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({});

    const branchesData = useSelector(state => state.BranchWithChairCountSlice.branch);
    const updateStatusData = useSelector(state => state.UpdateBranchWithChairSlice.branch);
    const handleAlertOpen = (branch) => {
        setSelectedBranch(branch);
        setAlertOpen(true);
    }

    const closeAlert = () => {
        setAlertOpen(false)
    }

    const tableColumns = [
        {field: "branch", headerName: "Clinic", accessor: "branch", textAlign: 'left'},
        {field: "room", headerName: "Rooms", accessor: "room", textAlign: 'left'},
        {field: "status", headerName: "Status", accessor: "status", textAlign: 'left'},
        {field: "view", headerName: "View", accessor: "view", textAlign: 'left'},
    ];

    const searchKey = ['branch', 'room'];

    useEffect(() => {
        dispatch(getAllBranchesWithChairCount());
    }, []);

    useEffect(() => {
        if (updateStatusLoader){
            setUpdateStatusLoader(false);
            if (updateStatusData.isSuccess && !updateStatusData.isLoading) {
                dispatch(getAllBranchesWithChairCount());
                closeAlert();
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Update Success',
                })
            } else if (!updateStatusData.isSuccess && !updateStatusData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Update Error'
                })
            }
        }
    }, [updateStatusData.data]);

    useEffect(() => {
        if(branchesData.isSuccess){
            const data = branchesData.data;
            if(Array.isArray(data)){
                const array = data.map((branch,index)=>({
                    id:index,
                    branch: (
                        <div style={{width: 500, display: 'flex', flexDirection: 'row'}}>
                            <Avatar
                                sx={{
                                    width: 30,
                                    height: 30,
                                    margin: '0px',
                                    padding: '0px',
                                    backgroundColor: '#EAF5FC',
                                    marginRight: '5px',
                                    marginTop: '8px',
                                }}
                                src={blueCross}
                            />

                            <Typography sx={{fontSize: '14px', fontWeight: 'bold', marginTop: '10px'}}>{branch.branchName}</Typography>
                        </div>
                    ),
                    branchName: branch.branchName,
                    room: (
                        <div style={{width: 500}}>
                            <Typography sx={{fontSize: '14px', fontWeight: 'bold'}}>{branch.chairCount}</Typography>
                        </div>
                    ),
                    status: (
                        <div>
                            <CommonButton
                                onClick={()=>{
                                    handleAlertOpen(branch);
                                }}
                                sx={{width: '100px'}}
                                name={branch.branchStatus === 1?'Open':'Close'}
                                circular={true}
                                variant={branch.branchStatus === 1? "Fill":"outline"}
                            />
                        </div>
                    ),
                    view: (
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <CommonButton
                                sx={{width: '100px'}}
                                name={'view'}
                                circular={true}
                                variant={"outline"}
                                onClick={()=>{viewBranchClick(branch.branchId)}}
                            />
                        </div>
                    )
                }));
                setBranches(array);
            }
        }
    }, [branchesData.data]);

    return (
        <div>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <div></div>

                <CommonButton
                    name={'+ Add Clinic'}
                    variant={'Fill'}
                    onClick={addBranchClick}
                />
            </Box>

            <MDBox sx={{marginY: 2}}>
                <MDTable tableData={branches} tableColumns={tableColumns} searchKey={searchKey} isSearch={true}/>
            </MDBox>

            <ConfirmAlert
                src={blueCross}
                name={selectedBranch.branchName}
                open={alertOpen}
                onClose={closeAlert}
                title="Are you sure"
                subtitle={selectedBranch.branchStatus === 1?'you want to Close the Clinic ?':'you want to Open the Clinic ?'}
                buttonText={selectedBranch.branchStatus === 1?'Close':'Open'}
                onclick={()=>{
                    setUpdateStatusLoader(true);
                    dispatch(updateBranchWithChairs({id:selectedBranch.branchId,branchData:{status: selectedBranch.branchStatus === 1 ? '0' : '1'}}))
                }}
            />

        </div>
    );
}

export default Branch;

Branch.propTypes = {
    addBranchClick:PropTypes.func,
    viewBranchClick:PropTypes.func
};
