import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "services/AxiosOrders";

export const getAllPatients = createAsyncThunk('get-patient', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/patient`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const savePatient = createAsyncThunk('save-patient', async (patientData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/patient`, patientData, {
            headers: {"Content-type": "multipart/form-data"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const updatePatient = createAsyncThunk('update-patient', async ({id,updatedPatientData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/patient/${id}`,  updatedPatientData,{
            headers: {"Content-type": "multipart/form-data"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const deletePatient = createAsyncThunk('delete-patient', async (id, { rejectWithValue }) => {
        try {
            const data = await instance.delete(`/patient/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


export const getPatientByMobileNo = createAsyncThunk('get-patient', async (mobileNo, { rejectWithValue }) => {
        try {
            const {data} = await instance.get('/patient',{
                params:{
                    nicOrNumber:mobileNo,
                },
            });
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

