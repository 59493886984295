import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "services/AxiosOrders";

export const getAllStockCount = createAsyncThunk('get-stock-count', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/stock_dashboard/chart_details/${page}`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const allStockDashboardCount = createAsyncThunk('get-dashboard-count', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/stock_dashboard`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const allMainPurchaseOrder = createAsyncThunk('get-main-order', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/stock_dashboard/approval_pending`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const allPOApprovalDashboard = createAsyncThunk('get-dashboard-po', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/stock_dashboard/branch_po_approval`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const dailyUsagePending = createAsyncThunk('daily-usage', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/stock_dashboard/daily_usage_pending`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

