import { createSlice } from "@reduxjs/toolkit";
import {postExtraCharge} from "services/extraCharge/extraCharge";

const initialState = {
    extra: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const saveExtraChargeSlice = createSlice({
    name: "saveExtraCharge",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(postExtraCharge.pending, (state) => {
                state.extra.isLoading = true;
            })
            .addCase(postExtraCharge.fulfilled, (state, { payload }) => {
                state.extra.isLoading = false;
                state.extra.isSuccess = true;
                state.extra.data = payload;
            })
            .addCase(postExtraCharge.rejected, (state, { payload }) => {
                state.extra.isLoading = false;
                state.extra.isSuccess = false;
                state.extra.errorMessage = payload;
            });
    },
});

export default saveExtraChargeSlice.reducer;
