import { createSlice } from "@reduxjs/toolkit";
import {postMainGrn} from "services/MainGrn/MainGrn";

const initialState = {
    mainGrn: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const saveMainGrnSlice = createSlice({
    name: "getAllMainGrn",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(postMainGrn.pending, (state) => {
                state.mainGrn.isLoading = true;
            })
            .addCase(postMainGrn.fulfilled, (state, { payload }) => {
                state.mainGrn.isLoading = false;
                state.mainGrn.isSuccess = true;
                state.mainGrn.data = payload;
            })
            .addCase(postMainGrn.rejected, (state, { payload }) => {
                state.mainGrn.isLoading = false;
                state.mainGrn.isSuccess = false;
                state.mainGrn.errorMessage = payload;
            });
    },
});

export default saveMainGrnSlice.reducer;
