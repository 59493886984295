import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "services/AxiosOrders";

export const getAllOnlineAppointments = createAsyncThunk('online-appointment', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/online_appointments`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});
