import { Delete} from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import CommonButton from "common/components/Button";
import MDTable from "common/components/MDTable";
import React, { useEffect, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useDispatch, useSelector } from "react-redux";
import { deleteCategory, getCategory } from "services/category/category";
import CategoryAddModal from "../CategoryAddModal";
import ConfirmAlert from "../ConfirmAlert";
import { tooth } from "config/images";
import {
  sendSuccessNotification,
  sendWarningNotification,
} from "common/components/Notification/SendNotification";

function Category({ addClick, updateClick }) {
  const dispatch = useDispatch();
  const categoryData = useSelector((state) => state.CategorySlice.category);
  const deleteData = useSelector((state) => state.DeleteCategorySlice.category);
  const [tableData, setTableData] = useState([]);
  const [openViewpopup, setOpenViewpopup] = useState(false);
  const [selectCategory, setSelectCategory] = useState({});
  const [isConfirmAlertOpen, setConfirmAlertOpen] = useState(false);
  const [deleteLoder, setDeleteLoader] = useState(false);
  const [openAddpopup, setOpenAddpopup] = useState(false);
  const [openUpdatepopup, setOpenUpdatepopup] = useState(false);

  const handleAddPopup = () => {
    setOpenAddpopup(true);
  };
  const handleUpdatePopup = (val) => {
    setSelectCategory(val);
    setOpenUpdatepopup(true);
  };

  useEffect(() => {
    dispatch(getCategory());
  }, []);

  useEffect(() => {
    if (categoryData.isSuccess) {
      const data = categoryData?.data;
      if (Array.isArray(data)) {
        const array = data.map((val) => ({
          id: val.categoryId,
          category: (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginTop: "10px" }}>{val.description}</div>
            </div>
          ),
          action: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "end",
                justifyContent: "end",
              }}
            >
              <IconButton
                onClick={() => {
                  handleUpdatePopup(val);
                }}
              >
                <RemoveRedEyeIcon sx={{ color: "#2D9DE5" }} />
              </IconButton>

              <IconButton
                onClick={() => {
                  setSelectCategory(val);
                  setConfirmAlertOpen(true);
                }}
              >
                <Delete sx={{ color: "#1C5D7C" }} />
              </IconButton>
            </div>
          ),
        }));
        setTableData(array);
      }
    }
  }, [categoryData.isSuccess, categoryData.data]);

  useEffect(() => {
    if (deleteLoder) {
      setDeleteLoader(false);
      if (deleteData.isSuccess && !deleteData.isLoading) {
        dispatch(getCategory());
        sendSuccessNotification({
          title: "Success",
          message: "Category Delete Success",
        });
        setConfirmAlertOpen(false);
      } else if (!deleteData.isSuccess && !deleteData.isLoading) {
        sendWarningNotification({
          title: "Warning",
          message: "Category Delete Warning",
        });
      }
    }
  }, [deleteData.data]);

  const tableColumns = [
    {
      field: "category",
      headerName: "Category",
      accessor: "category",
      textAlign: "left",
    },
    { field: "action", headerName: "", accessor: "action", textAlign: "left" },
  ];
  const searchKey = ["category"];
  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <CommonButton
          name={" + Add Category"}
          onClick={() => {
            handleAddPopup();
          }}
        />
      </Box>

      <MDTable
        tableData={tableData}
        tableColumns={tableColumns}
        searchKey={searchKey}
        isSearch={true}
      />
      {openViewpopup && (
        <CategoryAddModal
          open={openViewpopup}
          onClose={() => {
            setOpenViewpopup(false);
          }}
          isView={true}
          categoryData={selectCategory}
        />
      )}
      {isConfirmAlertOpen && (
        <ConfirmAlert
          src={tooth}
          open={isConfirmAlertOpen}
          onClose={() => {
            setConfirmAlertOpen(false);
          }}
          name={selectCategory.description}
          title="Are you sure"
          subtitle="you want to delete Category ?"
          buttonText={"Delete"}
          onclick={() => {
            setDeleteLoader(true);
            dispatch(deleteCategory(selectCategory.categoryId));
          }}
        />
      )}

      {openAddpopup && (
        <CategoryAddModal
          open={openAddpopup}
          onClose={() => {
            setOpenAddpopup(false);
          }}
        />
      )}

      {openUpdatepopup && (
        <CategoryAddModal
          open={openUpdatepopup}
          onClose={() => {
            setOpenUpdatepopup(false);
          }}
          isView={true}
          categoryData={selectCategory}
        />
      )}
    </div>
  );
}
export default Category;
