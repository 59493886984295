import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "services/AxiosOrders";

export const saveBranchWithChairs = createAsyncThunk('save-branch', async (branchData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/branch/branch_with_chairs`, branchData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const getAllBranches = createAsyncThunk('get-branches', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/branch`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getBranchById = createAsyncThunk('get-branch-byid', async (id, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/branch/get_branch_with_related_chairs/${id}`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getBranchWithChairs = createAsyncThunk('get-branches', async (id, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/branch/get_branch_with_related_chairs/${id}`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateBranchWithChairs = createAsyncThunk('update-branch', async ({id,branchData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/branch/update_branch_with_chairs/${id}`,  branchData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getAllBranchesWithChairCount = createAsyncThunk('get-all-branches', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/branch/get_branch_using_chair_count`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});


