import React, {useEffect, useState} from "react";
import WideCard from "common/components/WideCard";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import CommonButton from "common/components/Button";
import {useDispatch, useSelector} from "react-redux";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";
import {deleteClinicalNote, getClinicalNotesByPatient, saveClinicalNote} from "services/clinicalNote/clinicalNote";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

function ClinicalNote({patientId,changePage}){

    const dispatch = useDispatch();
    const [saveLoader,setSaveLoader] = useState(false);
    const [deleteLoader,setDeleteLoader] = useState(false);
    const [note,setNote] = useState('');
    const [clinicalNoteHistory, setClinicalNoteHistory] = useState([]);
    const saveClinicalNoteData = useSelector(state => state.SaveClinicalNoteSlice.clinicalNote);
    const clinicalNotesData = useSelector(state => state.ClinicalNoteByPatientSlice.clinicalNote);
    const deleteClinicalNotesData = useSelector(state => state.DeleteClinicalNoteSlice.clinicalNote);


    const isValidate = note !== '';

    const saveNote = ()=> {
        const clinicalNote = {
            description:note,
            patientId:patientId
        }

        setSaveLoader(true);
        dispatch(saveClinicalNote(clinicalNote));
    }

    useEffect(() => {
        dispatch(getClinicalNotesByPatient(patientId))
    }, []);

    useEffect(() => {
        if(clinicalNotesData.isSuccess && !clinicalNotesData.isLoading){
            const array = []
            clinicalNotesData.data?.forEach((val)=> {
                array.push({
                    description:val.description,
                    date:val.date,
                    clinicNoteId:val.clinicNoteId
                })
            });
            setClinicalNoteHistory(array);
        }
    }, [clinicalNotesData.data]);

    useEffect(() => {
        if (saveLoader){
            setSaveLoader(false);
            if (saveClinicalNoteData.isSuccess && !saveClinicalNoteData.isLoading) {
                setNote('');
                dispatch(getClinicalNotesByPatient(patientId))
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Clinical Note Saved Success',
                })
            } else if (!saveClinicalNoteData.isSuccess && !saveClinicalNoteData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Save Error'
                })
            }
        }
    }, [saveClinicalNoteData.data]);

    useEffect(() => {
        if (deleteLoader){
            setDeleteLoader(false);
            if (deleteClinicalNotesData.isSuccess && !deleteClinicalNotesData.isLoading) {
                setNote('');
                dispatch(getClinicalNotesByPatient(patientId))
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Clinical Note Delete Success',
                })
            } else if (!deleteClinicalNotesData.isSuccess && !deleteClinicalNotesData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Delete Error'
                })
            }
        }
    }, [deleteClinicalNotesData.data]);

    return(
        <div>
            <WideCard>
                <Typography
                    sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '19px',}}>
                    Clinical Note
                </Typography>
                <Box>
                    <Divider sx={{borderBottom: '1.3px solid black', marginTop: '6px',}}/>
                </Box>

                <Box sx={{display:'flex',justifyContent:'center',marginY:'10px'}}>
                    <Box width={{xs:'100%',sm:'100%',md:'85%',lg:'80%'}}>
                        <Box>
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Note"
                                fullWidth
                                multiline
                                rows={3}
                                value={note}
                                onChange={(e)=>{
                                    setNote(e.target.value)
                                }}
                            />
                        </Box>
                        <Box sx={{display:'flex',justifyContent:'end' ,marginTop:'10px'}}>
                            <CommonButton
                                name={'Add'}
                                variant={'Fill'}
                                onClick={saveNote}
                                disabled={!isValidate}
                            />
                        </Box>
                    </Box>
                </Box>

                <Typography
                    sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '19px',}}>
                    Clinical History
                </Typography>
                <Box>
                    <Divider sx={{borderBottom: '1.3px solid black', marginTop: '6px',}}/>
                </Box>
                <Box sx={{display:'flex',justifyContent:'center',marginY:'10px'}}>
                    <Box width={{xs:'100%',sm:'100%',md:'85%',lg:'80%'}}>
                        <Box sx={{height:'190px',overflow: 'auto'}}>
                            {
                                clinicalNoteHistory.map((val,index)=>(
                                    <Box sx={{border:'1px solid #2D9DE5',borderRadius:'5px' ,backgroundColor:'#EAF5FC',padding:'10px',marginY:'8px'}}>
                                        <Grid container>
                                            <Grid item xs={11} sm={11} md={11} lg={11}>
                                                <Box>
                                                    <Typography
                                                        sx={{color: '#1C5E7C', fontWeight: 400, fontSize: '12px',}}>
                                                        {val.description}
                                                    </Typography>
                                                    <Typography
                                                        sx={{color: '#1C5E7C', fontWeight: 400, fontSize: '8px',}}>
                                                        {val.date}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={1} lg={1}>
                                                <IconButton aria-label="delete">
                                                    <CloseIcon onClick={()=>{
                                                        setDeleteLoader(true);
                                                        dispatch(deleteClinicalNote(val.clinicNoteId));
                                                    }} />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))
                            }
                        </Box>

                        <Box sx={{display:'flex',justifyContent:'space-between',marginTop:'10px'}}>
                            <IconButton aria-label="back">
                                <ArrowBackIosNewIcon
                                    sx={{ color: '#2D9DE5' }}
                                    onClick={()=>{
                                        changePage('tooth-chart');
                                    }}
                                />
                            </IconButton>
                            <CommonButton
                                name={'Next'}
                                variant={'Fill'}
                                onClick={()=>{
                                    changePage('extra-charge');
                                }}
                            />
                        </Box>

                    </Box>
                </Box>
            </WideCard>
        </div>
    );
}

export default ClinicalNote
