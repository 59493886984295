import React, {useEffect, useState} from 'react';
import MDBox from "common/components/MDBox";
import ModalComponent from "common/components/Modal";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextInput from "common/components/TextInput/TextInput";
import CommonButton from "common/components/Button";
import IconButton from "@mui/material/IconButton";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import {useDispatch, useSelector} from "react-redux";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";
import {getAllProcedures, updateProcedure} from "services/procedure/procedure";
import DropDown from "common/components/DropDown/DropDown";
import PropTypes from "prop-types";

function ManageProceduresUpdateModal({open, onClose, procedureData}) {
    const dispatch = useDispatch();
    const updateProcedureData = useSelector(state => state.UpdateProcedureSlice.procedure);
    const [editMode, setEditMode] = useState(false);
    const [procedure, setProcedure] = useState('');
    const [type, setType] = useState('');
    const [fees, setFees] = useState('');
    const [cost, setCost] = useState('');
    const [extra, setExtra] = useState('');
    const [totalCharge, setTotalCharge] = useState('');
    const [branch, setBranch] = useState('');
    const [updateLoader, setUpdateLoader] = useState(false);
    const [feesError, setFeesError] = useState('');
    const [costError, setCostError] = useState('');
    const [extraError, setExtraError] = useState('');
    const [totalChargeError, setTotalChargeError] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);
    const [dentalPortion, setDentalPortion] = useState('');
    const [doctorPortion, setDoctorPortion] = useState('');
    const [doctorPortionError, setDoctorPortionError] = useState("");
    const [dentalPortionError, setDentalPortionError] = useState("");

    const updatedProcedure = () => {
        const proUpData = {
            procedureName: procedure,
            type: type,
            fees: fees,
            labCost: cost,
            extraCons: extra,
            totalCharge: totalCharge,
            branchId: procedureData.branch.branchId,
            doctorPayMethod: selectedValue,
            dentalPortion: selectedValue === "portion" ? dentalPortion : 0,
            doctorPortion: selectedValue === "portion" ? doctorPortion : 0,
        };
        setUpdateLoader(true);
        dispatch(updateProcedure({id: procedureData.procedureId, proUpData}));
    };

    useEffect(() => {
        if (updateLoader) {
            setUpdateLoader(false);
            if (updateProcedureData.isSuccess && !updateProcedureData.isLoading) {
                dispatch(getAllProcedures(procedureData.branch.branchId));
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Procedure Updated Success',
                })
                onClose();
            } else if (!updateProcedureData.isSuccess && !updateProcedureData.isLoading) {
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Procedure Updated Warning'
                })
            }
        }
    }, [updateProcedureData.data]);

    useEffect(() => {
        setProcedure(procedureData.procedureName)
        setType(procedureData.type)
        setFees(procedureData.fees === 0 ? "0" : procedureData.fees)
        setCost(procedureData.labCost === 0 ? "0" : procedureData.labCost)
        setExtra(procedureData.extraCons === 0 ? "0" : procedureData.extraCons)
        setTotalCharge(procedureData.totalCharge === 0 ? "0" : procedureData.totalCharge)
        setBranch(procedureData.branch.name)
        setSelectedValue(procedureData.doctorPayMethod)
        setDentalPortion(procedureData.dentalPortion)
        setDoctorPortion(procedureData.doctorPortion)

    }, []);

    const handleDocPortionChange = (e) => {
        setDoctorPortion(e.target.value);
        if (!/^\d+(\.\d{1,2})?$/.test(e.target.value)) {
            setDoctorPortionError("Invalid Doctor Portion");
        } else {
            setDoctorPortionError("");
        }
    };

    const handleDentalPortionChange = (e) => {
        setDentalPortion(e.target.value);
        if (!/^\d+(\.\d{1,2})?$/.test(e.target.value)) {
            setDentalPortionError("Invalid Dental Portion");
        } else {
            setDentalPortionError("");
        }
    };

    const isDataComplete = procedure && type && fees && cost && extra && totalCharge &&
        ((!selectedValue && !feesError && !costError && !extraError && !totalChargeError) ||
            (selectedValue && selectedValue === 'portion' && ((doctorPortion !== '' || dentalPortion !== '') && !doctorPortionError && !dentalPortionError && !feesError && !costError && !extraError && !totalChargeError)) ||
            (selectedValue && selectedValue === 'percentage' && (doctorPortion === '' || dentalPortion === '') && !doctorPortionError && !dentalPortionError && !feesError && !costError && !extraError && !totalChargeError));

    const handleProcedureChange = (e) => {
        setProcedure(e.target.value);
    };

    const handleTypeChange = (e) => {
        setType(e.target.value);
    };

    const handleFeesChange = (e) => {
        setFees(e.target.value);
        if (!/^\d+(\.\d{1,2})?$/.test(e.target.value)) {
            setFeesError("Invalid Fee");
        } else {
            setFeesError("");
        }
    };

    const handleCostChange = (e) => {
        setCost(e.target.value);
        if (!/^\d+(\.\d{1,2})?$/.test(e.target.value)) {
            setCostError("Invalid Fee");
        } else {
            setCostError("");
        }
    };

    const handleExtraChange = (e) => {
        setExtra(e.target.value);
        if (!/^\d+(\.\d{1,2})?$/.test(e.target.value)) {
            setExtraError("Invalid Fee");
        } else {
            setExtraError("");
        }
    };

    const handleTotalChargeChange = (e) => {
        setTotalCharge(e.target.value);
        if (!/^\d+(\.\d{1,2})?$/.test(e.target.value)) {
            setTotalChargeError("Invalid Fee");
        } else {
            setTotalChargeError("");
        }
    };

    const handleBranchChange = (e) => {
        setBranch(e.target.value);
    };

    const handleEditMode = () => {
        setEditMode(!editMode);
    };

    const docPayTypeArray = [
        {value: "percentage", label: "percentage"},
        {value: "portion", label: "portion"},
    ]

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="sm">
            <MDBox open={open} onClose={onClose} maxWidth="sm" sx={{margin: '15px'}}>

                <div style={{overflow: 'hidden'}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Box sx={{width: '870px', margin: '0 auto'}}>
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <Typography sx={{
                        color: '#1C5E7C',
                        fontWeight: 450,
                        fontSize: '19px',
                        marginBottom: '25px',
                        position: 'absolute',
                        top: '15px',
                        left: '15px'
                    }}> Procedure </Typography>

                    <div style={{
                        position: 'absolute',
                        top: '12px',
                        left: '105px'
                    }}
                    >
                        <IconButton aria-label="delete">
                            <DriveFileRenameOutlineIcon sx={{color: '#1C5E7C'}} fontSize={'small'}
                                                        onClick={handleEditMode}/>
                        </IconButton>
                    </div>
                </Box>

                <MDBox sx={{marginY: 2}}>
                    <TextInput placeholder={'Clinic'}
                               disabled={true}
                               value={branch}
                               onChange={handleBranchChange}

                    />
                </MDBox>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextInput
                            placeholder={'Procedure'}
                            disabled={!editMode}
                            value={procedure}
                            onChange={handleProcedureChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DropDown
                            disabled={!editMode}
                            options={docPayTypeArray}
                            value={selectedValue}
                            onChange={(value) => {
                                setSelectedValue(value);
                            }}
                        />
                    </Grid>

                    {selectedValue === "portion" && (
                        <>
                            <Grid item xs={4} sm={4} md={3} lg={3}>
                                <TextInput placeholder={'Doctor Portion'}
                                           value={doctorPortion}
                                           onChange={handleDocPortionChange}
                                           error={doctorPortionError !== ""}
                                />
                                {doctorPortionError &&
                                    <div style={{color: 'red', fontSize: '12px'}}>{doctorPortionError}</div>}
                            </Grid>

                            <Grid item xs={4} sm={4} md={3} lg={3}>
                                <TextInput placeholder={'Dental Portion'}
                                           value={dentalPortion}
                                           onChange={handleDentalPortionChange}
                                           error={dentalPortionError !== ""}
                                />
                                {dentalPortionError &&
                                    <div style={{color: 'red', fontSize: '12px'}}>{dentalPortionError}</div>}
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextInput placeholder={'Type'}
                                   disabled={!editMode}
                                   value={type}
                                   onChange={handleTypeChange}

                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <TextInput placeholder={'Fees'}
                                   value={fees}
                                   disabled={!editMode}
                                   onChange={handleFeesChange}
                                   error={feesError !== ""}
                        />
                        {feesError && <div style={{color: 'red', fontSize: '12px'}}>{feesError}</div>}
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <TextInput placeholder={'Lab Cost'}
                                   value={cost}
                                   disabled={!editMode}
                                   onChange={handleCostChange}
                                   error={costError !== ""}

                        />
                        {costError && <div style={{color: 'red', fontSize: '12px'}}>{costError}</div>}
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <TextInput placeholder={'Extra Cons'}
                                   value={extra}
                                   disabled={!editMode}
                                   onChange={handleExtraChange}
                                   error={extraError !== ""}

                        />
                        {extraError && <div style={{color: 'red', fontSize: '12px'}}>{extraError}</div>}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextInput placeholder={'Total Charge'}
                                   value={totalCharge}
                                   disabled={!editMode}
                                   onChange={handleTotalChargeChange}
                                   error={totalChargeError !== ""}
                        />
                    </Grid>
                    {totalChargeError &&
                        <div style={{color: 'red', fontSize: '12px', marginLeft: '15px'}}>{totalChargeError}</div>}

                </Grid>

                <MDBox sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '15px'}}>
                    <CommonButton
                        name={!editMode ? "Cancel" : 'Update'}
                        variant={'Fill'}
                        onClick={!editMode ? onClose : updatedProcedure}
                        disabled={editMode && !isDataComplete}
                    />
                </MDBox>

            </MDBox>
        </ModalComponent>
    );
}

ManageProceduresUpdateModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    procedureData: PropTypes.object.isRequired,
};

export default ManageProceduresUpdateModal;
