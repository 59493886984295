import React, {useEffect,useState} from 'react';
import NavbarCard from "common/components/NavbarCard";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import {navbarIconBtn} from "common/components/DashboardNavbar/styles";
import PersonIcon from "@mui/icons-material/Person";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {RemoveItem} from "common/utils/Storage/Storage";
import {getMainUrl} from "common/utils/CommonFunctions";
import { useSelector } from "react-redux";

function ReceptionPopupCard({open}) {

    const [user,setUser] = useState('');
    const [userRoll,setUserRoll] = useState('');
    const auth = useSelector((state) => state.AuthSlice.auth);

    useEffect(()=> {
        if(auth.isSuccess && auth.data){
            setUser(auth.data.name);
            setUserRoll(auth.data?.role)
        }},[auth])

    return (
        <NavbarCard isOpen={open}>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <IconButton sx={navbarIconBtn} size="large">
                    <PersonIcon fontSize={'large'} style={{color: '#2D9DE5'}}/>
                </IconButton>
                <Typography sx={{fontSize: "16px", color: '#1C5D7C'}}>{user}</Typography>
                <Typography sx={{fontSize: "12px", color: '#999999'}}>{userRoll}</Typography>
            </Box>

            <Divider sx={{ width: '100%', margin: '12px 0' }} />

            <Button
                variant={"outlined"}
                sx={{color:'#2D9DE5' , width:'100%',
                '&:hover': {
                    backgroundColor: 'transparent',
                    borderColor: '#2D9DE5',
                    color: '#2D9DE5',
                },}}
                onClick={()=> {
                    RemoveItem("my-dentist-token");
                    if(window.location.hostname === 'localhost'){
                        window.location.reload();
                    }else {
                        window.location.href= getMainUrl();
                    }
                }}
            >Sign Out</Button>
        </NavbarCard>
    );
}

ReceptionPopupCard.propTypes = {
    open: PropTypes.bool.isRequired,
};
export default ReceptionPopupCard;
