import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "services/AxiosOrders";
export const getItems = createAsyncThunk('get-items', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/item`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const postItem = createAsyncThunk('post-item', async (itemData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/item`, itemData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateItem = createAsyncThunk('item-update', async ({id,itemUpData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/item/${id}`,  itemUpData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const deleteItem = createAsyncThunk('delete-item', async (id, { rejectWithValue }) => {
        try {
            const data = await instance.delete(`/item/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getItemsByBranch = createAsyncThunk('get-items-by-branch', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/item_branch/current_stock`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getAllItems = createAsyncThunk('get-all-items', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/item/getAllItem`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getItemsMainStock = createAsyncThunk('get-items-main-stock', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/main_grn/item_details_with_qty`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});
