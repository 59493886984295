import WideCard from "common/components/WideCard";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CommonButton from "common/components/Button";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Grid from "@mui/material/Grid";
import DropDown from "common/components/DropDown/DropDown";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import GrnIssueAddModal from "components/GrnIssueAddModal";
import {useDispatch, useSelector} from "react-redux";
import {getAllBranches} from "services/branch/branch";
import {getGrn} from "services/grn/grn";
import GrnIssueShowModal from "components/GrnIssueShowModal";
import {generateDate} from "common/utils/CommonFunctions";

function ManageGrnIssue() {
    const dispatch = useDispatch();
    const getGrnData = useSelector(state => state.GrnSlice.grn);
    const getBranchData = useSelector(state => state.BranchSlice.branch);
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [isViewModalOpen, setViewModalOpen] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [branch, setBranch] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [selectedGrn, setSelectedGrn] = useState(null);

    useEffect(() => {
        dispatch(getAllBranches());
    }, []);

    useEffect(() => {
        if (getBranchData.isSuccess) {
            const data = getBranchData.data;
            if (Array.isArray(data)) {
                setBranch(data.map((branch) => ({value: branch, label: branch.name})));
            }
        }
    }, [getBranchData.data]);

    useEffect(() => {

        if (selectedBranch && selectedDate) {
            dispatch(getGrn({branchId: selectedBranch.branchId, date: generateDate(selectedDate)}));
        } else if (selectedBranch) {
            dispatch(getGrn({branchId: selectedBranch.branchId}));
        }else if (selectedDate){
            dispatch(getGrn({date: generateDate(selectedDate)}));
        }
    }, [selectedBranch, selectedDate]);

    const tableColumns = [
        {field: "branch", headerName: "Branch", accessor: "branch", textAlign: 'left'},
        {field: "grnDate", headerName: "GRN Date", accessor: "grnDate", textAlign: 'left'},
        {field: "action", headerName: "Action", accessor: "action", textAlign: 'left'},
    ];

    const searchKey = ['invoiceNo', 'branch'];

    useEffect(() => {
        if (getGrnData.isSuccess && !getGrnData.isLoading) {
            const array = []
            getGrnData.data?.forEach((val) => {
                const filtBranch = branch.filter(brch => val.branchId === brch.value.branchId)
                array.push({
                    id: val.grnId,
                    branch:filtBranch[0]?.value.name,
                    grnDate: val.date,
                    action: (
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <IconButton
                                onClick={() => {
                                    setViewModalOpen(true);
                                    setSelectedGrn(val);
                                }}>
                                <RemoveRedEyeIcon sx={{color: '#2D9DE5'}}/>
                            </IconButton>
                        </div>
                    ),
                })
            })
            setTableData(array)
        }
    }, [getGrnData.data])

    const handleDateChange = (date) => {
        setSelectedDate(date)
    }

    return (
        <WideCard>
            <Typography sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '18px'}}>Manage GRN Issue</Typography>
            <Box>
                <Divider sx={{borderBottom: '1.3px solid black'}}/>
            </Box>

            <Box sx={{display: 'flex', justifyContent: 'end', marginBottom: '5px'}}>
                <CommonButton
                    onClick={() => {
                        setAddModalOpen(true)
                    }}
                    name={'+  Add GRN'}
                    variant={'Fill'}
                />
            </Box>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <DropDown
                        label={'Clinic'}
                        options={branch}
                        value={selectedBranch}
                        onChange={(value) => {
                            setSelectedBranch(value);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{width: "100%"}}
                            label="Date"
                            value={selectedDate}
                            onChange={
                                handleDateChange
                            }

                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>

            <MDBox sx={{marginY: 2,}}>
                <MDTable tableData={tableData} tableColumns={tableColumns} searchKey={searchKey}/>
            </MDBox>

            {isAddModalOpen &&
                <GrnIssueAddModal
                    open={isAddModalOpen}
                    onClose={() => {
                        setAddModalOpen(false)
                    }}
                />
            }

            {isViewModalOpen &&
                <GrnIssueShowModal onClose={() => {
                    setViewModalOpen(false)
                }} open={isViewModalOpen} grnData={selectedGrn}/>
            }

        </WideCard>
    )
}

export default ManageGrnIssue;
