import { createSlice } from "@reduxjs/toolkit";
import { getBookingHistory } from "services/report/report";

const initialState = {
    bookingHistory: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const bookingHistorySlice = createSlice({
    name: "bookingHistory",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBookingHistory.pending, (state) => {
                state.bookingHistory.isLoading = true;
            })
            .addCase(getBookingHistory.fulfilled, (state, { payload }) => {
                state.bookingHistory.isLoading = false;
                state.bookingHistory.isSuccess = true;
                state.bookingHistory.data = payload;
            })
            .addCase(getBookingHistory.rejected, (state, { payload }) => {
                state.bookingHistory.isLoading = false;
                state.bookingHistory.isSuccess = false;
                state.bookingHistory.errorMessage = payload;
            });
    },
});

export default bookingHistorySlice.reducer;