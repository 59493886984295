import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../AxiosOrders";

export const getAuth = createAsyncThunk('auth/details', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.post(`/user/get_user_info_by_token`);
        return data;
    } catch (error) {
        return rejectWithValue(error?.response?.data);
    }
});
