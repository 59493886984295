import React from 'react';
import FilterModal from "common/components/FilterModal";
import TextInput from "common/components/TextInput/TextInput";
import MDBox from "common/components/MDBox";
import SearchIcon from "@mui/icons-material/Search";
import CommonButton from "common/components/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

function Index({open, onClose}) {

    const handleClose = () => {
        onClose();
    };

    return (
        <FilterModal isOpen={open} closeModel={onClose} maxWidth="md">
            <MDBox open={open} onClose={handleClose} maxWidth="md">

                <Box sx={{marginTop: '15px'}}>
                    <TextInput
                        placeholder={'Search'}
                        endIcon={<SearchIcon/>}
                    />
                </Box>

                <Typography sx={{fontSize: '11px', marginTop: '15px'}}>Patient</Typography>

                <Grid container style={{display: 'flex', flexDirection: 'row', marginTop: '5px'}}>
                    <Grid item xs={5} lg={3}>
                        <CommonButton
                            sx={{width: '100px'}}
                            name={'Registered'}
                            circular={true}
                            variant={'Fill'}
                            onClick={() => {
                                console.log("Clicked")
                            }}
                        />
                    </Grid>

                    <Grid item xs={1.2} lg={1.2}></Grid>

                    <Grid item xs={5} lg={3}>
                        <CommonButton
                            sx={{width: '100px'}}
                            name={'Unregistered'}
                            circular={true}
                            variant={'outline'}
                        />

                    </Grid>

                    <Grid item xs={12} lg={4.8}></Grid>

                </Grid>

                <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px'}}>
                    <CommonButton
                        name={'Show (82) booking'}
                        variant={'Fill'}
                        onClick={() => {
                            console.log("Clicked")
                        }}
                    />
                </Box>
            </MDBox>
        </FilterModal>
    );
}

FilterModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.bool.isRequired,
};

export default Index;